import React, { Component } from 'react';
import { Popup, View } from 'framework7-react';
import './CodeAuthenticationPopup.css';
import CodeAuthenticationPage from '../../pages/codeAuthenticationPage/CodeAuthenticationPage';
import FontIcon from '../../fontIcon/FontIcon';

window.showBiometricCode = () => {
	window.popupController.show(CodeAuthenticationPopup, {})
};

export default class CodeAuthenticationPopup extends Component {
	constructor(props) {
		super(props);
		this.state = {
			opened: this.props.opened,
			secondsUntilClosed: 30
		};

		this.intervalId = setInterval(() => {
			this.setState({ secondsUntilClosed: this.state.secondsUntilClosed - 1 });
			if (this && this.close && this.state.secondsUntilClosed == 0) {
				this.close();
			}
		}, 1000);
	}

	componentWillReceiveProps = (nextProps) => {
		this.setState({ opened: nextProps.opened });
	};

	close = () => {
		clearInterval(this.intervalId);
		this.setState({ opened: false });
		window.popupController.hide(CodeAuthenticationPopup);
	};

	render() {
		return (
			<Popup
				tabletFullscreen
				opened={this.state.opened}
				className="g-code-authentication-popup"
				id="CodeAuthenticationPopup"
			>
				<FontIcon onClick={this.close} name="icon-close" color="white" />
				<View stackPages>
					<CodeAuthenticationPage secondsUntilClosed={this.state.secondsUntilClosed} />
				</View>
			</Popup>
		);
	}
}
