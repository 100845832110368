import React from 'react';
import './ResetPasswordPopup.css';
import { Navbar, Page, Popup, Link, View } from 'framework7-react';
import Input from '../../input/Input';
import FormTemplate from '../../templates/formTemplates/FormTemplate';
import Button from '../../button/Button';
import mainAPI from '../../../api/MainAPI';
import Content from '../../helpers/content/Content';
import utils from '../../../utils/utils';
import TutorialPopup from '../tutorialPopup/TutorialPopup';
import toasts from '../../../utils/toasts';
import models from '../../../model/models';
import loginController from '../../../controllers/loginController';

export default class ResetPasswordPopup extends FormTemplate {
	constructor(props) {
		super(props);
		this.state = {
			opened: false,
			mode: 'Reset'
		};
	}

	componentDidMount = () => {
		this._checkResetUrl();
	};

	_checkResetUrl = () => {
		const queryString = utils.query_string();
		let hash = (window.location.hash || queryString.hash || '').replace('#', '');
		if (hash === 'reset-password') {
			if (queryString.password_reset_guid) {
				setResetGuid(queryString.password_reset_guid, queryString.country || queryString.region);
				window.location = '/#reset-password';
			} else {
				this.setState({ opened: true, mode: 'Reset' });
			}
		} else if (hash === 'user-invite') {
			if (queryString.token || queryString.password_reset_guid) {
				setResetGuid(queryString.password_reset_guid, queryString.country || queryString.region);
				window.location = '/#user-invite';
			} else {
				this.setState({ opened: true, mode: 'Update' });
			}
		} else if (hash === 'show-tutorial') {
			window.location.hash = '';
			window.popupController.show(TutorialPopup, {});
		}
	};

	_validPassword = () => {
		let value = this.getValue();
		if (value.password.length < 8) {
			toasts.error('Passwords must be 8 characters or longer');
			return false;
		}
		if(value.password === value.password.toLowerCase()){
			toasts.error('Passwords must contain 1 uppercase character');
			return false;
		}
		if(value.password === value.password.toUpperCase()){
			toasts.error('Passwords must contain 1 lowercase character');
			return false;
		}
		return true;
	};

	_reset = async () => {
		if (!this._validPassword()) return;
		let value = this.getValue();
		let user = await mainAPI.user.update({ password: value.password });
		if (!user) return toasts.error('Password reset expired, click forgot password again.');
		delete localStorage.password_reset_guid;
		utils.setToken(user.token, user.region);
		models.user.setCurrent(user);
		this.setState({ opened: false });
		window.popupController.hide(ResetPasswordPopup);
		const queryString = utils.query_string();
		let hash = (window.location.hash || queryString.hash || '').replace('#', '');
		if (hash === 'user-invite') {
			window.popupController.show(TutorialPopup, {});
			window.location.hash = '#show-tutorial';
		} else {
			window.location.hash = '';
		}
		loginController.hide();
		utils.hardReloadApp()
	};

	render() {
		return (
			<Popup tabletFullscreen opened={this.state.opened} className="g-reset-password-popup">
				<View stackPages>
					<Page className="reset-password-popup-page">
						<Navbar title={this.state.mode + ' Password'}></Navbar>
						<Content>
							<div className="label">Enter your new password</div>
							<Input className="border" value="" type="password" ref={(ref) => (this.myRefs.password = ref)} />
						</Content>
						<Button bg="blue" color="white" center onClick={this._reset}>
							{this.state.mode}
						</Button>
						<Link className="sendAnother" href="/forgot/password">
							Send another reset email
						</Link>
					</Page>
				</View>
			</Popup>
		);
	}
}

const setResetGuid = (guid, region) => {
	localStorage.clear();
	localStorage.password_reset_guid = guid;
	localStorage.region = region;
};
