import React from 'react';
import mainAPI from '../api/MainAPI';
import models from '../model/models';
import BoxScannedPopup from '../components/popups/boxScannedPopup/BoxScannedPopup';
import toasts from 'utils/toasts.js';
import getLocation from '../utils/getLocation';
import boxScannedPopupModel from '../components/popups/boxScannedPopup/BoxScannedPopup.model';
import scanViewController, {ScannerShowThrottleMs} from './scanViewController';
import offlineInventoryCheckController from './offlineInventoryCheckController';
import { barcodeAndOfflineLookup } from '../utils/barcodeLookup';
import {openEmptyBoxScannedPopup} from "./resealController";
import AppReviewPopup from "../components/popups/appReviewPopup/AppReviewPopup";
import BoxCheckScanProgressCard from "../components/pages/scanner/boxCheckScanProgressCard/BoxCheckScanProgressCard";
import uniq from 'lodash.uniq';
import {PopupAnimationMs} from "../utils/animationConstants";
import throttle from 'lodash.throttle';
let InventoryCheckController = function () {
	this.myRefs = {}
	this.start = () => {
		this.clear();
		scanViewController.showScanner('Inventory Check', 'Scan a box or a drug in the box', this._onScanText, null, true, null,
			{hideRootDelayMs:PopupAnimationMs}
		);
	};

	this.handleDuplicateScan = (text) => {
	   if(!this.lastScannedCode)return;
		 if((Date.now() - this.lastScannedCode.ts) < 1500)return;
		 if(!this.drugCodes.includes(text)) return;
		 this.lastScannedCode = {text, ts:Date.now()};
		 this.showDuplicateScanToast(text);
	}

	this.showDuplicateScanToast = throttle((text) => {
		toasts.show(`already scanned ${text}`, 1000);
	},1000,{leading:true, trailing:false});

	this.clear = () => {
		boxScannedPopupModel.clear();
	};

	this.foundBox = (box, drug) => {
		getLocation();
		scanViewController.hideMainViewDuringAnimation();
    openEmptyBoxScannedPopup(box, drug, false, this.completed)
		scanViewController.hideScanner(PopupAnimationMs * 2, 'behind');
		return { value: box, lastDrugScanned: drug }; // remove last scanned drug
	};

	this.completed = async (boxCheckId, createdTimestamp) => {
		window.f7.views.current.router.navigate('/box/check/confirm/' + boxCheckId + '/' + createdTimestamp, {props:{
			onClose:this.afterCloseConfirmPage
			}});
		const keepPopupOpen = true;
		return keepPopupOpen;
	};

	this.afterCloseConfirmPage = async () => {
		if(!window?.cordova?.InAppBrowser) return;
		const response = await mainAPI.product_feedback.shouldPrompt();
		if (response?.shouldPrompt) {
			window.popupController.show(AppReviewPopup, {response})
		}
	};

	this._onScanText = async (text, inputManually) => {
		const timeoutId = setTimeout(() => this.promptForOfflineMode(text, inputManually), 5000);
		let { drug, box, online } = await barcodeAndOfflineLookup(text);
		clearTimeout(timeoutId);
		if (!online) return this.promptForOfflineMode(text, inputManually);
		if (drug && !box) {
			box = models.drug_box.obj(drug.drug_box_id); // use main api
		}
		if (box) {
			this.foundBox(box, drug);
		} else {
			toasts.error('Box not found');
		}
	};

	this.promptForOfflineMode = (scanText) => {
		return window.f7.dialog.confirm(
			'We can save the inventory check when you are back online',
			'Would you like to continue offline?',
			() => {
				scanViewController.hideScanner();
				offlineInventoryCheckController.start(scanText);
				toasts.show('Going offline');
				this.clear();
			}
		);
	};

	this._onDrugScan = async (text) => {
		this.lastScannedCode = {text, ts:Date.now()};
		if (this.drugCodes.includes(text + '')) {
			this.drugCodesChecked.push(text);
			this.drugCodesChecked = uniq(this.drugCodesChecked);
			this.myRefs.boxCheckProgressCard.setState({progress:this.drugCodesChecked.length})
		} else {
			toasts.error('Drug not found');
		}
		setTimeout(scanViewController.showScanner, ScannerShowThrottleMs) // account for the throttle
	};

	this.resume = () => {
		console.log('inventoryCheckController.resume');
		scanViewController.hideMainViewDuringAnimation();
		scanViewController.hideScanner(PopupAnimationMs * 2, 'behind');
		window.popupController.show(BoxScannedPopup, {...this.props, drugCodesChecked: this.drugCodesChecked});
	};

	this.scanForDrugInBox = (drugCodes, drugCodesChecked, props) => {
		scanViewController.hideMainViewDuringAnimation();
		this.drugCodes = drugCodes
		this.drugCodesChecked = drugCodesChecked || [];
		this.props = props;
		window.popupController.hideAll();
		scanViewController.showScanner('Inventory Check', 'Scan a drug in this box', this._onDrugScan, null, false, this.resume,
			{ onDuplicateScan:this.handleDuplicateScan, animateFrom:'behind',hideRootDelayMs:200, forceHideManual:true, bottom: <BoxCheckScanProgressCard progress={this.drugCodesChecked.length} onContinue={this.resume} ref={ref=>this.myRefs.boxCheckProgressCard = ref} total={this.drugCodes.length}/>});
	};
};

let inventoryCheckController = new InventoryCheckController();
window.inventoryCheckController = inventoryCheckController;
export default inventoryCheckController;
