import mainAPI from "../api/MainAPI";
import hapticError from "./hapticError";
import hapticSuccess from "./hapticSuccess";
import vpnController from "../controllers/vpnController";

const queue = [];
let toasts = new (function () {
	this.show = (text, time = 2000, report=true, onClick) => {
		queue.push({ text, time, report, onClick });
		if (queue.length === 1) this.handleQueue();
	};
	this.error = (text, time=2000, report=true, onClick = null) => {
		queue.push({ text, time, report, error: true, onClick });
		if (queue.length === 1) this.handleQueue();
	};
	this._afterToastHidden = () => {
		queue.shift();
		this.handleQueue();
	};
	this.reportToastToAPI = async (message, type) => {
		try {
			const using_vpn = await vpnController.isVpnConnected();
			await mainAPI.toasts.create({message, type, using_vpn});
		} catch(e) {
			console.error(e);
		}
	};
	this.handleQueue = () => {
		const next = queue[0];
		if (!next) return;
		if(next.error){
			hapticError()
		} else {
			hapticSuccess()
		}
		window.toastController.show({key:Date.now(),success:!next.error, text: next.text, hideMs:next.time, onClick:next.onClick})
    this.reportToastToAPI(next.text, next.error ? 'error' : 'info');
	};
})();
window.toasts = toasts;

export default toasts;
