import { Component } from 'react';
import { getDisplayUnit, getUnit } from '../../utils/gramUtils';

export default class Volume extends Component {
	render() {
		return Volume.displayWithPreferredUnit(this.props.value, this.props.type);
	}

	static displayWithPreferredUnit = (mcgQuantity, type) => {
		return Volume.inPreferredUnit(mcgQuantity, type) + ' ' + getDisplayUnit(type);
	};

	static inPreferredUnit = (mcgQuantity, type) => {
		if(!mcgQuantity) return mcgQuantity;
		if (getUnit(type) === 'mg') {
			return mcgQuantity / 1000;
		} else if (getUnit(type) === 'g') {
			return mcgQuantity / (1000 * 1000);
		}
		return mcgQuantity;
	};
	static toMicrograms = (quantityInPreferredUnit, type) => {
		if(!quantityInPreferredUnit) return quantityInPreferredUnit;
		if (getUnit(type) === 'mg') {
			return quantityInPreferredUnit * 1000;
		} else if (getUnit(type) === 'g') {
			return quantityInPreferredUnit * 1000 * 1000;
		}

		return quantityInPreferredUnit;
	};
	static unit = (type) => {
		return getUnit(type);
	};
	static displayUnit = (type) => {
		return getDisplayUnit(type);
	};
}
