import * as React from "react"

export const XIcon = (props) => (
	<svg  className={"g-x-icon"} width="16" height="16" viewBox="0 0 16 16" fill="none" xmlns="http://www.w3.org/2000/svg" {...props}>
	<g clipPath="url(#clip0_154_65)">
		<path d="M1 15L15 1" stroke="white" strokeWidth="2" strokeLinecap="round" strokeLinejoin="round"/>
		<path d="M1 1L15 15" stroke="white" strokeWidth="2" strokeLinecap="round" strokeLinejoin="round"/>
	</g>
	<defs>
		<clipPath id="clip0_154_65">
			<rect width="16" height="16" fill="white"/>
		</clipPath>
	</defs>
</svg>
);