import React, {Component} from 'react';
import './ToastController.css';
import Toast from "./toast/Toast";
import {PopupAnimationMs} from "../../utils/animationConstants";
import toasts from "../../utils/toasts";

export default class ToastController extends Component{
	constructor(props) {
		super(props);
		this.state = {toast:null};
	}

	show = (toast) => {
		this.setState({toast})
	};

	_onToastHide = () => {
		setTimeout(() => {
			this.setState({toast:null});
			toasts._afterToastHidden();
		}, PopupAnimationMs);
	};

  render(){
    return(
    // <div className="g-toast-controller">
			<>
			{this.state.toast && <Toast {...this.state.toast} onHide={this._onToastHide}/>}
			</>
    // </div>
    )
  }
}