import scanViewController from './scanViewController';
import mainAPI from '../api/MainAPI';
import toasts from '../utils/toasts';

const BiometricCodeController = function () {
	this.start = (onSuccess) => {
		this.onSuccess = onSuccess;
		window.popupController.temporaryHideLast();
		this.showScanner();
	};

	this.showScanner = () => {
		scanViewController.showScanner(
			'Scan Biometric Code',
			'Place the scan area on the code',
			this._handleScan,
			null,
			true,
			this._onScannerHide,
			{forceHideManual:true, zoom:1}
		);
	};

	this._onScannerHide = () => {
		window.popupController.temporaryResumeLast();
	};

	this._handleError = (res) => {
		if (!res) {
			toasts.error('Unknown Error.');
			return true;
		}
		const {access_to_group, is_biometric_code, is_expired, is_owner} = res;
		if (!is_biometric_code) {
			toasts.error('Invalid code. Ensure you are scanning another User\'s biometric code.', 5_000);
			return true;
		}
		if (is_expired) {
			toasts.error('This code has expired.');
			return true;
		}
		if (is_owner) {
			toasts.error('You cannot scan your own code.');
			return true;
		}
		if (!access_to_group) {
			toasts.error('This user is not part of your agency.');
			return true;
		}
		return false;
	};

	this._handleScan = async (biometric_guid) => {
		const res = await mainAPI.biometric_code.get_by_guid(biometric_guid);
		scanViewController.hideScanner();
		if(this._handleError(res)){
			return window.popupController.temporaryResumeLast();
		}
		if (this.onSuccess) this.onSuccess(res);
	};
};
const biometricCodeController = new BiometricCodeController();
window.biometricCodeController = biometricCodeController;

export default biometricCodeController;
