import React, { Component } from 'react';
import './DashBoardPage.css';
import { Link, Navbar, NavLeft, NavRight, NavTitle, Page } from 'framework7-react';
import FontIcon from '../../fontIcon/FontIcon';
import DropDown from '../../dropDown/DropDown';
import InventoryCard from './inventoryCard/InventoryCard';
import models, { sockets } from '../../../model/models';
import mainAPI from '../../../api/MainAPI';
import utils from '../../../utils/utils';
import ScanToolbar from '../../toolbars/scanToolbar/ScanToolbar';
import Content from '../../helpers/content/Content';
import Label from '../../label/Label';
import PullToRefresh from '../../pullToRefresh/PullToRefresh';
import Volume from '../../volume/Volume';
import { getDisplayUnit } from '../../../utils/gramUtils';
import offlineSyncController from '../../../controllers/offlineSyncController';
import hapticSuccess from "../../../utils/hapticSuccess";
import throttle from 'lodash.throttle';
import dashboardController from "../../../controllers/dashboardController";
import {Copy} from "../../svg/Copy";
import toasts from "../../../utils/toasts";
import {isCordova} from "../../../utils/isCordova";

export const DashBoardPageRoute = {name: 'home', path: '/'};
export default class DashBoardPage extends Component {
	constructor(props) {
		super(props);
		this.state = {
			drugs: [],
			lastCheck: '',
			loading:true
		};
		this.myRefs = {};
	}

	componentDidMount = () => {
		this._drugsChanged();
		dashboardController.onRefresh(this.refreshDashboard);
		this.userSubscribeId = models.user.onCurrentChange(this._userChanged);
		this.checkUrl();
	};

	componentWillUnmount = () => {
		models.user.unsubscribe(this.userSubscribeId);
		if (this.socketSubscribeId) sockets.stock_move_record.off(this.socketSubscribeId);
	};

	checkUrl = () => {
		const queryString = utils.query_string();
		let hash = (window.location.hash || queryString.hash || '').replace('#', '');
		if (hash === 'set-storage') {
			localStorage.clear();
			const storage = JSON.parse(queryString.storage);
			for (let i in storage) {
				localStorage.setItem(i, storage[i]);
			}
			window.location.hash = '';
			window.location = '/'
		}
	};

	_refresh = async () => {
    const promises = [
		 models.user.refreshData(),
		 models.user_group.refreshData(),
		 models.drug_box.refreshData(),
			this._boxChanged(localStorage.lastBoxId, false)
    ];
    await Promise.all(promises);
		await offlineSyncController.start().catch(console.error);
		this.pullToRefresh.finish();
		hapticSuccess();
	};

	_listenForStockMovement = () => {
		let user = models.user.getCurrent();
		if (!user) return;
		if (this.socketSubscribeId) return;
		this.socketSubscribeId = sockets.stock_move_record.on(user.user_group_id, this._refreshDrugs);
	};

	_userChanged = async () => {
		let user = models.user.getCurrent();
		if (user?.id) offlineSyncController.start().catch(console.error);
		this._listenForStockMovement();
		if (!user || !user.id) return;
		if(this.state.loading)return;
		if(this.state.drugs?.length) return;
		let { lastUsedBox, lastCheck, drugs } = await this._getBoxDetails(null);
		this.setState({ lastUsedBox, lastCheck, drugs });
	};

	_refreshDrugs = async () => {
		return this._drugsChanged();
	};

	_drugsChanged = async () => {
		const {drugs,lastUsedBox, lastCheck} = await this._getBoxDetails(localStorage.lastBoxId);
		this.setState({ lastCheck, drugs, lastUsedBox });
	};

	_lastCheck = async (drug_box_id) => {
		let lastCheckedDrug = await mainAPI.box_check.last_check_for_box(drug_box_id);
		if (lastCheckedDrug) {
			return [
				<FontIcon name="icon-check" color="green" key="icon" />,
				<div key="last-checked">
					Last verified by {lastCheckedDrug.user__first_name + ' ' + lastCheckedDrug.user__last_name}{' '}
					{utils.display_datetime(lastCheckedDrug.created_on)}
				</div>
			];
		}
		return '';
	};

	_drugCount = (type) => {
		let count = 0;
		let lastUsedBox = this.state.lastUsedBox || {};
		for (let i in this.state.drugs) {
			let drug = this.state.drugs[i];
			if (type === drug.type && drug.stock_move_record__quantity > 0 && drug.drug_box_id == lastUsedBox.id) count++;
		}
		return count;
	};

	_totalDrugAmount = (type) => {
		let totalMcg = 0;
		let lastUsedBox = this.state.lastUsedBox || {};
		for (let i in this.state.drugs) {
			let drug = this.state.drugs[i];
			if (type === drug.type && drug.drug_box_id == lastUsedBox.id) {
				totalMcg += parseFloat(this.state.drugs[i].stock_move_record__quantity || 0);
			}
		}
		return Volume.inPreferredUnit(totalMcg, type);
	};

	_getCard = (type, i) => {
		let count = this._drugCount(type);
		const total = this._totalDrugAmount(type);
		let displayUnit = getDisplayUnit(type);
		const drugType = models.drug_type.obj(type);
		if (count == 0) return null;
		return (
			<InventoryCard  count={count} total={total} displayUnit={displayUnit} drug={drugType.name} key={`inventory-card-${i}`} />
		);
	};

	_displayCards = () => {
		if(this.state.loading && this.state.drugs?.length === 0){
			return 	<InventoryCard  loading count={0} total={0} displayUnit={''} drug={' '} key={`inventory-card-loading`} />
		}
		let types = models.drug_type.allowed();
		let cards = [];
		for (let i in types) {
			let card = this._getCard(types[i].id, i);
			if (card !== null) cards.push(card);
		}
		if (cards.length == 0) {
			return <div className="empty-box">This box is empty</div>;
		}
		return cards;
	};

	getBoxById = async (boxId) => {
		if (!boxId) return null;
		return mainAPI.drug_box.get(boxId);
	};

	_getBoxDetails = async (boxId) => {
		let lastUsedBox = await this.getBoxById(boxId);
		let drugPromise = Promise.resolve([]);
		if (!lastUsedBox && !boxId && !localStorage.lastBoxId) {
			const boxes = models.drug_box.objs();
			for (let i in boxes) {
				lastUsedBox = boxes[i];
				break;
			}
		}
		if (!lastUsedBox) return {};
		if(lastUsedBox.id){
			drugPromise = mainAPI.drug.for_box(lastUsedBox.id);
		}
		const [lastCheck, drugs] = await Promise.all([this._lastCheck(lastUsedBox.id), drugPromise]);
		return { lastUsedBox, lastCheck, drugs };
	};

	refreshDashboard = async (boxId) => {
		await this._boxChanged(boxId);
		await this.myRefs.drug_box_id._setOptions()
	};
	_boxChanged = async (boxId, setNull=true) => {
		if(setNull) {
			this.setState({loading: true, drugs: [], lastUsedBox: null, lastCheck: null})
		}
		if (boxId) localStorage.lastBoxId = boxId;
		let { lastUsedBox, lastCheck, drugs } = await this._getBoxDetails(boxId);
		this.setState({ lastUsedBox, lastCheck, drugs, loading:false });
	};

	_renderSandboxMode = () => {
		if (!localStorage.useSandbox) return null;
		return (
			<Label small white className="sandbox-label">
				SANDBOX
			</Label>
		);
	};

	_scrollingContainerElement = () => {
		return document.querySelector('.g-dash-board-page .page-content');
	};

	render() {
		let lastUsedBox = this.state.lastUsedBox || {};

		return (
			<Page className="g-dash-board-page">
				<Navbar innerClassName={'g-dash-board-page-nav-bar-inner'}>
					<NavLeft>
						<Link color="white" panelOpen="left">
							<FontIcon
								name="icon-menu"
								style={{
									transform: 'scale(1.6)',
									height: '36px',
									width: '26px'
								}}
							/>
						</Link>
					</NavLeft>
					<NavTitle sliding>
						<DropDown
							onOpen={throttle(models.drug_box.refreshData, 2000,{ leading: true, trailing: false })}
							className="g-navbar-box-select"
							onChange={this._boxChanged}
							getDisplayValue={formatDrugBoxName}
							value={lastUsedBox.id}
							ref={(ref) => (this.myRefs.drug_box_id = ref)}
							placeholder="Drug Box"
							endpoint={mainAPI.drug_box.all_with_unit}
							setFirstValueIfNotSet={!localStorage.lastBoxId}
						/>
					</NavTitle>
					<NavRight></NavRight>
				</Navbar>
				<PullToRefresh
					ref={(ref) => (this.pullToRefresh = ref)}
					parent={this._scrollingContainerElement()}
					onPullToRefresh={this._refresh}
				>
					<Content className="current-inventory-row ">
						<h3>Current Inventory</h3>
						<div className="box-row">
							<div className={'box-barcode-label'}>Box Code:</div>
							<div className={'box-barcode selectable'}>{utils.upperCase(lastUsedBox.barcode_id)}</div>
							{lastUsedBox.barcode_id && canCopyToClipboard() && <Label onClick={e => copyToClipboard(lastUsedBox.barcode_id?.toUpperCase())}><Copy className={'copy-button'}/></Label>}
						</div>
						<div className="last-check">{this.state.lastCheck}</div>
						{this._displayCards()}
					</Content>
				</PullToRefresh>
				<ScanToolbar />
			</Page>
		);
	}
}

export const formatDrugBoxName = (drugBox) => {
   if(!drugBox.unit__name){
		 return drugBox.drug_box__name;
	 }
	return `${drugBox.drug_box__name} (${drugBox.unit__name})`;
};

const canCopyToClipboard = () => {
	if(isCordova()){
		if(window?.cordova?.plugins?.clipboard?.copy){
			return true;
		}
		return false;
	}else {
		return true;
	}
};
const copyToClipboard = (text) => {
	if(!text)return;
	if(isCordova()){
		window.cordova.plugins.clipboard.copy(text);
	}else {
		navigator.clipboard.writeText(text)
	}
	toasts.show(`copied to clipboard (${text})`, 1000);
};