import React, { Component } from 'react';
import ReactDatePicker from 'react-datepicker';
import './DatePicker.css';
import 'react-datepicker/dist/react-datepicker.css';
import utils from '../../utils/utils';
import Validation from '../validation/Validation';
import FontIcon from "../fontIcon/FontIcon";

const formatValue = (datetime) => {
	if (!datetime) return '';
	if (!datetime.getMonth) return '';
	let month = (datetime.getMonth() + 1 + '').padStart(2, '0');
	let day = (datetime.getDate() + '').padStart(2, '0');
	let dtArray = [datetime.getFullYear(), month, day];
	return dtArray.join('-');
};
const fromDateString = (dateString) => {
	const [year, month, day] = dateString.split('-').map(Number);
	return new Date(year, month - 1, day);
};

export default class DatePicker extends Component {
	constructor(props) {
		super(props);
		this.state = {
			value: typeof this.props.value === 'string' ? fromDateString(this.props.value): this.props.value,
			valid: true
		};
	}
	componentDidMount() {
		if(this.props.autoFocus){
			this.datePickerRef.setFocus();
		}
	}

	componentWillReceiveProps(nextProps, nextContext) {
		if (this.props.uncontrolled) {
			this.setState({ value: nextProps.value });
		}
	}

	isValid = () => {
		let valid = true;
		let date = formatValue(this.state.value);
		if (!utils.is_client_date(new Date(date))) {
			valid = false;
		}
		if (this.props.required && !date) {
			valid = false;
		}
		this.setState({ valid });
		return valid;
	};

	getValue = () => {
		return formatValue(this.state.value);
	};

	onChange = (value) => {
		this.setState({ value }, () => {
			if (this.props.onChange) {
				this.props.onChange(formatValue(value));
				this.isValid();
			}
		});
	};
	onFocus = () => {
		utils.hideKeyboard();
	};

	renderClearButton = () => {
		return <FontIcon className={'icon-clear-value'} name="icon-close" onClick={this.clearValue}/>
	};

	clearValue = () => {
		this.setState({value:null})
		this.props.onChange(null);
	};

	renderInvalidText = () => {
		if (!this.state.valid) {
			return <Validation />;
		}
	};

	render() {
		return (
			<div className={"g-date-picker " + this.props.className ||''} data-placeholder={this.state.value?this.props.placeholder: ''}>
				{this.renderClearButton()}
				<ReactDatePicker
					onFocus={this.onFocus}
					onBlur={this.props.onBlur}
					onCalendarClose={this.props.onCalendarClose}
					placeholderText={this.props.placeholder}
					onChange={this.onChange}
					selected={this.state.value}
					ref={ref=>this.datePickerRef = ref}
				/>

				{this.renderInvalidText()}
			</div>
		);
	}
}
