import React, { Component } from 'react';
import './NewDrugPopup.css';
import { View } from 'framework7-react';
import DrugDetailsPage from 'components/pages/drugDetailsPage/DrugDetailsPage.jsx';
import models from 'model/models';
import toasts from '../../../utils/toasts';
import Popup from "../popup/Popup";

export default class NewDrugPopup extends Component {
	close = () => {
		window.popupController.hide(NewDrugPopup);
	};

	submitBox = async (drug_box, barcodes) => {
		let drugBox = { name: drug_box.name, barcode_id: barcodes[0].barcode_id };
		let res = await models.drug_box.save(drugBox);
		if (!res) return toasts.error('Could not create Drug Box');
		this.close();
	};

	render() {
		return (
			<Popup tabletFullscreen opened={this.props.opened} className="g-new-drug-popup">
				<View stackPages>
					<DrugDetailsPage
						submitDrug={this.props.onDoneCreating}
						submitBox={this.submitBox}
						startBarcodeImport={this.startBarcodeImport}
						state={this.props.state}
					/>
				</View>
			</Popup>
		);
	}
}
