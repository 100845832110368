import React, { Component } from 'react';
import './ScanToolbar.css';
import * as F7 from 'framework7-react';
import Button from '../../button/Button';
import inventoryCheckController from '../../../controllers/inventoryCheckController';
import scanController from '../../../controllers/scanController';
import Icon from '../../icon/Icon';
import utils from 'utils/utils.js';
import CodeAuthenticationPopup from '../../popups/codeAuthenticationPopup/CodeAuthenticationPopup';
import toasts from '../../../utils/toasts';

class Toolbar extends Component {
	constructor(props) {
		super(props);
		this.state = {
			areBiometricsAvailable: false,
			overrideBiometrics: false,
		};
	}

	overrideBiometrics = () => {
		const bioCheck = localStorage.getItem('bioCheck');
		const override = (bioCheck === 'false' || bioCheck === '0') ? false : !!bioCheck;
		return override;
	}

	componentDidMount = () => {
		this.setState({ overrideBiometrics: this.overrideBiometrics() });
		utils.areBiometricsAvailable(() => {
			this.setState({ areBiometricsAvailable: true });
		}, console.error);

		setTimeout(() => {
			utils.areBiometricsAvailable(() => {
				this.setState({ areBiometricsAvailable: true });
			}, console.error);
		}, 3000); // fallback to make sure the check is made
	};

	renderBiometricIcon = () => {
		const {areBiometricsAvailable, overrideBiometrics}  = this.state;

		if(overrideBiometrics) {
			return <><Icon onClick={() => {
				window.popupController.show(CodeAuthenticationPopup, {})
			}} size={'xxl'} name="biometric"> </Icon></>;
		}
		if (areBiometricsAvailable) {
			return <>
				<Icon
					onClick={() => {
						utils.biometricCheck(
							() => { window.popupController.show(CodeAuthenticationPopup, {}) },
							() => { toasts.error('Could not access biometrics') }
						);
					}} size={'xxl'} name="biometric">
				</Icon>
			</>;
		}
		return <div className="divider"></div>;
	}

	render() {
		return (
			<F7.Toolbar className="g-scan-toolbar">
				<Button bg="darkblue" color="white" onClick={inventoryCheckController.start}>
					Inventory Check
				</Button>
				{this.renderBiometricIcon()}
				<Button bg="darkblue" color="white" className={'scan'} center onClick={scanController.start}>
					Scan
				</Button>
			</F7.Toolbar>
		);
	}
}

Toolbar.displayName = 'f7-toolbar';
export default Toolbar;
