import React, { Component } from 'react';
import { Popup } from 'framework7-react';
import './OptionsPopup.css';
import Card from '../../card/Card';
import Label from '../../label/Label';
import PrimaryButton from '../../primaryButton/PrimaryButton';
import TertiaryButton from '../../tertiaryButton/TertiaryButton';
import SecondaryButton from '../../secondaryButton/SecondaryButton';

export default class OptionsPopup extends Component {
	close = () => {
		window.popupController.hide(OptionsPopup);
	};

	handleSelect = (value) => {
    this.close();
		this.props.handleSelect(value);
	};

	render() {
		let option1 = null;
		if (this.props.option1) {
			option1 = (
				<PrimaryButton onClick={() => this.handleSelect(this.props.option1.value)}>
					{this.props.option1.label}
				</PrimaryButton>
			);
		}
		let option2 = null;
		if (this.props.option2) {
			option2 = (
				<SecondaryButton onClick={() => this.handleSelect(this.props.option2.value)}>
					{this.props.option2.label}
				</SecondaryButton>
			);
		}
		let option3 = null;
		if (this.props.option3) {
			option3 = (
				<TertiaryButton onClick={() => this.handleSelect(this.props.option3.value)}>
					{this.props.option3.label}
				</TertiaryButton>
			);
		}
		return (
			<Popup opened={this.props.opened} tabletFullscreen={true} className="g-options-popup never-transparent">
        <div onClick={this.close} className="transparent-backdrop" />
        <Card>
					<Label dark large center>
						Select an option
					</Label>
					{option1}
					{option2}
					{option3}
				</Card>
			</Popup>
		);
	}
}
