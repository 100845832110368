import React, {Component} from 'react';
import './HideShowPassword.css';
import EyeBall from "../svg/EyeBall";
import EyeBallHidden from "../svg/EyeBallHidden";

export default class HideShowPassword extends Component {
	constructor(props) {
		super(props);
		this.state = {hidden: true};
	}

	toggle = () => {
		const hidden = !this.state.hidden;
		const shown = !hidden;
		this.setState({hidden});
		if(this.props.onChange){
			this.props.onChange(shown);
		}
	}

	render() {
		return (
			<div className="g-hide-show-password" onClick={this.toggle}>
				{this.state.hidden ? <EyeBall/> :<EyeBallHidden/>}
			</div>
		)
	}
}