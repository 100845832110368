let hapticFeedbackAvailable = true;
const hapticSuccess = () => {
	if(window.TapticEngine && hapticFeedbackAvailable) {
		try {
			window.TapticEngine.notification({
				type: "success" // success | warning | error
			}, console.log, (e) => {
				console.error('hapticSuccess callback error', e);
				hapticFeedbackAvailable = false;
				if(navigator.vibrate){
					navigator.vibrate([5, 150, 5])
				}
			});
		} catch (e) {
			hapticFeedbackAvailable = false;
			console.error('hapticSuccess error', e);
			if(navigator.vibrate){
				navigator.vibrate([5, 150, 5])
			}
		}
	}else if(navigator.vibrate){
		navigator.vibrate([5, 150, 5])
	}
};


export default hapticSuccess;