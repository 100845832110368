import React, { Component } from 'react';
import './Label.css';

export default class Label extends Component {
	constructor() {
		super();
		this.state = {
			pressed:false
		}
	}
	_getClasses = () => {
		return (this.props.className || '') + ' g-label ' + this._propsToClass();
	};

	_propsToClass = () => {
		let props = [
			'center',
			'justify', //text-align
			'x-small',
			'small',
			'medium-small',
			'medium',
			'large',
			'larger',
			'largest', //size
			'italic',
			'bold',
			'pastel', //style
			'dark',
			'light',
			'dark-gray',
			'green',
			'white',
			'onClick',
		]; //color
		let classes = [];
		for (let i in props) {
			if (this.props[props[i]]) {
				classes.push(props[i]);
			}
		}
		if(this.state.pressed) {
			classes.push('pressed');
		}
		return classes.join(' ');
	};

	touchStart = () => {
		if(!this.props.onClick)return;
	  this.setState({pressed:true})
	};

	touchEnd = () => {
		if(!this.props.onClick)return;
	  this.setState({pressed:false})
	};

	render() {
		return (
			<div onClick={this.props.onClick}
					 onMouseDown={this.touchStart}
					 onMouseUp={this.touchEnd}
					 onMouseLeave={this.touchEnd}
					 onTouchStart={this.touchStart}
					 onTouchEnd={this.touchEnd}
					 onTouchCancel={this.touchEnd}
					 className={this._getClasses()}>
				{this.props.children}
			</div>
		);
	}
}
