import React, { useState, useEffect } from "react";

const Image = ({ src, headers = {}, alt = "", ...props }) => {
	const [imageSrc, setImageSrc] = useState(null);
	const [loading, setLoading] = useState(true);
	const [error, setError] = useState(false);

	useEffect(() => {
		if (!src) return;
		// if(imageSrc) return;
		const fetchImage = async () => {
			try {
				setLoading(true);
				const response = await fetch(src, { headers });

				if (!response.ok) throw new Error("Failed to load image");

				const blob = await response.blob();
				const objectURL = URL.createObjectURL(blob);
				setImageSrc(objectURL);
			} catch (err) {
				console.error("Error loading image:", err);
				setError(true);
			} finally {
				setLoading(false);
			}
		};

		fetchImage();

		// Cleanup object URL when component unmounts
		return () => {
			if (imageSrc) URL.revokeObjectURL(imageSrc);
		};
	}, [src, headers]);

	if (loading) return <div></div>;
	if (error) return <div></div>;

	return <img src={imageSrc} alt={alt} {...props} />;
};

export default Image;
