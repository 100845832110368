import React from 'react';
import './Login.css';
import { Link, Navbar, Page } from 'framework7-react';
import Input from '../../input/Input';
import Button from '../../button/Button';
import FormTemplate from '../../templates/formTemplates/FormTemplate';
import mainAPI from 'api/MainAPI.js';
import toasts from 'utils/toasts.js';
import ChooseGroupPopup from '../../popups/chooseGroupPopup/ChooseGroupPopup';
import utils from '../../../utils/utils';
import CheckBox from '../../checkBox/CheckBox';
import EnterDualFactorCodePopup from '../../popups/enterDualFactorCodePopup/EnterDualFactorCodePopup';
import loginController from '../../../controllers/loginController';
import throttle from 'lodash.throttle';

export default class Login extends FormTemplate {
	constructor(props) {
		super(props);
		this.state = { biometricsAreAvailable: false };
	}

	async componentDidMount() {
		const biometricsAreAvailable = await utils.areBiometricsAvailableAsync();
		this.setState({ biometricsAreAvailable });
	}

	continue = async () => {
		delete localStorage.password_reset_guid;
		delete localStorage.user_group_id;
		const formValue = this.getValue();
    const id = setTimeout(() => {
       toasts.error('Your network may be slow')
    }, 2500);
		let userStatus = await mainAPI.user.loginStatus({ username: formValue.username });
    clearTimeout(id)
		if (userStatus?.inactive_after_date) {
			return toasts.error('Your account has been disabled. Please contact your agency admin if this is a mistake', 2500);
		}
		let user = await mainAPI.user.login(formValue);
		if (formValue.isPersonalDevice) {
			localStorage.isPersonalDevice = true;
		} else {
			delete localStorage.isPersonalDevice;
		}
		if (user && user.token) {
			onLoginSuccess(user);
		} else if (user && user.dual_factor_guid) {
			return window.popupController.show(EnterDualFactorCodePopup, { dualFactorGuid: user.dual_factor_guid });
		} else {
			toasts.error('Incorrect email or password');
		}
	};

	renderBiometricLogin = () => {
		if (!this.state.biometricsAreAvailable) return null;
		return (
			<div className="personal-device">
				<CheckBox ref={(ref) => (this.myRefs.isPersonalDevice = ref)} />
				<p>Enable biometric login? (not recommended for shared devices)</p>
			</div>
		);
	};

	render() {
		return (
			<Page className="g-login" name="login">
				<Navbar title="Log-in" backLink="Back"></Navbar>
				<Input showCancelX value={localStorage.last_login_email || ''} ref={(ref) => (this.myRefs.username = ref)} center placeholder="Email address"	onEnter={this.continue}/>
				<Input
					ref={(ref) => (this.myRefs.password = ref)}
					center
					placeholder="Password"
					type="password"
					onEnter={this.continue}
				/>
				{this.renderBiometricLogin()}

				<Link className="forgot" href="/forgot/password">
					Password Reset
				</Link>
				{/*<ListButton title="Sign In" closeLoginScreen />*/}
				<Button center color="white" bg="blue" onClick={throttle(this.continue, 1500, { leading: true, trailing: false })}>
					Continue
				</Button>
				<p className="no-account">
					Don't have an account? <Link href="/signup/">Contact us</Link>
				</p>
			</Page>
		);
	}
}

export const onLoginSuccess = (user) => {
	utils.setToken(user.token, user.region, user.primary_email);
	loginController.hide();
	if (user.num_additional_groups >= 1) {
		return window.popupController.show(ChooseGroupPopup, {});
	}
	utils.hardReloadApp()
};

