import * as React from "react"

const EyeBallHidden = ({className, ...props}) => {
	const classes = 'g-eye-ball-hidden ' + (className || '')
	return <svg  className={classes}  fill="none" height="16" role="img" viewBox="0 0 16 16" width="16" xmlns="http://www.w3.org/2000/svg" {...props}>
		<path clipRule="evenodd"
					d="M13.719 1.878 1.775 13.822l1.06 1.06 2.787-2.786c.7.223 1.491.358 2.378.358 5.09 0 7-4.454 7-4.454s-.696-1.625-2.37-2.912l2.15-2.15-1.061-1.06Zm-3.54 5.66L7.54 10.18a2.23 2.23 0 0 0 2.64-2.64Z"
					fill="var(--rh__text-color)" fillRule="evenodd"></path>
		<path
			d="M5.794 7.689A2.232 2.232 0 0 1 7.69 5.794l2.06-2.06A7.982 7.982 0 0 0 8 3.545C2.91 3.545 1 8 1 8s.574 1.34 1.933 2.55l2.861-2.86Z"
			fill="var(--rh__text-color)"></path>
	</svg>
};
export default EyeBallHidden;
