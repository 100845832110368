import React, {Component} from 'react';
import './ImageInput.css';
import axios from "axios";
import mainAPI from "../../api/MainAPI";
import Icon from "../icon/Icon";
import utils from "../../utils/utils";
import toasts from "../../utils/toasts";
import Image from "../image/Image";

export default class ImageInput extends Component {
  constructor(props){
    super(props)
    this.state = {
      id: Date.now(), // needs to be unique.
			valid:true,
			imageUrl: props.value
    }
  }

  getValue = () => {
     return this.state.imageUrl;
  }
	_isValid = () => {
		if(!this.state.imageUrl && this.props.required){
			return false;
		}
		return true;
	}
	isValid = () => {
		const valid = this._isValid();
		this.setState({valid});
		return valid;
	};

  uploadImage = async (file, fileName) => {
		const timeout = setTimeout(() => {
			 throw Error('Upload Timeout');
		}, 15000);
    const url =  mainAPI.rootURL() + '/api/v1/image/upload';
    const formData = new FormData();
    formData.append('file', file, fileName);
    const config = {
      headers: {
        'Content-Type': 'multipart/form-data',
        last_log_in: Date.now(),
				'x-auth-token': localStorage.token
      }
    };
    const response = await axios.post(url, formData, config);
		clearTimeout(timeout);
		return response
  }
  onInputImageChange = async (event) => {
		const file = event.target.files[0];
		await this.getImage(file);
	}
	fullImageUrl = (imageUrl) => {
		if(!imageUrl) return null;
		return  mainAPI.rootURL() + imageUrl
	};
  getImage = async (file, fileName) => {
		if(!file)return;
    this.setState({uploading:true, valid: true})
    const response = await this.uploadImage(file, fileName || file.name).catch(() => {
			toasts.error('Upload failed');
			this.setState({uploading:false, valid: this._isValid()});
    });
    const imageUrl = response?.data?.data?.url;
    this.setState({imageUrl, uploading:false, valid: true})
  }
	getIcon = () => {
	   if (this.state.uploading) {
			 return <div className="spinning-loading" />
		 } else {
			 return <Icon name='camera' onClick={this.openAndroidCamera}/>
		 }
	};
	onAndroidFileChange = (imageURI) => {
		const self = this;
		const onError = (e) => {
			console.error(e);
			toasts.error('Could not access file');
		};
		window.resolveLocalFileSystemURL(imageURI, (fileEntry) => {
			fileEntry.file(async (file) => {
				const reader = new FileReader();
				reader.onloadend = async function () {
					const blob = new Blob([this.result], { type: file.type} );
					await self.getImage(blob, file.name)
				};
				reader.readAsArrayBuffer(file);
			});
		}, onError);
	};
	openAndroidCamera = () => {
		if(!utils.isPlatform('android')){
			return;
		}
		navigator.camera.getPicture(this.onAndroidFileChange, (e) => {
		   console.error('navigator.camera.getPicture', e)
			toasts.error('Camera and Storage permissions needed. Touch to open settings.', 4000, true, window.QRScanner.openSettings);
		}, {
			quality: 100, // Adjust the photo quality
			destinationType: window.Camera.DestinationType.FILE_URI, // Save as file URI
			sourceType: window.Camera.PictureSourceType.CAMERA, // Directly open the camera
			encodingType: window.Camera.EncodingType.JPEG, // Use JPEG format
			saveToPhotoAlbum: false, // Save the photo to the device
			correctOrientation: true, // Adjust for device orientation
		});
	};
  render(){
    return(
    <div className={`g-image-input ${this.state.uploading ? 'uploading': ''} ${this.state.valid ? '': 'invalid'}`}>
      {this.state.imageUrl && <Image src={this.fullImageUrl(this.state.imageUrl)} headers={{'x-auth-token':localStorage.token}} alt={'inventory-check'} />}
      <label htmlFor={'g-image-input-' + this.state.id} onChange={this.getImage}>{this.getIcon()}</label>
			{utils.isPlatform('android') ? <div/>:<input id={'g-image-input-' + this.state.id} onChange={this.onInputImageChange} type='file' accept="image/*"
						 capture="camera"/>}
    </div>
    )
  }
}