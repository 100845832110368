import React, { Component } from 'react';
import { Link, Navbar, NavRight, Page } from 'framework7-react';
import './BoxCheckConfirmPage.css';
import Label from '../../label/Label';
import utils from 'utils/utils';
import Content from '../../helpers/content/Content';
import Card from '../../card/Card';
import models from '../../../model/models';
import FontIcon from '../../fontIcon/FontIcon';

export default class BoxCheckConfirmPage extends Component {
	constructor(props) {
		super(props);
		this.state = {};
	}

	_usersName = () => {
		let user = models.user.getCurrent();
		return user.first_name + ' ' + user.last_name;
	};

	render() {
		return (
			<Page noBackSwipe className="g-box-check-confirm-page">
				<Navbar title="Inventory Check Complete">
					<NavRight>
						<Link popupClose>
							{' '}
							<FontIcon name="icon-close" onClick={this.props.onClose} />{' '}
						</Link>
					</NavRight>
				</Navbar>
				<Content>
					<Label large dark>
						Inventory Check
					</Label>
					<Card>
						<Label>User</Label>
						<Label dark>{this._usersName()}</Label>
						<Label>Timestamp</Label>
						<Label dark>{utils.display_datetime(new Date(parseInt(this.props.timeStamp)))}</Label>
						<Label>Inventory Check ID</Label>
						<Label dark>{this.props.id}</Label>
					</Card>
				</Content>
			</Page>
		);
	}
}
