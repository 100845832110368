import React, { Component } from 'react';
import { Popup } from 'framework7-react';
import './BoxActionsPopup.css';
import Card from '../../card/Card';
import Label from '../../label/Label';
import PrimaryButton from '../../primaryButton/PrimaryButton';
import TertiaryButton from '../../tertiaryButton/TertiaryButton';
import SecondaryButton from '../../secondaryButton/SecondaryButton';
import scanViewController from "../../../controllers/scanViewController";

export default class BoxActionsPopup extends Component {
	close = () => {
		window.popupController.hide(BoxActionsPopup);
	};

	closeAndResumeScanner = () => {
	  this.close();
		scanViewController.unPauseScanner();
	};

	handleDailyCheck = () => {
		this.close();
		this.props.handleDailyCheck();
	};

	handleTransfer = () => {
		this.close();
		this.props.handleTransfer();
	};

	handleReseal = () => {
		this.close();
		this.props.handleReseal();
	};

	render() {
		return (
			<Popup
				opened={this.props.opened}
				tabletFullscreen={true}
				className="g-box-actions-popup never-transparent"
			>
        <div onClick={this.closeAndResumeScanner} className="transparent-backdrop" />
        <Card>
					<Label dark large center>
						Select an option
					</Label>
					<PrimaryButton onClick={this.handleDailyCheck}>Inventory Check</PrimaryButton>
					<SecondaryButton onClick={this.handleTransfer}>Transfer Unit</SecondaryButton>
					<TertiaryButton onClick={this.handleReseal}>Reseal</TertiaryButton>
				</Card>
			</Popup>
		);
	}
}
