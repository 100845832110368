import React, {Component, useEffect, useState} from 'react';
import './ScannedDrugs.css';
import Icon from "../icon/Icon";
import utils from "../../utils/utils";
import Label from "../label/Label";

export default class ScannedDrugs extends Component{

	render(){

    return(
    <div className="g-scanned-drugs">
			{this.props.drugs?.length > 5 && <Label className={'drug-count'} white>Total {this.props.drugs.length}</Label>}
			<ul>{this._displayScannedDrugs()}</ul>
    </div>
    )
  }

	_displayScannedDrugs = () => {
		let drugs = this.props.drugs
		if (!drugs) {
			return null;
		}
		let drugsBarcodeIds = [];
		for (const drug of drugs) {
			let id = <BarcodeRow key={drug.barcode_id} barcodeId={drug.barcode_id}/>
			drugsBarcodeIds.push(id);
		}
		return drugsBarcodeIds.reverse();
	};
}

const BarcodeRow = ({barcodeId}) => {
	const[classes, setClasses] = useState('');
	useEffect(() => {
		setTimeout(() => {
	   setClasses( 'animating-in');
		}, 50);
	}, [])
   return <li className={classes + ' selectable '} key={barcodeId}> <Icon name="data-matrix"/>{`${utils.upperCase(barcodeId)}`}</li>
};