import React from 'react';
import './ForgotPasswordPage.css';
import { Navbar, Page } from 'framework7-react';
import Content from '../../helpers/content/Content';
import Input from '../../input/Input';
import FormTemplate from '../../templates/formTemplates/FormTemplate';
import Button from '../../button/Button';
import mainAPI from '../../../api/MainAPI';
import toasts from '../../../utils/toasts';

export default class ForgotPasswordPage extends FormTemplate {
	_send = async () => {
		let value = this.getValue();
		const email = value.email.trim();
		let userStatus = await mainAPI.user.loginStatus({ username: email });
		if (userStatus?.inactive_after_date) {
			return toasts.error('Your account has been disabled. Please contact your agency admin if this is a mistake', 2500);
		}
		if(!email.includes('@')){
			return toasts.error(`Enter a valid email (${email})`)
		}
		await mainAPI.user.forgot_password({ email });
		toasts.show('Check your email', 10000);
		window.f7.views.login.router.back();
		// window.f7.views.current.router.back();
	};

	render() {
		return (
			<Page className="g-forgot-password-page">
				<Navbar title="Forgot Password" backLink="Back"></Navbar>
				<Content>
					<div className="label">Enter your email</div>
					<Input border className="border" value="" ref={(ref) => (this.myRefs.email = ref)} />
				</Content>
				<Button bg="blue" color="white" onClick={this._send} center>
					Send Reset Email
				</Button>
			</Page>
		);
	}
}
