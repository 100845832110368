import axios from 'axios';
import utils from 'utils/utils';
import loginController from '../controllers/loginController';
import listenForInteraction from '../utils/listenForInteraction';
import biometricReAuthenticateController from '../controllers/biometricReAuthenticateController';
import mainAPI from './MainAPI';
import {VERSION} from "../components/pages/loginHome/LoginHome";
import {isCordova} from "../utils/isCordova";

let axiosConfig = {
	headers: {
		'Content-Type': 'application/json',
		'Access-Control-Allow-Origin': '*'
	}
};

/*
 * Default parameters for handleError function.
 */
const defaults = {
	error: { response: {data: {}}, request: {data: {}}, config: {}},
	options: {type: 'Network Error'}
};


/**
 * An error object returned from an axios request.
 * @typedef {Object} AxiosError
 * @property {Object} config - AxiosRequestConfig
 * @property {String} code - Error code
 * @property {any} request - Axios request
 * @property {Object} response - AxiosResponse
 */

/**
 * Handle Error and return error object.
 *
 * New version of the handleNetworkError function that now returns the error object.
 * Responses of 403, 401, and 404 are handled. All other errors create an entry in the error_log table.
 *
 * @param {AxiosError} [error] - AxiosError object
 * @param {Object} [options] - Options object
 * @param {String} [options.type=Network Error] - Type of error
 * @return {Promise<AxiosError>} Error object.
 */
export const handleError = async ({ error = defaults.error, options: {type} = defaults.options } = {}) => {
	const { response, response: {status, data: resData}, request: {data: reqData}, config: {url, method}} = error;
	switch (status) {
		case 403:
			console.log('User is not authorized for API');
			return {error};
		case 401:
			listenForInteraction.pause();
			if (await biometricReAuthenticateController.isEligible()) {
				biometricReAuthenticateController.show();
				return {error};
			}
			console.log('User Logged Out');
			loginController.show();
			return {error};
		case 404:
			return {error};
	}
	const message = `HTTP: ${status} URL: ${url} METHOD: ${method} RESPONSE: ${JSON.stringify(resData)} REQUEST: ${JSON.stringify(reqData)}`;
	await mainAPI.error_log.create({type, message});
	console.log('ERROR', error, status, response);
	return {error};
}

export const handleNetworkError = async (e) => {
	let response = e.response || {};
	let status = response.status;
	if (status === 403) {
		return console.log('User is not authorized for API');
	} else if (status === 401) {
		listenForInteraction.pause();
		if (await biometricReAuthenticateController.isEligible()) {
			return biometricReAuthenticateController.show();
		}
		console.log('User Logged Out');
		return loginController.show();
	} else if (status === 404) {
		return;
		//do nothing
	}

	await mainAPI.error_log.create({
    type: 'Network Error',
    message:
      status +
      e?.config?.url +
      e?.config?.method +
      ' ' + JSON.stringify(response?.data) +
			' ' + (e?.config?.data ) +
			' ' + e
	});
	console.log('ERROR', e, status, response);
	// throw new Error(e);
	// requests.fireErrors(response);
};

let pre = window.location.origin;

if (isCordova()) {
	pre = 'https://api.logrx.com';
	if (localStorage.useSandbox) {
		pre = 'https://dev.logrx.com';
	}
}
pre = pre + '/api/v1/';

const CancelToken = axios.CancelToken;

let cancelRequest = (cancel) => {
	setTimeout(() => {
		cancel();
	}, 10000);
};

let serialize = (obj) => {
	const str = [];
	for (let p in obj)
		if (obj.hasOwnProperty(p)) {
			str.push(encodeURIComponent(p) + '=' + encodeURIComponent(obj[p]));
		}
	return str.join('&');
};
const additionalHeaders = () => {
	let headers= {}
	headers['x-platform-type'] =  isCordova() ? 'CORDOVA' : 'WEB';
	headers['x-platform'] =  window?.cordova?.platformId
	headers['x-service-name'] = 'app';
	headers['x-app-version'] = VERSION;
	headers['x-region'] = localStorage.region;
	headers['x-auth-token'] = localStorage.token;
	headers['user-group-id'] = localStorage.user_group_id || '';
	headers.last_log_in = localStorage.last_log_in;
	headers['last-log-in'] = localStorage.last_log_in;
	return headers;
};

export default () => {
	return {
		get: (url, params) => {
			let data = {
				...params,
        platformType : isCordova() ? 'CORDOVA': ''
			};
      url += '?' + serialize(data);

			if (!localStorage.last_log_in) localStorage.last_log_in = Date.now();

			return axios
				.get(pre + url, {
					cancelToken: new CancelToken(function executor(c) {
						cancelRequest(c);
						// c()
					}),
					headers: {
            ...additionalHeaders(),
					}
				})
				.then((res) => {
					if (res.data.data && res.data.metadata) {
						res.data.data = utils.server_to_client_obj(res.data.data, null, res.data.metadata);
					}
					return res.data.data;
				})
				.catch((err) => {
					handleNetworkError(err);
				});
		},
		getNoAuth: (url, data) => {
			data = data || {};
      data.platformType = isCordova()  ? 'CORDOVA' : ''
			url += '?' + serialize(data);
      let headers = additionalHeaders();
			delete headers['x-auth-token'];
			return axios
				.get(pre + url, {
					cancelToken: new CancelToken(function executor(c) {
						cancelRequest(c);
						// c()
					}, {
            headers: headers
          })
				})
				.then((res) => {
					if (res.data.data && res.data.metadata) {
						res.data.data = utils.server_to_client_obj(res.data.data, null, res.data.metadata);
					}
					return res.data.data;
				})
				.catch((err) => {
					handleNetworkError(err);
				});
		},

		post: (url, body, config, errorHandler) => {
			let data = { ...body, password_reset_guid: localStorage.password_reset_guid };

			if (!config) {
				config = axiosConfig;
			}
			if (!config.headers) {
				config.headers = {};
			}
			config.headers = {...config.headers, ...additionalHeaders()};

			url += '?platformType=' + (isCordova() ? 'CORDOVA': '')
			return axios
				.post(pre + url, data, config)
				.then((res) => {
					if (res.data.data && res.data.metadata) {
						res.data.data = utils.server_to_client_obj(res.data.data, null, res.data.metadata);
					}
					return res.data.data;
				})
				.catch((err) => {
					if (errorHandler) {
						return errorHandler(err);
					} else {
						handleNetworkError(err);
					}
				});
		},
		postReturnError: function(url, body, config){
			return this.post(url, body, config, (error) => handleError({error}))
		},
		delete: (url, data, config) => {
			if (!config) {
				config = axiosConfig;
			}
			if (!config.headers) {
				config.headers = {};
			}
			config.headers = {...config.headers, ...additionalHeaders()};

			url += '?platformType=' + (isCordova() ? 'CORDOVA': '')
			return axios
				.delete(pre + url, data, config)
				.then((res) => {
					return res.data.data;
				})
				.catch((err) => {
					handleNetworkError(err);
				});
		}
	};
};
