import React, { Component } from 'react';
import { List, ListItem, Navbar, Page, Panel, View } from 'framework7-react';
import InvitePopup from './popups/invitePopup/InvitePopup';
import TutorialPopup from './popups/tutorialPopup/TutorialPopup';
import models from '../model/models';
import ChooseGroupPopup from './popups/chooseGroupPopup/ChooseGroupPopup';
import loginController from '../controllers/loginController';
import offlineSyncController from '../controllers/offlineSyncController';
import offlineStorageModel from '../model/offlineStorageModel';
import utils from "../utils/utils";
import './LeftPanel.css';

class LeftPanel extends Component {
	constructor(props, context) {
		super(props, context);
		this.state = {};
	}

	componentDidMount = () => {
		this.subId = models.user_group.onChange(this.updateViewData);
		this.offlineSubId = offlineStorageModel.onChange(this.updateViewData);
		this.updateViewData();
	};

	componentWillUnmount = () => {
		models.user_group.unsubscribe(this.subId);
		offlineStorageModel.unsubscribe(this.offlineSubId);
	};

	updateViewData = () => {
		let group = models.user_group.obj();
		const numPendingOfflineActions = offlineStorageModel.numPending();
		this.setState({ group, numPendingOfflineActions });
	};

	showDrugs = () => {
		window.f7.panel.close();
	};

	showGroups = () => {
		window.popupController.show(ChooseGroupPopup);
	};

	showLogin = () => {
		localStorage.clear();
		loginController.show();
		window.f7.panel.close();
    utils.hardReloadApp()
	};

	showTutorial = () => {
		window.popupController.show(TutorialPopup, {});
		window.f7.panel.close();
	};

	render = () => {
		let group = this.state.group || {};
		let offlineActions = null;
		if (this.state.numPendingOfflineActions) {
			offlineActions = (
				<ListItem
					title={'Offline Actions (' + this.state.numPendingOfflineActions + ')'}
					onClick={offlineSyncController.start}
				></ListItem>
			);
		}
		return (
			<Panel left reveal layout="light" className={'g-left-panel'}>
				<View id="left-panel-view" navbarThrough dynamicNavbar="true">
					<Navbar title="Menu"></Navbar>
					<Page>
						<List>
							{offlineActions}
							<ListItem title="Drugs" linkView="#main-view" panelClose onClick={this.showDrugs}></ListItem>
							<ListItem title="Tutorial" panelClose onClick={this.showTutorial}></ListItem>
							<ListItem title="Log-out" panelClose onClick={this.showLogin}></ListItem>
							<ListItem className="group-name" onClick={this.showGroups} title={group.name}></ListItem>
						</List>
					</Page>
				</View>
			</Panel>
		);
	};
}

//
// LeftPanel.contextTypes = {
//   framework7AppContext: PropTypes.object
// };
//

export default LeftPanel;
