import SparkMD5 from 'spark-md5';
import general_endpoint from './general_endpoint.js';
import req from './requests';
import { VERSION } from '../components/pages/loginHome/LoginHome';
import {getCurrentUser} from "../model/models";
import {isCordova} from "../utils/isCordova";
import vpnController from "../controllers/vpnController";

const requests = req();

class MainAPI {
	constructor() {
		const { get, getNoAuth, post } = { ...requests };

		this.biometric_code = new general_endpoint('biometric_code', requests);
		this.biometric_code.get_by_guid = (guid) => {
			return get('biometric_code/guid', { guid });
		};
		this.box_check = new general_endpoint('box_check', requests);
		this.box_check.user_stats = () => {
			return get('box_check/user/stats', {});
		};
		this.box_check.export = (id) => {
			return get('box_check/export', { id: id });
		};
		this.box_check.last_check_for_box = (drug_box_id) => {
			return get('box_check/last/drug_box', { drug_box_id });
		};
		this.drug = new general_endpoint('drug', requests);
		this.drug.find_by_barcode = (data) => {
			return get('drug/barcode', data);
		};
		this.drug.for_drug_box = (drug_box_id) => {
			return get('drug/drug_box/for', { drug_box_id });
		};
		this.drug.find_by_ids = (ids) => {
			return get('drug/by/ids', { ids:JSON.stringify(ids) });
		};

		this.error_log = {};
		this.error_log.create = async (obj) => {
			obj.using_vpn = await vpnController.isVpnConnected();
			obj.app_version = VERSION;
			obj.platform = window?.cordova ? window.cordova.platformId : 'web';
			return requests.post('error_log/create', obj, null, console.error);
		};
		this.toasts = {};
		this.toasts.create = async (obj) => {
      try {
        const user = getCurrentUser() || {};
        obj.user_token = localStorage.token;
        obj.user_group_id = localStorage.user_group_id;
        obj.user_id = user.id;
        return await requests.post('toast/create', obj, null, console.error);
      }catch(e){
        console.error('failed to save toast', e);
      }
		};

		this.drug_box = new general_endpoint('drug_box', requests);
		this.drug_box.all_with_unit = (user_group_id) => {
			const data = {};
			if (user_group_id) data.force_user_group_id = user_group_id;
			return get('drug_box/all/with/unit', data);
		};
		this.drug_box.find_by_barcode = (data) => {
			return get('drug_box/barcode', data);
		};
		this.drug_box.last_used = (data) => {
			return get('drug_box/last', data);
		};

		this.drug.for_box = (drug_box_id) => {
			return get('drug/drug_box/for', { drug_box_id: drug_box_id });
		};
		this.drug_check = new general_endpoint('drug_check', requests);

		this.drug_type = new general_endpoint('drug_type', requests);

		this.drug_type.allowed_drugs = () => {
			return get('drug_type/allowed', {});
		};
		this.drug_type.all_with_allowed = () => {
			return get('drug_type/all_with_allowed', {});
		};
		this.drug_type.is_short_date_allowed = (type) => {
			return get('drug_type/is_short_date_allowed', {type});
		};

		this.drug_transfer = new general_endpoint('drug_transfer', requests);
		this.health = {};
		this.health.get = () => {
			return getNoAuth('health');
		};
		this.reseal = new general_endpoint('reseal', requests);
		this.stock_move_record = new general_endpoint('stock_move_record', requests);
		this.stock_move_record.administerAndWasteRemaining = (obj, errorHandler) => {
			return post('stock_move_record/administer/waste/remaining', obj, null, errorHandler);
		};
		this.stock_move_record.administered = (after_timestamp) => {
			return get('stock_move_record/administered', { after_timestamp });
		};
		this.stock_move_record.administeredForDrug = (drug_id, options = {}) => {
			return get('stock_move_record/administered/drug', { drug_id, ...options });
		};
		this.stock_move_record.wastedForDrug = (drug_id) => {
			return get('stock_move_record/wasted/drug', { drug_id });
		};

		this.stock_move_record.wasted = (after_timestamp) => {
			return get('stock_move_record/wasted', { after_timestamp });
		};
		this.stock_move_record.userGroupTransferInfo = (drug_id) => {
			return get('stock_move_record/user_group/transfer/info', { drug_id });
		};
		this.stock_move_record.forDrug = (drug_id) => {
			return get('stock_move_record/drug/for', { drug_id });
		};

		this.stock_move_type = new general_endpoint('stock_move_type', requests);
		this.national_drug_code = new general_endpoint('national_drug_code', requests);

		this.national_drug_code.search_by_barcode = async({ndc_barcode, type}) => {
		   return get('national_drug_code/search_by_barcode',{ndc_barcode, type});
		};

		this.unit = new general_endpoint('unit', requests);

		this.product_feedback_prompt = new general_endpoint('product_feedback_prompt', requests);
		this.product_feedback = new general_endpoint('product_feedback', requests);
		this.product_feedback.shouldPrompt = () => {
			const lastPromptDate = localStorage['lastReviewPromptDate'] || '';
			const lastReviewMaybeLaterDelayMonths = localStorage['lastReviewMaybeLaterDelayMonths'] || '';
			return get('product_feedback/should-prompt', {lastPromptDate, lastReviewMaybeLaterDelayMonths});
		};

		this.discrepancy = new general_endpoint('discrepancy', requests);

		this.user_group = new general_endpoint('user_group', requests);
		this.user_group.create = (data) => {
			data.password = SparkMD5.hash(data.password);
			return post('user_group/create', data);
		};
		this.user_group.current = () => {
			return get('user_group/current', {});
		};
		this.user_group.for_organization = () => {
			return get('user_group/for/organization', {});
		};

		this.user = new general_endpoint('user', requests);

		this.user.create = (data) => {
			data.password = SparkMD5.hash(data.password);
			return post('user/create', data);
		};
		this.user.update = (data) => {
			data.password = SparkMD5.hash(data.password);
			return post('user/update', data);
		};
		this.user.invite = (data) => {
			return post('user/invite', data);
		};
		this.user.forgot_password = (data) => {
			return post('user/forgot_password', data);
		};
		this.user.refreshSession = () => {
			return post('user/refresh/session');
		};
		this.user.auth = (data) => {
			return get('user/auth', data);
		};
		this.user.auth_token = (data) => {
			return get('user/auth_token', data);
		};

		this.user.login = (data) => {
			//eslint-disable-next-line
			data.password = SparkMD5.hash(data.password);
			return post('user/login', data);
		};
		this.user.loginStatus = (data) => {
			return post('user/login/status', data);
		};
		this.user.loginWithCode = (data) => {
			return post('user/login/with_code', data);
		};
		this.user.updatePhoneNumber = (data) => {
			return post('user/phone/update', data);
		};

		this.user.verify_user = (data) => {
			//eslint-disable-next-line
			data.password = SparkMD5.hash(data.password);
			return post('user/verify_user', data);
		};

		this.contact_us = {};
		this.contact_us.create = (user) => {
			return post('contact_us/create', user);
		};

		this.enum = {
			get: (data) => {
				return get('enum/', data);
			},
			all: (data) => {
				return get('enum/all', data);
			}
		};

		this.rootURL = () => {
			let pre = window.location.origin;

			if (isCordova()) {
        pre = 'https://app.logrx.com';
				if (localStorage.useSandbox) {
					pre = 'https://dev.logrx.com';
				}
			}
			return pre;
		};
	}
}

let mainAPI = new MainAPI();

export default mainAPI;

let checkTokenExpiration = () => {
	mainAPI.user.get();
};

if (!window.cordova) {
	setInterval(checkTokenExpiration, 1000 * 60 * 5);
}
