import utils from "./utils";

export const openAppStoreReviewPage = (inApp= false) => {
	// iOS detection
  if (utils.isPlatform('ios')) {
		if(inApp) {
			try {
				window.cordova.exec(console.log, console.log, 'QRScanner', 'promptForReview', []);
			} catch (e) {
				console.log('prompting for review failed', e, JSON.stringify(e));
				return openAppStoreReviewPage(false);
			}
		}else {
			let appStoreUrl = "itms-apps://itunes.apple.com/app/id1095064884?action=write-review";
			try {
				window.cordova.InAppBrowser.open(appStoreUrl, '_system');
			} catch (e) {
				console.error(e)
			}
		}
  }
  // Android detection
  else if (utils.isPlatform('android')) {
    let playStoreUrl = "market://details?id=com.logrx.main";
		try{
			window.cordova.InAppBrowser.open(playStoreUrl, '_system');
		}catch(e){console.error(e)}
	}
}