import * as React from "react"

const EyeBall = ({className, ...props}) => {
	const classes = 'g-eye-ball ' + (className || '')
	return <svg className={classes} fill="none" height="16" role="img" viewBox="0 0 16 16" width="16"
							xmlns="http://www.w3.org/2000/svg" {...props}>
		<path clipRule="evenodd"
					d="M1 8s1.91-4.455 7-4.455S15 8 15 8s-1.91 4.454-7 4.454S1 8 1 8Zm4.773 0A2.23 2.23 0 0 0 8 10.227 2.23 2.23 0 0 0 10.227 8 2.23 2.23 0 0 0 8 5.773 2.23 2.23 0 0 0 5.773 8Z"
					fill="var(--rh__text-color)" fillRule="evenodd"></path>
	</svg>
};
export default EyeBall;