import React from 'react';
import './BoxTransferPage.css';
import { Navbar, Page } from 'framework7-react';
import mainAPI from '../../../api/MainAPI';
import DropDown from '../../dropDown/DropDown';
import FormTemplate from '../../templates/formTemplates/FormTemplate';
import Content from '../../helpers/content/Content';
import toasts from '../../../utils/toasts';
import models from '../../../model/models';
import utils from '../../../utils/utils';
import verifyController from '../../../controllers/verifyController';
import getLocation, { getLastLocation } from '../../../utils/getLocation';
import resealController from '../../../controllers/resealController';
import {checkIfResealRequired} from "../administerDetailsPage/AdministerDetailsPage";
import PrimaryButton from "../../primaryButton/PrimaryButton";
import dashboardController from "../../../controllers/dashboardController";
import {DashBoardPageRoute, formatDrugBoxName} from "../dashboardPage/DashBoardPage";

let validId = (id) => {
	if (id === -1) return false;
	if (!id) return false;
	return true;
};
export const boxTransferPageRoute = '/box/transfer'
export default class BoxTransferPage extends FormTemplate {
	constructor(props) {
		super(props);
		const userGroup = models.user_group.obj();
		this.state = { current_user_group_id: userGroup.id, user_group_id: null };
		getLocation();
	}

	_transfer = async (verify_user) => {
		let value = this.getValue();
		let drugIds = utils.parseJSON(this.props.drugIds);
		let promises = [];
		let drugId = null;
		let location = getLastLocation();
		const allDrugs = await mainAPI.drug.find_by_ids(drugIds)
		let targetBoxIds = allDrugs.map(d=>d.drug_box_id)
		for (let i in drugIds) {
			drugId = drugIds[i];
			let drug = { id: drugId, drug_box_id: value.drug_box_id };
			drug.transfer_lat = location.lat;
			drug.transfer_lon = location.lon;
			if (this.state.to_user_group_id) {
				drug.transfer_to_user_group_id = this.state.to_user_group_id;
			}
			drug.witness_id = verify_user.witness_id;
			drug.witness_biometric_guid = verify_user.guid;
			drug.guest_verify__signature_img = verify_user.guest_verify__signature_img;
			drug.guest_verify__name = verify_user.guest_verify__name;
			drug.guest_verify__license_number = verify_user.guest_verify__license_number;
			drug.guest_verify__title = verify_user.guest_verify__title;
			promises.push(mainAPI.drug.update(drug));
		}
		await models.user_group.refreshData();

		let res = await Promise.all(promises);
		this._handleResponses(res)
		if (window.f7.views.current.id === 'view_main') {
			await window.f7.views.current.router.navigate(DashBoardPageRoute, {reloadAll: true});
		} else {
			window.popupController.hideAll();
		}

		dashboardController.refresh(value.drug_box_id)

		checkIfResealRequired(drugIds, verify_user, targetBoxIds);
	};

	_handleResponses = (responses) => {
		let failCount = 0;
		for (let i in responses) {
			if (!responses[i]) failCount++;
		}
		if (failCount) {
			return toasts.error(`${failCount} drugs did not change boxes`);
		}
		toasts.show('Transferred');
		return responses;
	};

	_verify = async () => {
		let value = this.getValue();
		if (!this.isValid()) return;
		if (!validId(value.drug_box_id)) {
			return toasts.error(`Please select a drug box to continue`);
		}
		verifyController.start(async (witness) => {
			await this._transfer(witness);
		});
	};

	render() {
		let agencySelect = null;
		let title = 'Box Transfer';
		if (this.props.transferAgency) {
			title = 'Agency Transfer';
			agencySelect = [
				<div className="info" key="select-agency">
					Select an agency
				</div>,
				<DropDown
					required
					not={[this.state.current_user_group_id]}
					ref={(ref) => (this.myRefs.to_user_group_id = ref)}
					placeholder="Agency"
					onChange={(id) => {
						this.setState({ to_user_group_id: id });
					}}
					endpoint={mainAPI.user_group.for_organization}
					key="user-group-dropdown"
				/>
			];
		}
		let boxSelect = null;

		if (this.state.to_user_group_id || !this.props.transferAgency) {
			boxSelect = [
				<div className="info" key="select-box">
					Select a box to transfer this to
				</div>,
				<DropDown
					required
					getDisplayValue={formatDrugBoxName}
					ref={(ref) => (this.myRefs.drug_box_id = ref)}
					placeholder="Drug Box"
					endpoint={async () => {
						return mainAPI.drug_box.all_with_unit(this.state.to_user_group_id);
					}}
					key="drug-box"
				/>
			];
		}
		return (
			<Page className="g-box-list-page">
				<Navbar backLink={'Back'} title={title}></Navbar>
				<Content>
					{agencySelect}
					{boxSelect}
				</Content>
				<PrimaryButton onClick={this._verify} center>
					Transfer
				</PrimaryButton>
			</Page>
		);
	}
}

export const resealNextBox = (drugBoxIds, verify_user) => {
	const boxId = drugBoxIds.pop();
	let onSuccess = null;
	if (drugBoxIds.length > 0) {
		onSuccess = () => {
			setTimeout(() => {
				resealNextBox(drugBoxIds, verify_user);
			}, 1000);
		};
	}
	resealBox(boxId, verify_user, onSuccess);
};

const resealBox = (box_id, optionalWitness, onSuccess) => {
	let box = models.drug_box.obj(box_id);
	toasts.show(`Now reseal (${box.name})`);
	resealController.foundBox(box, null, onSuccess, optionalWitness);
};
