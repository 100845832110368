import throttle from 'lodash.throttle';

import {PopupAnimationMs} from "../utils/animationConstants";
export const ScannerShowThrottleMs = 300;
const ScanViewController = function () {
	this.showScanner = throttle((title, subTitle, onSuccess, onFinish, clearState = false, onHide, options) => {
		window.SCANNER.showScanner(title, subTitle, onSuccess, onFinish, clearState, onHide, options);
	},ScannerShowThrottleMs,  { leading: true, trailing: false });
	this.hideScanner = (animationMs, animateFrom) => {
		window.SCANNER.hideScanner(animationMs, animateFrom);
	};
	this.unPauseScanner = () => {
		window.SCANNER.unPauseScanner();
	};
	this.pauseScanner = () => {
		window.SCANNER.pauseScanner();
	};
	this.setDrugs = (drugs, title) => {
		window.SCANNER.setDrugs(drugs, title);
	};
	this.hideMainViewDuringAnimation = () => {
		if(this.hideTimeout){
			clearTimeout(this.hideTimeout)
			this.hideTimeout = null;
		}
		try {
			document.querySelector('#main-views').classList.add('display-none');
			this.hideTimeout = setTimeout(() => {
				try {
					document.querySelector('#main-views').classList.remove('display-none');
				}catch(e){
					console.error(e);
				}
			}, PopupAnimationMs * 2);
		}catch(e){
			console.error(e);
		}
	};
};
const scanViewController = new ScanViewController();

export default scanViewController;
