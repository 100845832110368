import offlineStorageModel, { OfflineTypes } from '../model/offlineStorageModel';
import mainAPI from '../api/MainAPI';
import barcodeLookup from '../utils/barcodeLookup';
import toasts from '../utils/toasts';
import loginController from './loginController';
import offlineController from './offlineController';
import models from '../model/models';
import gramUtils from '../utils/gramUtils';
import Volume from '../components/volume/Volume';
import utils from '../utils/utils';
import dashboardController from "./dashboardController";

const OfflineSyncController = function () {
	this.start = async () => {
		if (this.isRunning) return console.log('offline sync already running');
		if (loginController.isLoginVisible) return console.log('login screen visible');
		this.isRunning = true;
		await models.drug_type.refreshData();
		const numProcessed1 = await this.processAll(OfflineTypes.DailyCheck, this.processOneDailyCheck).catch(console.error);
		const numProcessed2 = await this.processAll(OfflineTypes.Administer, this.processOneAdminister).catch(console.error);
		const numProcessed3 = await this.processAll(OfflineTypes.Waste, this.processOneWaste).catch(console.error);
		this.isRunning = false;
		if(numProcessed1 || numProcessed2 || numProcessed3) {
			dashboardController.refresh();
		}
	};

	this.processAll = async (type, processFunction, numProcessed = 0) => {
		const items = offlineStorageModel.get(type);
		if (!items || items.length === 0) return numProcessed
		const success = await processFunction(items);
		if (!success) {
			console.log('failed process offline storage');
			return 0;
		}
		await this.processAll(type, processFunction, items.length);
	};
	this.processOneV2 = async (offlineActions, type, createFunction) => {
		if (!offlineActions || offlineActions.length === 0) return false;
		const success = await createFunction(offlineActions.pop());
		if (success) offlineStorageModel.save(type, offlineActions);
		return success;
	};
	this.processOneDailyCheck = async (offlineInventoryChecks) => {
		return this.processOneV2(offlineInventoryChecks, OfflineTypes.DailyCheck, this.createBoxCheck);
	};
	this.processOneAdminister = async (offlineAdministrations) => {
		return this.processOneV2(offlineAdministrations, OfflineTypes.Administer, this.createAdminister);
	};
	this.processOneWaste = async (offlineWaste) => {
		return this.processOneV2(offlineWaste, OfflineTypes.Waste, this.createWaste);
	};

	this.deleteByBarcodeId = (barcode_id, type) => {
		const offlineActions = offlineStorageModel.get(type);
		const newActions = offlineActions.filter((check) => {
			return check?.barcode_id != barcode_id;
		});
		offlineStorageModel.save(type, newActions);
	};

	this.getDrugBoxId = (drug, box) => {
		if (drug?.drug_box_id) return drug?.drug_box_id;
		if (box?.id) return box.id;
		return null;
	};

	this.createWaste = async (offlineWaste) => {
		const { drug } = await this.getDrugAndBoxIdIfExists(
			offlineWaste,
			OfflineTypes.Waste,
			'drug',
			'Would you like us to DELETE this offline waste record?'
		);
		if (!drug?.id) return false;
		const drugType = models.drug_type.obj(drug.type);
		let quantity = drug.stock_move_record__quantity;
		if (offlineWaste.quantityFromInput && drugType.is_reusable) {
			quantity = gramUtils.toMicrograms(offlineWaste.quantityFromInput, drug.type);
			if (quantity > drug.stock_move_record__quantity) {
				let wasteDisplayQuantity = Volume.displayWithPreferredUnit(quantity, drug.type);
				let actualDisplayQuantity = Volume.displayWithPreferredUnit(drug.stock_move_record__quantity, drug.type);

				window.f7.dialog.confirm(
					'Offline waste failed for drug "' +
						utils.upperCase(offlineWaste.barcode_id) +
						'". You cannot waste more than is available. Waste ' +
						wasteDisplayQuantity +
						'. Available ' +
						actualDisplayQuantity +
						'. Would you like us to DELETE this offline waste?',
					'Waste error',
					() => {
						this.deleteByBarcodeId(offlineWaste.barcode_id, OfflineTypes.Waste);
					}
				);
				return false;
			}
		}

		let success = false;
		const stockMoveRecord = {
			drug_id: drug.id,
			doses: 1,
			quantity: -Math.abs(quantity),
			guest_verify__name: offlineWaste.guest_verify__name,
			guest_verify__signature_img: offlineWaste.guest_verify__signature_img,
			guest_verify__license_number: offlineWaste.guest_verify__license_number,
			guest_verify__title: offlineWaste.guest_verify__title,
			incident_number: offlineWaste.incident_number,
			offline: 1,
			input_manually: offlineWaste.input_barcode_manually ? 1 : 0,
			timestamp: offlineWaste.time,
			lat: offlineWaste.lat,
			lon: offlineWaste.lon
		};
		success = await mainAPI.stock_move_record.create({
			stock_move_type_id__name: 'waste',
			...stockMoveRecord
		});
		const displayQuantity = Volume.displayWithPreferredUnit(quantity, drug.type);
		if (!success) {
			window.f7.dialog.confirm(
				'Waste failed for "' +
					utils.upperCase(offlineWaste.barcode_id) +
					'". Would you like us to DELETE this offline waste?',
				'Administer error',
				() => {
					this.deleteByBarcodeId(offlineWaste.barcode_id, OfflineTypes.Waste);
				}
			);
		} else {
			toasts.show(
				'recorded offline waste of (' + displayQuantity + ') for "' + utils.upperCase(offlineWaste.barcode_id) + '"'
			);
		}
		return !!success;
	};

	this.getDrugAndBoxIdIfExists = async (offlineRecord, offlineType, requiredType, errorMessage) => {
		const { drug, box } = await barcodeLookup(offlineRecord.barcode_id);
		const boxId = this.getDrugBoxId(drug, box);
		if ((!drug?.id && requiredType === 'drug') || (!boxId && requiredType === 'box')) {
			const online = await offlineController.isOnline();
			if (online) {
				window.f7.dialog.confirm(
					'Offline sync failed. No ' +
						requiredType +
						' was found with this code "' +
						utils.upperCase(offlineRecord.barcode_id) +
						'". ' +
						errorMessage,
					'Offline error',
					() => {
						this.deleteByBarcodeId(offlineRecord.barcode_id, offlineType);
					}
				);
			}
			return { drug: null, boxId: null };
		}
		return { drug, boxId };
	};

	this.createAdminister = async (offlineAdminister) => {
		const { drug } = await this.getDrugAndBoxIdIfExists(
			offlineAdminister,
			OfflineTypes.Administer,
			'drug',
			'Would you like us to DELETE this offline administer?'
		);
		if (!drug?.id) return false;

		offlineAdminister.quantity = gramUtils.toMicrograms(offlineAdminister.quantity, drug.type);
		let success = false;
		const stockMoveRecord = {
			drug_id: drug.id,
			doses: 1,
			quantity: -Math.abs(offlineAdminister.quantity),
			guest_verify__name: offlineAdminister.guest_verify__name,
			guest_verify__signature_img: offlineAdminister.guest_verify__signature_img,
			guest_verify__license_number: offlineAdminister.guest_verify__license_number,
			guest_verify__title: offlineAdminister.guest_verify__title,
			incident_number: offlineAdminister.incident_number,
			offline: 1,
			input_manually: offlineAdminister.input_barcode_manually ? 1 : 0,
			timestamp: offlineAdminister.time,
			lat: offlineAdminister.lat,
			lon: offlineAdminister.lon
		};
		if (offlineAdminister.wasteRemaining) {
			success = await mainAPI.stock_move_record.administerAndWasteRemaining(stockMoveRecord);
		} else {
			success = await mainAPI.stock_move_record.create({
				stock_move_type_id__name: 'administer',
				...stockMoveRecord
			});
		}
		if (!success) {
			window.f7.dialog.confirm(
				'Administer failed for "' +
					utils.upperCase(offlineAdminister.barcode_id) +
					'". Would you like us to DELETE this offline administer?',
				'Administer error',
				() => {
					this.deleteByBarcodeId(offlineAdminister.barcode_id, OfflineTypes.Administer);
				}
			);
		} else {
			toasts.show('recorded offline administer for "' + utils.upperCase(offlineAdminister.barcode_id) + '"');
		}

		return !!success;
	};
	this.createBoxCheck = async (offlineBoxCheck) => {
		const { boxId } = await this.getDrugAndBoxIdIfExists(
			offlineBoxCheck,
			OfflineTypes.DailyCheck,
			'box',
			'would you like to DELETE this offline inventory check?'
		);
		if (!boxId) return false;
		const drugs = await mainAPI.drug.for_drug_box(boxId);
		let bc = {
			drug_box_id: boxId,
			// witness_id: value.witness_id,
			// witness_biometric_guid: value.witness_biometric_guid,
			guest_verify__name: offlineBoxCheck.guest_verify__name,
			guest_verify__signature_img: offlineBoxCheck.guest_verify__signature_img,
			guest_verify__license_number: offlineBoxCheck.guest_verify__license_number,
			guest_verify__title: offlineBoxCheck.guest_verify__title,
			input_barcode_manually: offlineBoxCheck.input_barcode_manually,
			user_was_offline: 1,
			timestamp: offlineBoxCheck.time,
			lat: offlineBoxCheck.lat,
			lon: offlineBoxCheck.lon
		};

		let box_check = await mainAPI.box_check.create(bc);
		if (!box_check) {
			console.log('box_check failed');
			return false;
		}
		let drug_checks = [];
		for (let i in drugs) {
			let drug = drugs[i];
			if (drug.stock_move_record__quantity <= 0) continue;
			let drugCheck = { drug_id: drug.id, check_id: box_check.id };
			drug_checks.push(drugCheck);
		}
		const {error} = await mainAPI.drug_check.update_many(drug_checks);
		if (error) {
			toasts.error(`Error creating drug checks for '${utils.upperCase(offlineBoxCheck.barcode_id)}'`);
			return false;
		}
		else {
			toasts.show(`Recorded offline inventory check for '${utils.upperCase(offlineBoxCheck.barcode_id)}'`);
			return true;
		}
	};
};

const offlineSyncController = new OfflineSyncController();

export default offlineSyncController;
