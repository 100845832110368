import utils from '../utils/utils';
import loginController from './loginController';
import toasts from '../utils/toasts';

const BiometricReAuthenticateController = function () {
	let isShowing = false;
	let timeoutId;
	this.show = () => {
		if (isShowing) return;
		isShowing = true;
		if (timeoutId) clearTimeout(timeoutId);
		timeoutId = setTimeout(() => {
			isShowing = false;
		}, 10000);
		toasts.error('Session has expired');
		utils.biometricCheck(
			async () => {
        console.log('Biometric check success');
				isShowing = false;
				if (timeoutId) clearTimeout(timeoutId);
				await utils.refreshSession();
        utils.hardReloadApp()
			},
			() => {
        console.log('Biometric check failure');
				isShowing = false;
				if (timeoutId) clearTimeout(timeoutId);
				loginController.show();
			},
      true
		);
	};
	this.isEligible = async () => {
		return localStorage.isPersonalDevice && localStorage.token && (await utils.areBiometricsAvailableAsync());
	};
};

const biometricReAuthenticateController = new BiometricReAuthenticateController();

export default biometricReAuthenticateController;
