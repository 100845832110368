import React, { Component } from 'react';
import './CheckBox.css';
import Check from "../svg/Check";

export default class CheckBox extends Component {
	constructor(props) {
		super(props);
		this.state = {
			value: this.defaultValue()
		};
	}

	componentWillReceiveProps(nextProps) {
		if (this.isBooleanValue(nextProps.forceValue)) {
			this.setState({ value: nextProps.forceValue });
		} else if (this.isBooleanValue(nextProps.value)) {
			this.setState({ value: nextProps.value });
		}
	}

	defaultValue = () => {
		if (this.isBooleanValue(this.props.forceValue)) {
			return this.props.forceValue;
		} else if (this.isBooleanValue(this.props.value)) {
			return this.props.value;
		}
		return false;
	};
	isBooleanValue = (value) => {
		if (value === !!value) {
			return true;
		}
		return false;
	};

	getValue = () => {
		return this.state.value;
	};
	isValid = () => {
		return true;
	};

	_isEmpty = (value) => {
		if (value === null) return true;
		if (value === undefined) return true;
		if (value === '') return true;
		return false;
	};

	_getClasses = () => {
		let classes = 'g-check-box';
		if (this.props.className) {
			classes += ' ' + this.props.className;
		}
		if (this.state.value) {
			classes += ' checked ';
		}
		return classes;
	};

	_onChange = (e) => {
		let value = !this.state.value;
		if (!this.props.disabled) {
			this.setState({ value: value }, () => {
				if (this.props.onChange && typeof this.props.onChange === 'function') {
					this.props.onChange(value, this.props.id);
				}
			});
		}
	};
	getCheckbox = () => {
		let checkbox = <input ref={(ref) => (this.checkbox = ref)} type="checkbox" defaultChecked={!!this.state.value} />;
		if (this.props.disabled) {
			checkbox = (
				<input disabled ref={(ref) => (this.checkbox = ref)} type="checkbox" defaultChecked={!!this.state.value} />
			);
		}
		return checkbox;
	};

	render() {
		let requiredMessage = '';
		// if (this.state.showRequired) {
		//   requiredMessage = (
		//     <InputValidationError showRequiredText={this.state.showRequired} className={classes} />
		//   );
		// }
		return (
			<div className={this._getClasses()} onClick={this._onChange}>
					<Check className="check-box-checked" />
				{requiredMessage}
			</div>
		);
	}
}
