class VpnController {
	constructor() {
		this.vpnConnected = null;
	}

	isVpnConnected = () => {
		return new Promise((resolve, reject) => {
			this.checkStatus((connected) => {
				resolve(connected);
			});
		});
	};
	checkStatus = (callback) => {
		if (!window?.cordova) {
			return callback(false);
		}
		window.cordova.exec((response) => {
			if (response === 'true') {
				callback(true);
				this.vpnConnected = true;
			} else {
				callback(false);
				this.vpnConnected = false;
			}
		}, (response) => {
			console.error('isVPNConnected error', JSON.stringify(response));
		}, 'QRScanner', 'isVPNConnected', []);
	};
}


const vpnController = new VpnController();
export default vpnController;