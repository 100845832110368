import React from 'react';
import './EnterPhoneNumberPage.css';
import {Page} from 'framework7-react';
import Input from "../../input/Input";
import CheckBox from "../../checkBox/CheckBox";
import Label from "../../label/Label";
import PrimaryButton from "../../primaryButton/PrimaryButton";
import FormTemplate from "../../templates/formTemplates/FormTemplate";
import NoBorderButton from "../../noBorderButton/NoBorderButton";
import toasts from "../../../utils/toasts";
import mainAPI from "../../../api/MainAPI";
import {Logo} from "../../svg/Logo";

export const enterPhoneNumberPageRoute = '/enter/phone';
export default class EnterPhoneNumberPage extends FormTemplate {
	constructor(props) {
		super(props);
		this.state = {submitting: false};
	}

	submit = async () => {
		const isValid = this.isValid();
		if (!isValid) return;
		const value = this.getValue();
		value.phone_number = value.phone_number.replace(/ /g, '');
		value.phone_number = extractNumbers(value.phone_number);
		console.log(value);
		if (!value.opt_in) {
			return toasts.error('You need to opt in to receive SMS messages');
		}
		if (value.phone_number.length > 11) {
			return toasts.error('Your phone number has too many numbers (1 234 567 8901)')
		}
		if (value.phone_number.length !== 11) {
			if (value.phone_number[0] === '1') {
				return toasts.error('Please enter a valid phone number (1 234 567 8901)')
			} else {
				return toasts.error('Please enter a valid phone number starting with 1')
			}
		}
		if (value.phone_number.length === 11) {
			if (value.phone_number[0] !== '1') {
				return toasts.error('Your phone number needs to start with 1')
			}
		}
		value.phone_number = '+' + value.phone_number;
		this.setState({submitting: true});
		const res = await mainAPI.user.updatePhoneNumber({
			dual_factor_guid: this.props.dual_factor_guid,
			dual_factor_code: this.props.dual_factor_code,
			new_phone_number: value.phone_number
		});
		if (res?.success) {
			toasts.show('phone number updated');
			return this.props.onSuccess();
		} else {
			toasts.error('Something went wrong. Please try again later.');
		}
		this.setState({submitting: false});
	};

	render() {
		return (
			<Page className="g-enter-phone-number-page">
				<Label large dark>Enter your phone number to receive OTP codes with SMS in the future.</Label>
				<Input onEnter={this.submit} ref={ref => this.myRefs.phone_number = ref} required border
							 placeholder={'phone number 18885554444'}/>
				<div className={'opt-in'}>
					<CheckBox required ref={ref => this.myRefs.opt_in = ref}/>
					<Label dark>I agree to receive SMS messages for account verification.</Label>
				</div>
				<PrimaryButton disabled={this.state.submitting} onClick={this.submit}>Submit</PrimaryButton>
				<NoBorderButton disabled={this.state.submitting} onClick={this.props.onCancel}>Set Up Later</NoBorderButton>
				<Label dark x-small>Message frequency may vary. Standard Message and Data Rates may apply. Reply STOP to opt out. Reply Help for help. Your mobile information will not be sold or shared with third parties for promotional or marketing purposes.</Label>
				<div className={'logo-container'}>
					<Logo height={40} width={40}/>
				</div>
			</Page>
		)
	}
}

function extractNumbers(input) {
	return input.replace(/\D/g, ''); // Replace all non-digit characters with an empty string
}