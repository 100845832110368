import React, { Component } from 'react';
import { Popup } from 'framework7-react';
import './BatchWasteOptionPopup.css';
import Card from '../../card/Card';
import Label from '../../label/Label';
import PrimaryButton from '../../primaryButton/PrimaryButton';
import SecondaryButton from '../../secondaryButton/SecondaryButton';

export default class BatchWasteOptionPopup extends Component {
	constructor(props) {
		super(props);
		this.state = {
			opened: true
		};
	}

	onYesAddMore = () => {
		this.close();
		this.props.onYesAddMore();
	};

	onNoWasteOne = () => {
		this.close();
		this.props.onNoWasteOne();
	};

	close = () => {
		window.popupController.hide(BatchWasteOptionPopup);
	};

	render() {
		return (
			<Popup
				opened={this.props.opened}
				tabletFullscreen={true}
				className="g-batch-waste-popup never-transparent"
			>
        <div onClick={this.close} className={'transparent-backdrop'}/>
        <Card>
					<Label dark large center className={'dark-label'}>
						Batch Waste
					</Label>
					<Label dark center className={'dark-label'}>
						Would you like to add more?
					</Label>
					<PrimaryButton onClick={this.onYesAddMore}>Yes, add more</PrimaryButton>
					<SecondaryButton onClick={this.onNoWasteOne}>No, just this one</SecondaryButton>
				</Card>
			</Popup>
		);
	}
}
