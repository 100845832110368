import React, {Component} from 'react';
import {Popup as F7Popup } from 'framework7-react';

export default class Popup extends Component{
	constructor(props) {
		super(props);
		this.state = {
			mounted:false
		};
	}
	componentDidMount = () => {
	   this.setState({mounted:true})
	};
  render(){
		const shouldOpen = this.state.mounted && this.props.opened
    return(
    <F7Popup {...this.props} opened={shouldOpen}>
			{this.props.children}
    </F7Popup>
    )
  }
}