import cloneDeep from 'lodash.clonedeep';

class BoxScannedPopupDiscrepancyModel {
  drugs = null;
  setDrugs = (drugs) => { // null or array of drugs
    this.drugs = drugs;
  };

  getDrugs = () => {
    return cloneDeep(this.drugs);
  };
}


const boxScannedPopupDiscrepancyModel = new BoxScannedPopupDiscrepancyModel();
export default boxScannedPopupDiscrepancyModel;
