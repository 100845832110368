import React from 'react';
import './DrugDetailsPage.css';
import { Link, Navbar, NavLeft, NavRight, NavTitle, Page } from 'framework7-react';
import Input from '../../input/Input';
import FormTemplate from '../../templates/formTemplates/FormTemplate';
import FontIcon from '../../fontIcon/FontIcon';
import Button from '../../button/Button';
import DropDown from '../../dropDown/DropDown';
import mainAPI from '../../../api/MainAPI';
import gramUtils, { getDisplayUnit } from '../../../utils/gramUtils';
import models from '../../../model/models';
import DatePicker from '../../datePicker/DatePicker';
import Volume from '../../volume/Volume';
import utils from '../../../utils/utils';
import ndcBarcodeScanController from "../../../controllers/ndcBarcodeScanController";
import NewDrugPopup from "../../popups/newDrugPopup/NewDrugPopup";
import SecondaryButton from "../../secondaryButton/SecondaryButton";
import toasts from "../../../utils/toasts";

export default class DrugDetailsPage extends FormTemplate {
	constructor(props) {
		super(props);
		const state = this.props.state || {};
		this.state = {
			drug_or_box: 'drug',
			quantityPlaceholder: 'Amount',
			...state,
		};
	}
	componentDidMount = async () => {
		await this._onDrugTypeChange(this.state.value?.type)
	}

	startBarcodeImport = () => {
		window.popupController.hide(NewDrugPopup);
		ndcBarcodeScanController.start(this.state, this.props.submitDrug);
	};

	submit = () => {
		this.isValid();
		if (!this.isValid()) return;
		if (this.state.drug_or_box === 'drug') {
			let drug = this.getValue();
			if(!drug.short_date){
				delete drug.short_date;
			}
			const quantity = gramUtils.toMicrograms(drug.quantity, drug.type) || 1;
			this.props.submitDrug({ ...drug, quantity }, this.props.drugBarcodes);
		} else {
			let drug_box = this.getValue();
			this.props.submitBox(drug_box, this.props.drugBarcodes);
		}
		this.setState({ submitted: true, value: this.getValue() });
	};

	onInputChange = () => {
		let value = this.getValue();
		this.setState({value});
	}
	_checkForFutureDate = (value) => {
		if (!value) return;
		if (new Date(value).getTime() < Date.now()) {
			toasts.error('Choose a date in the future');
		}
	};

	_getOptions = () => {
		let value = this.state.value || {};
		if (this.state.drug_or_box === 'drug') {
			const toReturn = [];
      toReturn.push(
        <DatePicker
					key={'expiration_date'}
          required
          value={value.expiration_date || null}
          ref={(ref) => (this.myRefs.expiration_date = ref)}
          placeholder="Expiration date"
          onChange={(value)=>{this.onInputChange(value);this._checkForFutureDate(value)}}
        />
      );
			if (this.state.allow_short_date) {
				toReturn.push(
					<DatePicker
						key={'short_date'}
						value={value.short_date || null}
						ref={(ref) => (this.myRefs.short_date = ref)}
						placeholder="Short date"
						onChange={this.onInputChange}
					/>
				);
			}
			toReturn.push(
				<DropDown
					key={'type'}
					endpoint={mainAPI.drug_type.allowed_drugs}
					required
					value={value.type}
					ref={(ref) => (this.myRefs.type = ref)}
					onChange={this._onDrugTypeChange}
					placeholder="Type"
				/>
			);
			toReturn.push(
				<DropDown
					key={'drug_box_id'}
					className="drug-box-drop-down"
					required
					value={value.drug_box_id}
					ref={(ref) => (this.myRefs.drug_box_id = ref)}
					placeholder="Drug Box"
					endpoint={mainAPI.drug_box.all}
					onChange={this.onInputChange}
				/>
			);
			if (!this.state?.onlyOneQuantity) {
				toReturn.push(
					<Input
						key={'quantity'}
						required
						value={value.quantity}
						ref={(ref) => (this.myRefs.quantity = ref)}
						placeholder={this.state.quantityPlaceholder}
						type="number"
						onChange={this.onInputChange}
					/>
				);
			}
			toReturn.push(
				<Input
					key={'lot-number'}
					required
					value={value.lot_number}
					ref={(ref) => (this.myRefs.lot_number = ref)}
					placeholder="Lot number"
					onChange={this.onInputChange}
				/>
			);
			const userGroup = models.user_group.obj();
			if(userGroup.allow_ndc_barcode_import) {
				toReturn.push(
					<SecondaryButton key={'secondary-import'} onClick={this.startBarcodeImport}>Drug Barcode Import</SecondaryButton>
				)
			}
			return toReturn;
		}
	};

	_onDrugTypeChange = async (value) => {
		if(!value)return;
		let quantityPlaceholder = `Amount (${getDisplayUnit(value)})`;
		if (getDisplayUnit(value) === 'qty') {
			quantityPlaceholder = 'Quantity';
		}
		const response = await this.checkForShortCodeType(value);
		const val = this.getValue();
		const state = {
			onlyOneQuantity: models.drug_type.onlyOneQuantity(value),
			quantityPlaceholder
		}
		if(response?.allowed) {
			state.allow_short_date = true;
			if (response.num_days && response.short_date) {
				toasts.show(`Adding short date of +${response.num_days} days`);
				val.short_date = response.short_date;
			} else {
				toasts.show(`Adding short date`);
			}
		}else{
			state.allow_short_date = false;
			delete val.short_date;
		}
		this.setState({
			...state,
			value:val,
		});

	};

	checkForShortCodeType = async (drug_type) => {
		return mainAPI.drug_type.is_short_date_allowed(drug_type);
	};

	render() {
		return (
			<Page className="g-drug-details-page">
				<Navbar>
					<NavLeft>
						<div></div>
					</NavLeft>
					<NavTitle>New Drug {utils.upperCase(this.props?.drug?.barcode_id || '')}</NavTitle>
					<NavRight>
						<Link popupClose>
							<FontIcon name="icon-close"></FontIcon>
						</Link>
					</NavRight>
				</Navbar>
				<div className="content">
					{this._getOptions()}
					<Button bg="blue" color="white" onClick={this.submit}>
						Create
					</Button>
				</div>
			</Page>
		);
	}
}
