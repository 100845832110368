import { Component } from 'react';
import cloneDeep from 'lodash.clonedeep';

export default class FormTemplate extends Component {
	constructor(props) {
		super(props);
		this.myRefs = {};
	}

	getValue = () => {
		let value = {};
		if (this && this.state && this.state.value) {
			value = cloneDeep(this.state.value || {});
		}
		for (let i in this.myRefs) {
			if (this.myRefs && this.myRefs[i]) {
				value[i] = this.myRefs[i].getValue();
			}
		}
		return value;
	};

	isValid = () => {
		let valid = true;
		for (let i in this.myRefs) {
			if (this.myRefs && this.myRefs[i]) {
				let val = this.myRefs[i].isValid();
				if (!val) {
					valid = false;
				}
			}
		}
		return valid;
	};
}
