let hapticFeedbackAvailable = true;

const hapticError = () => {
	if(window.TapticEngine && hapticFeedbackAvailable) {
		try {
			window.TapticEngine.notification({
				type: "error" // success | warning | error
			}, console.log, (e) => {
				console.error('hapticError callback error', e);
				hapticFeedbackAvailable = false;
				if(navigator.vibrate){
					navigator.vibrate([5, 150, 5, 150, 5])
				}
			});
		} catch (e) {
			hapticFeedbackAvailable = false;
			console.error('hapticError error', e);
			if(navigator.vibrate){
				navigator.vibrate([5, 150, 5, 150, 5])
			}
		}
	} else if(navigator.vibrate){
		navigator.vibrate([5, 150, 5, 150, 5])
	}
};
export default hapticError;