import * as React from "react"

export const CircleX = (props) => (
	<svg
		xmlns="http://www.w3.org/2000/svg"
		viewBox="0 0 34 34"
		width="34"
		height="34"
		fill="none"
		strokeLinecap="round"
		strokeLinejoin="round"
		{...props}
	>
		<circle cx="17" cy="17" r="16" fill="#4e92df" stroke="white" strokeWidth="1"/>
		<path d="M10 24 L24 10" stroke="white" strokeWidth="2" fill="none"/>
		<path d="M10 10 L24 24" stroke="white" strokeWidth="2" fill="none"/>
	</svg>
);