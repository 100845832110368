import React, { Component } from 'react';
import './DropDown.css';
import mainAPI from 'api/MainAPI.js';
import FontIcon from '../fontIcon/FontIcon';
import Validation from '../validation/Validation';
import Input from '../input/Input';
import models from '../../model/models';

export default class DropDown extends Component {
	constructor(props) {
		super(props);
		let value = this._defaultValue();
		let search = '';
		if (this.props.options) search = this.props.options[value];
		this.state = {
			options: this.props.options,
			value,
			search,
			valid: true,
			open: false
		};
	}

	firstOption = () => {
		for (let i in this.state.options){
		  return {key:i, option: this.state.options[i]}
		}
		return {}
	};

	componentDidMount = async () => {
		document.addEventListener('mousedown', this._checkForOutsideClick, false);
		document.addEventListener('touchstart', this._checkForOutsideClick, false);
		if (!this.props.src && !this.props.endpoint) return;
		await this._setOptions();
		if (this.props.setFirstValueIfNotSet && this._isEmpty(this.props.value)){
			const firstOption = this.firstOption();
			let value = firstOption.key;
			if (value) {
				this.onChange(value);
			}
		}
		if (this.props.subscribeTo) {
			this.subId = models[this.props.subscribeTo].onChange(() => {
				this._setOptions(this.state.search);
			});
		}
	};

	componentWillUnmount() {
		document.removeEventListener('mousedown', this._checkForOutsideClick, false);
		document.removeEventListener('touchstart', this._checkForOutsideClick, false);

		if (this.props.subscribeTo) {
			models[this.props.subscribeTo].unsubscribe(this.subId);
		}
	}

	componentWillReceiveProps = async (nextProps) => {
		if (nextProps.value !== this.state.value && nextProps.value !== undefined) {
			let newState = {};
			let search = this.state.search;
			if (this.state.options) search = this.state.options[nextProps.value];
			if (!search) {
				let data = await this._getOptAndSearch(nextProps.value);
				if (data && data.options) newState.options = data.options;
				if (data && data.search) newState.search = data.search;
			}
			newState.value = nextProps.value;
			newState.search = search;
			this.setState(newState);
		}
	};

	_getOptAndSearch = async (value) => {
		let options = await this._getOptionsFromUrl();
		if (options) {
			let search = options[value];
			return { options, search };
		}
		return null;
	};

	_setOptions = async (search) => {
		let data = await this._getOptAndSearch(this.state.value);
		if (!data) return;
		let sch = data.search;
		if (typeof search === 'string') {
			sch = search;
		}
		if(!this.state.open && sch === ''){
			return this.setState({options:data.options})
		}
		this.setState({ options: data.options, search: sch });
	};

	_checkForOutsideClick = (e) => {
		if (!this.element) return;
		if (!this.element.contains(e.target)) {
			if (this.state.open) {
				this._toggleOpen();
			}
		}
	};

	isValid = () => {
		let hasValue = false;
		if (this.state) {
			hasValue = !this._isEmpty(this.state.value);
		}
		let required = this.props.required;
		let valid = false;
		if (required && hasValue) {
			valid = true;
		} else if (!required) {
			valid = true;
		} else {
			valid = false;
		}
		if (valid != this.state.valid) {
			this.setState({ valid });
		}
		return valid;
	};

	_defaultValue = () => {
		if (this._isEmpty(this.props.value)) {
			return -1;
		}
		return this.props.value;
	};

	_isEmpty = (value) => {
		if (value === null) return true;
		if (value === undefined) return true;
		if (value === '') return true;
		if (value === -1) return true;
		return false;
	};

	_getOptionsFromUrl = async () => {
		if (this.props.src) {
			let table = this.props.src.split('-')[0];
			let column = this.props.src.split('-')[1];
			let options = await mainAPI.enum.get({ table, column });
			return options;
		} else if (this.props.endpoint) {
			let options = await this.props.endpoint();
			options = this._formatOptions(options);
			return options;
		}
	};

	_getDisplayValue = (value) => {
		let displayValue = '';
		let label = this.props.label;
		if (label) {
			if (Array.isArray(label)) {
				for (let i in label) {
					displayValue += value[label[i]] + ' ';
				}
			} else {
				displayValue = value[label];
			}
		} else if (this.props.getDisplayValue) {
			displayValue = this.props.getDisplayValue(value);
		} else {
			displayValue = value.name;
		}
		return displayValue;
	};

	_formatOptions = (options) => {
		let opt = {};
		for (let i in options) {
			if (this.props.not && this.props.not.includes(options[i].id)) continue;
			opt[options[i].id] = this._getDisplayValue(options[i]);
		}
		return opt;
	};

	getValue = () => {
		return this.state.value;
	};

	onChange = (value) => {
		let search = this.state.options[value];
		this.setState({ value, search, open: false }, () => {
			this.isValid();
		});
		if (this.props.onChange) this.props.onChange(value);
	};

	_renderInvalidText = () => {
		if (!this.state.valid) {
			return <Validation />;
		}
	};

	_toggleOpen = () => {
		let open = !this.state.open;
		if (open) {
			return this._showOptions();
		}
		let search = this.state.options[this.state.value];
		this.setState({ open: false, search });
	};

	_classes = () => {
		let classes = 'g-drop-down  ' + (this.props.className || '');
		if (this.state.open) classes += ' open ';
		return classes;
	};

	_passSearchCheck = (value) => {
		if (!this.state.search) return true;
		return (value + '').toLowerCase().indexOf(this.state.search.toLowerCase()) !== -1;
	};

	_includeOption = (key, value) => {
		if (!this._passSearchCheck(value)) return false;
		if (!this.props.not) return true;
		for (let i in this.props.not) {
			if (key == this.props.not[i]) {
				return false;
			}
		}
		return true;
	};

	_showOptions = () => {
		if (this.props.onOpen) this.props.onOpen();
		if (this.state.open) return;
		this._setOptions('');
		this.setState({ open: true, search: '' });
	};

	_handleSearchChange = (value) => {
		this.setState({ search: value, open: true });
	};

	_renderSelected = () => {
		return (
			<Input
				onFocus={this._showOptions}
				value={this.state.search}
				onChange={this._handleSearchChange}
				placeholder={this.props.placeholder}
			/>
		);
	};

	_renderOptions = () => {
		let opt = [];
		opt.push(
			<div key={-1} className="disabled">
				{this.props.placeholder}
			</div>
		);
		for (let i in this.state.options) {
			let val = this.state.options[i];
			if (!this._includeOption(i, val)) continue;
			let isSelected = this.state.value == i;
			opt.push(
				<div
					className={isSelected ? 'selected' : ''}
					key={i}
					onClick={() => {
						this.onChange(i);
					}}
				>
					{val}
				</div>
			);
		}
		if (opt.length > 0) return <div className="options">{opt}</div>;
		return null;
	};

	render() {
		return (
			<div ref={(ref) => (this.element = ref)} className={this._classes()} onClick={this._showOptions}>
				{this._renderSelected()}
				{this._renderOptions()}
				<FontIcon name="icon-chevron-right" />
				{this._renderInvalidText()}
			</div>
		);
	}
}
