import React, {Component} from 'react';
import './AppReviewPopup.css';
import Label from "../../label/Label";
import Card from "../../card/Card";
import {Popup} from 'framework7-react';
import Logo from "../../../assets/logosmall.png";
import {openAppStoreReviewPage} from "../../../utils/openAppStoreReviewPage";
import PrimaryButton from "../../primaryButton/PrimaryButton";
import mainAPI from "../../../api/MainAPI";
import toasts from "../../../utils/toasts";
import NoBorderButton from "../../noBorderButton/NoBorderButton";
import SecondaryButton from "../../secondaryButton/SecondaryButton";

export default class AppReviewPopup extends Component {

	constructor(props) {
		super(props);
		this.state = {
			step:'buttons'
		}
		this.myRefs = {}
	}
	close = () => {
		this.setLastPromptDate()
		window.popupController.hide(AppReviewPopup);
	};

	handleGoodReview = async() => {
		try {
			await mainAPI.product_feedback.create({notes: "👍"})
			this.setLastPromptDate();
			this.close();
		}catch(e){
			console.error('handleGoodReview',e)
		}
		openAppStoreReviewPage(this.props?.response?.inApp);
	};

	submit = async () => {
		const notes = this.myRefs.notes.value;
		if(!notes) {
			return toasts.error('Please let us know how we can improve')
		}
		await mainAPI.product_feedback.create({notes})
	  this.setState({step: 'thank-you'});
		setTimeout(() => {
		   this.close()
		}, 1500)
	};

	renderOptions = () => {
		if(this.state.step === 'buttons'){
			return this.renderReviewButtons()
		}
		if(this.state.step === 'negative-review'){
			return this.renderNegativeReview()
		}
		if(this.state.step === 'maybe-later'){
			return this.renderMaybeLater()
		}
		if(this.state.step === 'thank-you'){
			return this.renderThankYou()
		}
	};

	setLastPromptDate = () => {
	  localStorage['lastReviewPromptDate'] = Date.now();
	};

	setMaybeLaterDelay = async (months) => {
	  this.setLastPromptDate();
		if(months > 1) {
			await mainAPI.product_feedback_prompt.create({delay_months: months});
		}
		this.close()
	};

	renderReviewButtons = () => {
		return <>
			<img className="logo" src={Logo} alt="login-logo"/>
			<Label dark large center bold>
				Enjoying LogRx?
			</Label>
			<div className="rating">
				<Label onClick={() => {
					this.setLastPromptDate()
					this.setState({step: 'negative-review'})
				}}>👎</Label>
				<Label onClick={this.handleGoodReview}>👍</Label>
			</div>
			<NoBorderButton className={'maybe-later'} dark center onClick={() => {
				this.setState({step: 'maybe-later'});
			}}>Maybe later</NoBorderButton>
		</>
	};

	renderNegativeReview = () => {
		return <>
			<Label dark large center bold>
				How can we do better?
			</Label>
			<textarea ref={ref => this.myRefs.notes = ref}></textarea>
			<PrimaryButton onClick={this.submit}>Submit</PrimaryButton>
		</>
	};

	renderMaybeLater = () => {
		return <>
			<Label dark large center bold>
				When can we try again?
			</Label>
			<div className={'try-again-buttons'}>
			<PrimaryButton onClick={x=>this.setMaybeLaterDelay(1)}>Next month</PrimaryButton>
			<SecondaryButton onClick={x=>this.setMaybeLaterDelay(6)}>6 months</SecondaryButton>
			</div>
		</>
	};

	renderThankYou = () => {
		return <>
			<Label dark large center bold>
				Thanks for you feedback!
			</Label>
		</>
	};

	render() {
		return (
			<Popup
				opened={this.props.opened}
				tabletFullscreen={true}
				className="g-app-review-popup"
			>
				<div onClick={this.close} className="transparent-backdrop"/>
				<Card>
					{this.renderOptions()}
				</Card>
			</Popup>
		)
	}
}