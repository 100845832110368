import cloneDeep from 'lodash.clonedeep';
import throttle from 'lodash.throttle';
import toasts from './toasts';
import listenForInteraction from './listenForInteraction';
import mainAPI from '../api/MainAPI';
import {VERSION} from "../components/pages/loginHome/LoginHome";

let utils = new (function () {
	// eslint-disable-next-line
	Date.prototype.getWeekOfMonth = function () {
		let firstWeekday = new Date(this.getFullYear(), this.getMonth(), 1).getDay();
		let offsetDate = this.getDate() + firstWeekday - 1;
		return Math.floor(offsetDate / 7);
	};

	let monthNames = ['Jan', 'Feb', 'Mar', 'Apr', 'May', 'June', 'July', 'Aug', 'Sept', 'Oct', 'Nov', 'Dec'];
	let days = ['Sunday', 'Monday', 'Tuesday', 'Wednesday', 'Thursday', 'Friday', 'Saturday'];

	function Copy(obj) {
		return JSON.parse(JSON.stringify(obj));
	}

	this.xDaysAgo = (days) => {
		let daysAgo = 1000 * 60 * 60 * 24 * days;
		return new Date(Date.now() - daysAgo);
	};

	this.copy = function (obj) {
		return Copy(obj);
	};

	this.isNumberType = (n) => typeof n === 'number'

	this.isInt = (n) => {
		return this.isNumberType(n) && Number(n) == n && n % 1 === 0;
	};

	this.isFloat = (n) => {
		return this.isNumberType(n) && Number(n) === n && n % 1 !== 0;
	};

	this.isNumber = (n) => {
		return this.isInt(n) || this.isFloat(n);
	};

	this.parseJSON = (json) => {
		try {
			return JSON.parse(json);
		} catch (e) {
			return cloneDeep(json || {});
		}
	};

	this.hideKeyboard = () => {
		if (window.Keyboard && window.Keyboard.hide) return window.Keyboard.hide();
		document.querySelectorAll('input,textarea').forEach(function (element) {
			if (element === document.activeElement) {
				element.blur();
			}
		});
	};

	this.clicks = [];
	this.resetClicks = () => {
		this.clicks = [];
	};

	this.switchModeClick = () => {
		this.clicks.push(Date.now());
		this.checkFor10Clicks();
	};

	this.checkFor10Clicks = () => {
		console.log(this, this.clicks);
		let numClicksInLast5Seconds = 0;
		let newClicks = [];
		for (let i in this.clicks) {
			if (this.clicks[i] > Date.now() - 5000) {
				numClicksInLast5Seconds++;
				newClicks.push(this.clicks[i]);
			}
		}
		if (numClicksInLast5Seconds >= 10) this.toggleSandboxMode();
		else this.clicks = newClicks;
	};

	this.toggleSandboxMode = () => {
		this.resetClicks();
		if (localStorage.useSandbox) {
			delete localStorage.useSandbox;
		} else {
			localStorage.useSandbox = true;
		}
		let modes = { true: 'Sandbox', false: 'Production' };
		toasts.show('Switching to ' + modes[!!localStorage.useSandbox]);
		setTimeout(this.hardReloadApp, 2000);
	};

	this.display_date = function (dt) {
		if (!dt) {
			return '';
		}
		if (!IsClientDate(dt)) {
			dt = ServerToClientDateTime(dt);
		}
		if (SameDayAsCurrent(dt)) {
			return "Today";
		}
		if (Yesterday(dt)) {
			return 'Yesterday';
		}

		let month = dt.getMonth(); //months from 1-12
		let day = dt.getDate();
		let year = dt.getFullYear();
		if (month || day || year) {
			return monthNames[month] + ' ' + day + ', ' + year;
		} else {
			return '';
		}
	};

	this.display_datetime = function (dt) {
		if (!dt) {
			return '';
		}
		if (!IsClientDate(dt)) {
			dt = ServerToClientDateTime(dt);
		}
		let month = dt.getMonth(); //months from 1-12
		let day = dt.getDate();
		let year = dt.getFullYear();
		if (month || day || year) {
			return monthNames[month] + ' ' + day + ', ' + year + ' ' + DisplayTime(dt);
		} else {
			return '';
		}
	};

	this.client_to_server_datetime = function (dt) {
		return ClientToServerDateTime(dt);
	};

	this.client_to_server_date = function (dt) {
		return ClientToServerDate(dt);
	};

	this.server_to_client_date = function (dt_str) {
		return ServerToClientDate(dt_str);
	};

	this.server_to_client_datetime = function (dt_str) {
		return ServerToClientDateTime(dt_str);
	};

	this.server_to_client_obj = (obj, model_name, meta_data) => {
		let meta = null;
		if (meta_data) {
			meta = meta_data;
		}
		if (obj && Array.isArray(obj)) {
			for (let j in obj) {
				for (let i in meta) {
					if (obj[j][i] !== undefined) {
						obj[j][i] = ServerToClientProperty(obj[j][i], meta[i]);
					}
				}
			}
		} else {
			for (let i in meta) {
				if (obj[i] !== undefined) {
					obj[i] = ServerToClientProperty(obj[i], meta[i]);
				}
			}
		}
		return obj;
	};

	let ServerToClientProperty = (property, metadata) => {
		if (metadata.type === 'date') {
			property = ServerToClientDate(property);
		} else if (metadata.type === 'datetime') {
			property = ServerToClientDateTime(property);
		}
		return property;
	};

	this.client_to_server_obj = (obj, model_name, metadata) => {
		let meta = null;

		if (!meta && metadata) {
			meta = metadata;
		}
		if (obj && Array.isArray(obj)) {
			for (let j in obj) {
				for (let i in meta) {
					if (obj[j][i]) {
						obj[j][i] = ClientToServerProperty(obj[j][i], meta[i]);
					}
				}
			}
		} else {
			for (let i in meta) {
				obj[i] = ClientToServerProperty(obj[i], meta[i]);
			}
		}
		return obj;
	};

	let ClientToServerProperty = (property, metadata) => {
		if (metadata.type === 'date') {
			return ClientToServerDate(property);
		} else if (metadata.type === 'datetime') {
			return ClientToServerDateTime(property);
		} else if (metadata.type === 'related' && property === '') {
			return null;
		} else if (metadata.type === 'boolean') {
			return property ? 1 : 0;
		}
		return property;
	};

	this.file_path_to_base_64 = function (file, callback) {
		FilePathToBase64(file, callback);
	};

	this.call_phone = function (number) {
		CallNumber(number);
	};
	this.message_phone = function (number) {
		OpenMessages(number);
	};

	this.allowNumbersPayRate = function (event) {
		let charCode = event.keyCode;
		if (
			(charCode > 47 && charCode < 58) ||
			charCode === 8 ||
			charCode === 188 ||
			charCode === 190 ||
			charCode === 9 ||
			charCode === 110 ||
			charCode === 46 ||
			charCode === 37 ||
			charCode === 3 ||
			charCode === 17
		) {
			return;
		} else {
			event.preventDefault();
			return false;
		}
	};

	this.addCommasToNumber = (number) => {
		return number.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ',');
	};
	this.generateColor = function () {
		return '#' + Math.random().toString(16).substr(-6);
	};

	this.copyText = (id) => {
		let copyText = document.getElementById(id);
		copyText.select();
		document.execCommand('Copy');
	};

	this.formatBase64Data = (string) => {
		return string.split(',')[1];
	};

	this.isPlatform = (expectedPlatform) => {
		if (!window?.cordova) return false;
		console.log('PLATFORM', window?.cordova?.platformId);
		return window?.cordova?.platformId === expectedPlatform;
	};

	this.areBiometricsAvailable = (success, failure = () => {}) => {
		if (this.isPlatform('ios')) {
			return success();
		}
		if (this.isPlatform('android')) {
			return this.areBiometricsAvailableAndroid(success, failure);
		}
		if (failure) return failure();
	};

	this.areBiometricsAvailableAsync = async () => {
		return new Promise((resolve) => {
			this.areBiometricsAvailable(
				() => {
					resolve(true);
				},
				() => {
					resolve(false);
				}
			);
		});
	};

	this.areBiometricsAvailableIos = () => {
		return window.CID && window.CID.checkAuth;
	};

	this.areBiometricsAvailableAndroid = (success, failure) => {
		try {
			if (!window?.FingerprintAuth) {
				return failure();
			}
			if (!window?.FingerprintAuth?.isAvailable) {
				return failure();
			}
			window.FingerprintAuth.isAvailable((result) => {
				if (result.isAvailable) return success();
				failure();
			}, failure);
		} catch (e) {
			mainAPI.error_log.create({
				type: 'areBiometricsAvailableAndroid',
				message: e?.message + ' ' + e
			});
			failure();
		}
	};

	this.biometricCheck = throttle((success, failure, delayInitAndroid) => {
		if (this.isPlatform('ios')) {
			return this.biometricCheckIos(success, failure);
		} else if (this.isPlatform('android')) {
			return this.biometricCheckAndroid(success, failure, delayInitAndroid);
		}
		failure();
	}, 500, { leading: true, trailing: false })

	this.biometricCheckAndroid = (success, failure,delayInitAndroid) => {
		let encryptConfig = {
			clientId: 'logrx',
			username: '*',
			password: '*',
			userAuthRequired: true,
			disableBackup: true
		};
    let initWasSuccessful = false;

		function successCallback(result) {
      initWasSuccessful = true;
			if (result.withFingerprint) {
				return success(result);
			} else if (result.withBackup) {
				toasts.error('You cannot authenticate with a back up'); // should not be possible with current config values.
				return failure(result);
			}
			return failure(result);
		}

		const errorCallback = (error) => {
      initWasSuccessful = true;
			if (error === window.FingerprintAuth.ERRORS.FINGERPRINT_CANCELLED) {
				failure(error);
			} else {
				failure(error);
			}
		};
    if(!delayInitAndroid){
      return window.FingerprintAuth.encrypt(encryptConfig, successCallback, errorCallback);
    }
    const delay = parseInt(localStorage.getItem('androidBiometricDelayMS') || '3000');
    setTimeout(() => {
      const maxInitDelayMS = 10_000
       if(delay > maxInitDelayMS) return;
       if(!initWasSuccessful){
         // increase delay for next time
         localStorage.setItem('androidBiometricDelayMS', (delay + 500) + '');
       }
    }, 30_000)

    setTimeout(() => { // give android more time to get set up.
      window.FingerprintAuth.encrypt(encryptConfig, successCallback, errorCallback);
    }, delay)
	};

	this.setToken = (token, region, last_login_email) => {
		listenForInteraction.resume();
		localStorage.token = token;
		if(last_login_email) localStorage.last_login_email = last_login_email;
		if(region) localStorage.region = region;
		localStorage.last_log_in = Date.now();
	};

	this.refreshSession = async () => {
		listenForInteraction.resume();
		localStorage.last_log_in = Date.now();
		await mainAPI.user.refreshSession();
	};

	this.biometricCheckIos = (success, failure, attempt=1) => {
		window.CID.checkAuth(
			'Authenticate to continue',
			(successResponse) => {
				success();
			},
			(response) => {
				mainAPI.error_log.create({
					type: 'biometricCheckIos failed',
					message: `${response} ${JSON.stringify(response)} ${window?.cordova?.platformId} ${VERSION} attempt:${attempt}`,
				})
				if(attempt <= 2 && response != 'User cancelled'){
					return setTimeout(() => {
					   this.biometricCheckIos(success, failure, attempt + 1);
					}, 500);
				}
				failure(response);
			}
		);
	};

	function DisplayTime(date) {
		return DisplayTimeMilitary(date);
		// let hours = date.getHours();
		// let minutes = date.getMinutes();
		// let ampm = hours >= 12 ? 'PM' : 'AM';
		// hours = hours % 12;
		// hours = hours ? hours : 12; // the hour '0' should be '12'
		// minutes = minutes < 10 ? '0' + minutes : minutes;
		// let strTime = hours + ':' + minutes + ' ' + ampm;
		// return strTime;
	}

	function DisplayTimeMilitary(date) {
		let hours = date.getHours();
		let minutes = date.getMinutes();
		if (minutes <= 9) {
			minutes = '0' + minutes;
		}
		let strTime = hours.toString() + ':' + minutes.toString();
		if (hours <= 9) {
			strTime = '0' + hours + ':' + minutes;
		}
		return strTime;
	}

	function DisplayDayOfWeek(date) {
		return days[date.getDay()];
	}

	function SameWeekAsCurrent(dt) {
		let current = new Date();
		if (current.getWeekOfMonth() !== dt.getWeekOfMonth()) {
			return false;
		}
		if (current.getMonth() !== dt.getMonth()) {
			return false;
		}
		if (current.getFullYear() !== dt.getFullYear()) {
			return false;
		}
		return true;
	}

	function SameDayAsCurrent(dt) {
		if (!dt) {
			return false;
		}
		let current = new Date();
		if (current.getDate() !== dt.getDate()) {
			return false;
		}
		if (current.getMonth() !== dt.getMonth()) {
			return false;
		}
		if (current.getFullYear() !== dt.getFullYear()) {
			return false;
		}
		return true;
	}

	function Yesterday(dt) {
		let currentDate = new Date();
		let todayMidnight = new Date(currentDate.getFullYear(), currentDate.getMonth(), currentDate.getDate());
		let yesterdayMidnight = new Date(currentDate.getFullYear(), currentDate.getMonth(), currentDate.getDate() - 1);
		if (dt.getTime() >= yesterdayMidnight.getTime() && dt.getTime() <= todayMidnight.getTime()) {
			return true;
		}
		return false;
	}

	function ClientToServerDateTime(dt) {
		if (IsClientDate(dt)) {
			return dt.toISOString().slice(0, 19).replace('T', ' ');
		} else {
			return dt;
		}
	}

	function ClientToServerDate(dt) {
		if (IsClientDate(dt)) {
			return dt.toISOString().substring(0, 10);
		} else {
			return dt;
		}
	}

	function ServerToClientDate(dt_str) {
		if (dt_str === null) {
			return null;
		}
		if (IsClientDate(dt_str)) {
			return dt_str;
		}
		let date = new Date(dt_str);
		return new Date(date.getTime() + date.getTimezoneOffset() * 60000);
	}

	function ServerToClientDateTime(dt_str) {
		if (IsClientDate(dt_str)) {
			return dt_str;
		}
		if (!dt_str) {
			return new Date(''); //invalid Date
		}
		if (!dt_str.replace) {
			return new Date(''); //invalid Date
		}
		let date = DateFromString(dt_str);
		return new Date(date.getTime() - date.getTimezoneOffset() * 60000);
	}

	function DateFromString(dt_str) {
		if (!dt_str) return new Date('');
		dt_str = dt_str.replace('T', ' ').replace('Z', '');
		let a = dt_str.split(/[^0-9]/).map((s) => {
			return parseInt(s, 10);
		});
		return new Date(a[0], a[1] - 1 || 0, a[2] || 1, a[3] || 0, a[4] || 0, a[5] || 0, a[6] || 0);
	}

	function IsClientDate(dt) {
		if (dt && dt.getTime && typeof dt.getTime === 'function') {
			return true;
		} else {
			return false;
		}
	}

	this.is_client_date = function (dt) {
		return IsClientDate(dt);
	};

	this.query_string = () => {
		let queryString = window.location.search;
		let query = {};
		let pairs = (queryString[0] === '?' ? queryString.substr(1) : queryString).split('&');
		for (let i = 0; i < pairs.length; i++) {
			let pair = pairs[i].split('=');
			query[decodeURIComponent(pair[0])] = decodeURIComponent(pair[1] || '');
		}
		return query;
	};

	this.upperCase = (string) => {
		if (typeof string !== 'string') return '';
		return string.toUpperCase();
	};

	function FilePathToBase64(file, callback) {
		let img = new Image();
		img.crossOrigin = 'Anonymous';
		img.src = file;
		img.onload = function () {
			let canvas = document.createElement('canvas');
			let ctx = canvas.getContext('2d');
			canvas.height = img.height;
			canvas.width = img.width;
			ctx.drawImage(img, 0, 0);
			let dataURL = canvas.toDataURL('image/jpeg').slice(23);
			canvas = null;
			let filename = file.match(/([a-zA-Z0-9-_.]+\..*)/g)[0];
			callback(dataURL, filename);
		};
	}

	function OpenMessages(number) {
		window.open('sms:' + number);
	}

	function CallNumber(number) {
		window.open('tel:' + number);
	}

  this.hardReloadApp = function() {
    if(navigator?.splashscreen?.show) {
      navigator.splashscreen.show();
    }
    window.location.reload()
  }
})();

window.utils = utils;
export default utils;
