import React, { Component } from 'react';
import './ChooseGroupPopup.css';

import mainAPI from '../../../api/MainAPI';
import Label from '../../label/Label';
import utils from "../../../utils/utils";
import Popup from "../popup/Popup";

export default class ChooseGroupPopup extends Component {
	constructor(props) {
		super(props);
		this.state = {};
	}

	componentDidMount() {
		this.getGroups();
	}

	getGroups = async () => {
		let groups = await mainAPI.user_group.for_user();
		this.setState({ groups });
	};

	setGroup = (id) => {
		delete localStorage.lastBoxId;
		localStorage.user_group_id = id;
    utils.hardReloadApp()
	};

	renderGroups = () => {
		let toDisplay = [];
		for (let i in this.state.groups) {
			let group = this.state.groups[i];
			toDisplay.push(
				<Label
					className="row"
					key={group.id}
					onClick={() => {
						this.setGroup(group.id);
					}}
					dark
				>
					{group.name}
				</Label>
			);
		}
		return toDisplay;
	};

	render() {
		return (
			<Popup {...this.props} show={this.props.show} className="g-choose-group-popup">
				<Label large dark>
					Choose an Agency
				</Label>
				{this.renderGroups()}
			</Popup>
		);
	}
}
