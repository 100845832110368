import * as React from "react"

export const Logo = (props) => {
	return <svg width="345" height="345" viewBox="-20 -20 385 385" fill="none"
							xmlns="http://www.w3.org/2000/svg" {...props}>
		<path fillRule="evenodd" clipRule="evenodd"
					d="M310.011 289.439V54.5948C308.575 43.9508 300.083 35.4601 289.439 34.0228H112.171V0.000111817H286.238C318.03 0.000111817 344.035 26.0041 344.035 57.7961V286.239C344.035 318.029 318.03 344.033 286.238 344.033H283.546L170.451 176.147L197.384 176.108C266.675 176.001 263.604 86.4201 192.286 86.4201H112.171V53.9521H193.936C223.851 53.9521 246.807 61.9481 262.764 77.9068C287.348 102.485 290.571 151.484 269.008 179.28C259.671 191.316 247.466 200.145 232.404 205.763L300.563 305.448C305.611 301.667 309.132 295.965 310.011 289.439Z"
					fill="#03A8F5"/>
		<path fillRule="evenodd" clipRule="evenodd"
					d="M92.2467 -7.12881e-05V256.521H200.664L223.253 290.104H53.9413V34.1239C43.584 35.8146 35.4093 44.1933 34.0173 54.6346V289.377C35.4307 300.1 43.996 308.641 54.724 310.028H236.653L259.524 344.033H57.796C26.004 344.033 0 318.029 0 286.239V57.7959C0 26.0039 26.004 -7.12881e-05 57.796 -7.12881e-05H92.2467Z"
					fill="#0387D1"/>
	</svg>
}
