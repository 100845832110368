import React, { Component } from 'react';
import { Page, Navbar } from 'framework7-react';
import './ModifyIncidentPage.css';
import Content from '../../helpers/content/Content';
import Label from '../../label/Label';
import mainAPI from '../../../api/MainAPI';
import Card from '../../card/Card';
import Input from '../../input/Input';
import PrimaryButton from '../../primaryButton/PrimaryButton';
import utils from '../../../utils/utils.js';
import Volume from '../../volume/Volume';
import toasts from '../../../utils/toasts';
import models from '../../../model/models';

export default class ModifyIncidentPage extends Component {
	constructor(props) {
		super(props);
		this.state = {};
		this.adjustments = {};
	}

	componentDidMount = () => {
		this.getIncidents();
	};

	getIncidents = async () => {
		let incidents = await mainAPI.stock_move_record.administeredForDrug(this.props.id);
		if (!incidents) incidents = [];
		this.setState({ incidents });
	};

	createDiscrepancies = async () => {
		const drug = await mainAPI.drug.get(this.props.id);
		for (let i in this.adjustments) {
			let previousNumber = this.existingIncidentNumber(i);
			if (previousNumber) {
				let user = models.user.getCurrent();
				let userName = user.first_name + ' ' + user.last_name;
				let disc = {
					created_by_id: user.id,
					discrepancy_type: 'Incident Number Changed',

					notes: `${userName} changed the incident number from (${previousNumber}) to (${
						this.adjustments[i]
					}) for drug with code (${utils.upperCase(drug.barcode_id)})`
				};
				await mainAPI.discrepancy.create(disc);
			}
		}
	};

	save = async (doNotPrompt) => {
		let hasModifiedExistingNumber = this.hasChangedIncidentNumber();
		if (!doNotPrompt && hasModifiedExistingNumber) {
			return window.f7.dialog.confirm(
				'Are you sure you want to continue?',
				'Changing this number will create a discrepancy',
				() => {
					this.save(true);
				}
			);
		}
		if (hasModifiedExistingNumber) {
			await this.createDiscrepancies();
		}

		let success = true;
		for (let i in this.adjustments) {
			let res = await mainAPI.stock_move_record.update({ id: i, incident_number: this.adjustments[i] });
			if (res) {
				toasts.show('Updated ' + this.adjustments[i], 2000);
			} else {
				toasts.error('Did not update');
				success = false;
			}
		}
		if (success) window.f7.views.current.router.back();
	};

	hasChangedIncidentNumber = () => {
		for (let i in this.adjustments) {
			if (this.existingIncidentNumber(i)) {
				return true;
			}
		}
		return false;
	};

	existingIncidentNumber = (stockMoveId) => {
		for (let i in this.state.incidents) {
			let incident = this.state.incidents[i];
			if (!incident) continue;
			if (incident.id == stockMoveId) {
				if (incident.incident_number) {
					return incident.incident_number;
				}
			}
		}
		return false;
	};

	addChangeValue = (value, stockMoveId) => {
		this.adjustments[stockMoveId] = value;
	};

	renderStockMovements = () => {
		if (!this.state.incidents) {
			return (
				<Label large dark center>
					Loading Incidents
				</Label>
			);
		}
		if (this.state.incidents.length == 0) {
			return (
				<Label large dark center>
					No incidents
				</Label>
			);
		}
		let toRender = [];

		for (let i in this.state.incidents) {
			toRender.push(this.renderIncident(this.state.incidents[i]));
		}
		toRender.push(
			<PrimaryButton center onClick={this.save}>
				Save
			</PrimaryButton>
		);
		return toRender;
	};

	renderIncident = (incident) => {
		let dt = utils.display_datetime(incident.created_on);
		return (
			<Card>
				<div className="i-row">
					<Label className="label">AMOUNT</Label>
					<Label dark bold>
						<Volume type={incident.type} value={Math.abs(incident.quantity)} />
					</Label>
				</div>
				<div className="i-row">
					<Label className="label">USER</Label>
					<Label dark bold>
						{incident.created_by}
					</Label>
				</div>
				<div className="i-row">
					<Label className="label">WITNESS</Label>
					<Label dark bold>
						{incident.witnessed_by || incident.guest_verify_name}
					</Label>
				</div>
				<div className="i-row">
					<Label className="label">DATE</Label>
					<Label dark bold>
						{dt}
					</Label>
				</div>
				<div className="i-row location">
					<Label dark bold>
						{incident.location_name}
					</Label>
				</div>
				<Label className="input-label label">INCIDENT</Label>
				<Input
					onChange={(value) => {
						this.addChangeValue(value, incident.id);
					}}
					value={incident.incident_number}
				/>
			</Card>
		);
	};

	render() {
		return (
			<Page className="g-modify-incident-page">
				<Navbar backLink="Back" title={this.props.drugCode} />
				<Content>{this.renderStockMovements()}</Content>
			</Page>
		);
	}
}
