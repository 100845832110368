import toasts from "./toasts";

let ScannerUtils = function () {
	this.validScan = (value) => {
		let valid = true;
		if (value.length < 3) {
			valid = false;
			toasts.error('You need at least 3 characters, you have only entered ' + value.length);
		}
		if (value.indexOf(' ') > -1) {
			valid = false;
			toasts.error('You cannot have spaces in your code');
		}
		return valid;
	};
	this.successCallback = (callback) => {
		if (!callback) {
			return null;
		}
		return function(statusDict) {
			callback(null, convertStatus(statusDict));
		};
	}
	this.errorCallback = (callback) => {
			if (!callback) {
				return null;
			}
			return function(error) {
				let errorCode = parseInt(error);
				let QRScannerError = {};
				switch (errorCode) {
					case 0:
						QRScannerError = {
							name: 'UNEXPECTED_ERROR',
							code: 0,
							_message: 'QRScanner experienced an unexpected error.'
						};
						break;
					case 1:
						QRScannerError = {
							name: 'CAMERA_ACCESS_DENIED',
							code: 1,
							_message: 'The user denied camera access.'
						};
						break;
					case 2:
						QRScannerError = {
							name: 'CAMERA_ACCESS_RESTRICTED',
							code: 2,
							_message: 'Camera access is restricted.'
						};
						break;
					case 3:
						QRScannerError = {
							name: 'BACK_CAMERA_UNAVAILABLE',
							code: 3,
							_message: 'The back camera is unavailable.'
						};
						break;
					case 4:
						QRScannerError = {
							name: 'FRONT_CAMERA_UNAVAILABLE',
							code: 4,
							_message: 'The front camera is unavailable.'
						};
						break;
					case 5:
						QRScannerError = {
							name: 'CAMERA_UNAVAILABLE',
							code: 5,
							_message: 'The camera is unavailable.'
						};
						break;
					case 6:
						QRScannerError = {
							name: 'SCAN_CANCELED',
							code: 6,
							_message: 'Scan was canceled.'
						};
						break;
					case 7:
						QRScannerError = {
							name: 'LIGHT_UNAVAILABLE',
							code: 7,
							_message: 'The device light is unavailable.'
						};
						break;
					case 8:
						// Open settings is only available on iOS 8.0+.
						QRScannerError = {
							name: 'OPEN_SETTINGS_UNAVAILABLE',
							code: 8,
							_message: 'The device is unable to open settings.'
						};
						break;
					default:
						QRScannerError = {
							name: 'UNEXPECTED_ERROR',
							code: 0,
							_message: 'QRScanner returned an invalid error code.'
						};
						break;
				}
				callback(QRScannerError);
			};
		}
};

function stringToBool(string) {
	switch (string) {
		case '1':
			return true;
		case '0':
			return false;
		default:
			throw new Error('QRScanner plugin returned an invalid boolean number-string: ' + string);
	}
}

// Converts the returned ['string':'string'] dictionary to a status object.
function convertStatus(statusDictionary) {
	return {
		authorized: stringToBool(statusDictionary.authorized),
		denied: stringToBool(statusDictionary.denied),
		restricted: stringToBool(statusDictionary.restricted),
		prepared: stringToBool(statusDictionary.prepared),
		scanning: stringToBool(statusDictionary.scanning),
		previewing: stringToBool(statusDictionary.previewing),
		showing: stringToBool(statusDictionary.showing),
		lightEnabled: stringToBool(statusDictionary.lightEnabled),
		canOpenSettings: stringToBool(statusDictionary.canOpenSettings),
		canEnableLight: stringToBool(statusDictionary.canEnableLight),
		canChangeCamera: stringToBool(statusDictionary.canChangeCamera),
		currentCamera: parseInt(statusDictionary.currentCamera)
	};
}

let scannerUtils = new ScannerUtils();

export default scannerUtils;
