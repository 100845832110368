import React, { Component } from 'react';
import './Scanner.css';
import models from '../../../model/models';
import toasts from '../../../utils/toasts';
import scannerUtils from '../../../utils/scannerUtils';
import BoxActionsPopup from '../../popups/boxActionsPopup/BoxActionsPopup';
import Instascan from 'instascan';
import hapticSuccess from "../../../utils/hapticSuccess";
import Icon from "../../icon/Icon";
import ScannedDrugs from "../../scannedDrugs/ScannedDrugs";
import Input from "../../input/Input";
import Label from "../../label/Label";
import Corner from "../../svg/Corner";
import CornerNegative from "../../svg/CornerNegative";
import {ScannerAnimationMs} from "../../../utils/animationConstants";

export default class Scanner extends Component {
	constructor(props) {
		super(props);
		this.waitingForScan = false;
		this.lastScanTime = 0;
		this.scanCount = 0;
		this.lastScanCode = '';
		this.state = {
			type: 'drug', //drug or box
			mode: 'check', //check or scan
			box_id: '',
			drugs: '',
			flashLightEnabled:false,
			scannerPaused:false
		};
		this.myRefs = {};
		this.showScannerTimeouts = [];
	}

	setupWebScanner = () => {
		if (this.webScannerRunning) return;
		this.webScannerRunning = true;
		if (this.webScanner && this.webScanner.start) {
			return this.webScanner.start();
		}

		let options = { video: document.getElementById('ScannerVideo'), scanPeriod: 4 };
		let iOS = /iPad|iPhone|iPod/.test(navigator.userAgent) && !window.MSStream;
		if (iOS) {
			options.mirror = false;
		}
		this.webScanner = new Instascan.Scanner(options);
		this.webScanner.addListener('scan', (content) => {
			this.scanSuccess(null, content);
		});
		Instascan.Camera.getCameras()
			.then((cameras) => {
				console.log(cameras);
				console.log(cameras[1]);
				if (cameras.length > 0) {
					this.webScanner.start(cameras[cameras.length - 1]);
				} else {
					console.error('No cameras found.');
				}
			})
			.catch(function (e) {
				console.error(e);
			});
	};

	getEmptyState = () => {
		return {
			action: null,
			type: 'drug',
			mode: 'check',
			box_id: '',
			drugs: '',
			title: '',
			subTitle: '',
			onSuccess: null,
			onFinish: null,
			onHide: null,
			scannerReady: false,
			scannerPaused: false
		};
	};

	listenForHotKeys = () => {
		window.onkeydown = (e) => {
			// console.log('handleKeyPress', e.keyCode);
			const K = 75;
			if (e.metaKey && e.keyCode == K) { // command k or control k
				this.myRefs.manualInput.forceFocus();
			}
			console.log(e.keyCode);
		};
	};
	removeHotKeyListener = () => {
		window.onkeydown = (e) => {};
	};

	showScanner = (title, subTitle, onSuccess, onFinish, clearState = false, onHide = null, options) => {
		this.scannerDisplayed = true;
		const {
			forceHideManual = false,
			wide = false,
			bottom = null,
			codeTypes = ['DATA_MATRIX', 'QR_CODE', 'org.iso.DataMatrix', 'org.iso.QRCode'],
			allowAnyType = false,
			hideRootDelayMs = 0,
			zoom = 3,
			animateFrom = 'right',
			scannerPaused = false,
			onDuplicateScan=null
		} = options || {};
		this.listenForHotKeys();
		let state = {};
		if (clearState) {
			state = this.getEmptyState();
		}
		this.zoomLevel = zoom
		if (title && subTitle) {
			state.title = title;
			state.subTitle = subTitle;
			state.onSuccess = onSuccess;
			state.onFinish = onFinish;
			state.onHide = onHide;
			state.wide = wide;
			state.bottom = bottom;
			state.forceHideManual = forceHideManual;
			state.codeTypes = codeTypes;
			state.allowAnyType = allowAnyType;
			state.hideRootDelayMs = hideRootDelayMs;
			state.animateFrom = animateFrom;
			state.scannerPaused = scannerPaused;
			state.onDuplicateScan = onDuplicateScan;
			state.scannerArgs = {allowAnyType};
			this.setState(state);
		}

		this.hideRoot(hideRootDelayMs || this.state.hideRootDelayMs || 2);

		if (!window.QRScanner) {
			this.setupWebScanner();
			return console.log('QRScanner not defined');
		}
		if (this.waitingForScan) {
			window.QRScanner.scan(this.scanSuccess);
			return;
		}
		window.QRScanner.getStatus((err, status) => {
			console.log('window.QRScanner.getStatus(', JSON.stringify(err, null, 1), JSON.stringify(status, null,1))
			const prepared = status?.prepared || err?.prepared;
			const scanning = status?.scanning || err?.scanning;

			if(!prepared){
				this.waitingForScan = true;
				console.log('window.QRScanner.prepare(this.scanReady);');
				// window.QRScanner.prepare(this.scanReady);
				window.cordova.exec(scannerUtils.successCallback(this.scanReady), scannerUtils.errorCallback(this.scanReady), 'QRScanner', 'prepare', this.scannerArgs(this.state));

				return;
			}
			if(!scanning){
				return window.QRScanner.scan(this.scanSuccess);
			}
		})
	};

	setZoomLevel = () => {
		if(this.zoomLevel != 3) {
			window.QRScanner.setZoomLevel(console.log, this.zoomLevel)
		}
	};

	scannerArgs = (state) => {
	  if(state.allowAnyType){
			return [];
		}else{
			return ['only-2d'];
		}
	};

	scanReady = (err, status) => {
		console.log('scanReady', err, status);
		this.hideRoot(this.state.hideRootDelayMs);
		if (err) {
			console.error('error scanReady window.QRScanner.prepare(this.scanReady);', err, JSON.stringify(err, null, 1));
			if (err && err.name === "CAMERA_ACCESS_DENIED") {
				return toasts.error('The scanner will not work without permission to access the camera. Touch to open settings.', 4000, true, window.QRScanner.openSettings);
			}
			this.showScannerTimeouts.push(() => {
				window.cordova.exec(scannerUtils.successCallback(this.scanReady), scannerUtils.errorCallback(this.scanReady), 'QRScanner', 'prepare', this.scannerArgs(this.state));
			}, 2000);
		}
		if (status.authorized) {
			this.hideRoot(this.state.hideRootDelayMs);
			window.QRScanner.scan(this.scanSuccess);
			// window.cordova.exec(this.scanSuccess, scannerUtils.errorCallback(this.scanSuccess), 'QRScanner', 'scan', this.scannerArgs(this.state));
			this.showScannerTimeouts.push(setTimeout(window.QRScanner.show, 300));
			this.showScannerTimeouts.push(setTimeout(window.QRScanner.show, 800));
			this.showScannerTimeouts.push(setTimeout(this.setZoomLevel, 400));
			this.showScannerTimeouts.push(setTimeout(this.setZoomLevel, 1100));
			this.showScannerTimeouts.push(setTimeout(() => {
				window.QRScanner.show(function (status) {
					console.log('Scanner show success', status);
				}, 2500);
			}));

		} else if (status.denied) {
			console.error('denied');
			toasts.error('Enable camera use in settings');
			this.showScannerTimeouts.push(setTimeout(() => {
				window.QRScanner.openSettings();
			}, 1000))

			// window.QRScanner.prepare(this.scanReady);
			// The video preview will remain black, and scanning is disabled. We can
			// try to ask the user to change their mind, but we'll have to send them
			// to their device settings with `QRScanner.openSettings()`.
		} else {
			console.error('did not get permission');
			toasts.error('Enable camera use in settings');
			this.showScannerTimeouts.push(setTimeout(() => {
				window.QRScanner.openSettings();
			}, 1000));

			// window.QRScanner.prepare(this.scanReady);
			// we didn't get permission, but we didn't get permanently denied. (On
			// Android, a denial isn't permanent unless the user checks the "Don't
			// ask again" box.) We can ask again at the next relevant opportunity.
		}
	};

	scanSuccess = async (err, response, inputManually = false) => {
		const res = parseResponse(response);
		let text = res.text;
		if(this.state.scannerPaused && inputManually == false) {
			console.log('prevented scan while inputting manually')
			return this.showScanner();
		}
		if(res?.type && !this.state.allowAnyType && !this.state.codeTypes.includes(res.type)){
			return this.showScanner();
		}
		this.scanCount++;
		console.log('scanSuccess', text);
		// if (tryAgain) setTimeout(() => { // uncomment to simulate double scan.
		//   this.scanSuccess(err, text, false);
		// }, 1);
		if (this.lastScanCode == text) {
			if (Date.now() - 2000 < this.lastScanTime) {
				if(typeof this.state.onDuplicateScan === 'function'){
					this.state.onDuplicateScan(text)
				}
				this.lastScanCode = text;
				this.lastScanTime = Date.now();
				console.log('caught duplicate scan ' + this.scanCount + ' ' + text);
				if(!this.scannerDisplayed){
					console.log('prevented "showScanner" when scanner is not displayed');
					return;
				}
				return this.showScanner();
			}
		}
		hapticSuccess()
		if (!inputManually) {
			this.pulseScanner();
		}
		this.lastScanTime = Date.now();
		this.lastScanCode = text;
		if (err) {
			// an error occurred, or the scan was canceled (error code `6`)
			window.f7.dialog.alert(err, 'Scan Error');
		} else {
			let valid = scannerUtils.validScan(text);
			if (!valid) return this.showScanner();
			this.waitingForScan = false;
			this.state.onSuccess(text, inputManually, res.type);
		}
	};

	pulseScanner = () => {
		document.querySelector('.scan-box').classList.add('success')
		setTimeout(() => {
			document.querySelector('.scan-box').classList.remove('success')
		}, ScannerAnimationMs)
	};

	closeModalsAndHideScanner = () => {
		window.popupController.hide(BoxActionsPopup);
		this.hideScanner(this.state.hideRootDelayMs);
		window.f7.dialog.close();
		if(this.myRefs.manualInput){
			this.myRefs.manualInput.clear();
		}
		if (this.state.onHide) {
			this.state.onHide();
		}
	};

	destroyWebScanner = () => {
		this.webScannerRunning = false;
		if (this.webScanner) this.webScanner.stop();
	};

	hideScanner = (animationMs, animateFrom) => {
		console.log('hideScanner', animationMs);
		this.scannerDisplayed = false;
		this.removeHotKeyListener();
		this.showRoot(animationMs);
		const state = {
			drugs: null
		}
		if(animateFrom){
			state.animateFrom = animateFrom;
		}
		this.setState(state, () => {
			if (!window.QRScanner) return this.destroyWebScanner();
			this.waitingForScan = false;
			if (this.destroyScannerTimeout) {
				clearTimeout(this.destroyScannerTimeout)
				this.destroyScannerTimeout = null;
			}
			this.destroyScannerTimeout = setTimeout(this.destroyScanner, animationMs)
		});
	};

	destroyScanner = () => {
		window.QRScanner.hide((status) => {
			console.log('Scanner Hide', status);
			window.QRScanner.destroy((status) => {
				console.log('Scanner destroy', JSON.stringify(status));
				// this.showRoot(this.state.animationMs);
			});
		});
	};

	hideRoot = (hideRootDelayMs = 2) => {
		this.clearShowRootTimeouts();
		this._addGlobalClass('transparent-incoming')
		if (!this.hideTimeout1) {
			this.hideTimeout1 = setTimeout(() => {
				this.hideTimeout1 = null;
				this._addGlobalClass('transparent-incoming-2')
			}, hideRootDelayMs / 2)
		}
		if (!this.hideTimeout2) {
			this.hideTimeout2 = setTimeout(() => {
				this.hideTimeout2 = null;
				this._addGlobalClass('transparent')
			}, hideRootDelayMs)
		}
	};

	clearHideRootTimeouts = () => {
		if(this.hideTimeout2){
			clearTimeout(this.hideTimeout2)
			this.hideTimeout2 = null;
		}
		if(this.hideTimeout1){
			clearTimeout(this.hideTimeout1)
			this.hideTimeout1 = null;
		}
		for (let i in this.showScannerTimeouts){
			clearTimeout(this.showScannerTimeouts[i])
		}
		this.showScannerTimeouts = [];
	};

	clearShowRootTimeouts = () => {
		if(this.showRootTimeout2){
			clearTimeout(this.showRootTimeout2)
			this.showRootTimeout2 = null;
		}
		if(this.showRootTimeout1){
			clearTimeout(this.showRootTimeout1)
			this.showRootTimeout1 = null;
		}
		if(this.destroyScannerTimeout){
			clearTimeout(this.destroyScannerTimeout)
			this.destroyScannerTimeout = null;
		}
	};

	showRoot = (showRootDelayMs = 2) => {
		console.log('showRoot', showRootDelayMs)
		this.clearHideRootTimeouts();
		this._removeGlobalClass('transparent');
		if(!this.showRootTimeout1) {
			this.showRootTimeout1 = setTimeout(() => {
				this.showRootTimeout1 = null;
				this._removeGlobalClass('transparent-incoming-2')
			}, showRootDelayMs / 2)
		}
		if(!this.showRootTimeout2) {
			this.showRootTimeout2 = setTimeout(() => {
				this.showRootTimeout2 = null;
				this._removeGlobalClass('transparent-incoming')
			}, showRootDelayMs)
		}
	};

	_addGlobalClass = (className) => {
		const root = document.getElementById('root');
		const body = document.body;
		const html = document.documentElement;

		if (!root.classList.contains(className)) root.classList.add(className);
		if (!body.classList.contains(className)) body.classList.add(className);
		if (!html.classList.contains(className)) html.classList.add(className);
	};

	_removeGlobalClass = (className) => {
		const root = document.getElementById('root');
		const body = document.body;
		const html = document.documentElement;

		root.classList.remove(className);
		body.classList.remove(className);
		html.classList.remove(className);
	};

	onManualInputBlur = () => {
		this.unPauseScanner();
	};
	onManualInputFocus = () => {
		let user_group = models.user_group.obj();
		if (!user_group?.allow_manual_scan_entry && user_group) {
			toasts.error('Manual Entry is not enabled for your organization');
			return this.myRefs.manualInput.forceBlur();
		}
		this.pauseScanner();
	};

	pauseScanner = () => {
		this.setState({scannerPaused:true});
	};
	unPauseScanner = () => {
		this.setState({scannerPaused:false});
	};

	addManualBarcode = (e) => {
		e.preventDefault();
		this.myRefs.manualInput.forceFocus();
	  const value = this.myRefs.manualInput.getValue();
		this.myRefs.manualInput.clear();
		this.manualBarcode(value);
	};

	manualBarcode = (value) => {
		if(value === '') {
			return this.myRefs.manualInput.forceBlur();
		}
		this.scanSuccess(null, value.trim(), true);
	};

	_getSubTitle = () => {
		return <p>{this.state.subTitle}</p>;
	};

	_getTitle = () => {
		return this.state.title;
	};

	setDrugs = (drugs, action) => {
		this.setState({ drugs: drugs, action });
	};
	toggleFlashlight = () => {
		// if (!window.QRScanner) {
		// 	return null;
		// }
		try {
			if (this.state.flashLightEnabled) {
				this.setState({...this.state, flashLightEnabled: false})
				window.QRScanner.disableLight()
				hapticSuccess()
			} else {
				this.setState({...this.state, flashLightEnabled: true})
				window.QRScanner.enableLight()
				hapticSuccess()
			}
		}catch(e){
			console.error('toggleFlashlight',e)
		}
	};

	_displayCreateButton = () => {
		let drugs = this.state.drugs;
		if (!drugs) {
			return null;
		} else if (drugs.length === 1) {
			return (
				<div className={'flex-row'}><a className="button create center g-button g-white bg-darkblue"  onClick={this._createDrugs}>
					{this.state.action} Drug
				</a></div>
			);
		} else {
			return (
				<div className={'flex-row'}><a className="button create center g-button g-white bg-darkblue" onClick={this._createDrugs}>
					{this.state.action} Drugs
				</a></div>
			);
		}
	};

	_createDrugs = () => {
		this.state.onFinish({}, this.state.drugs);
	};

	renderWebScannerVideo = () => {
		if (window.QRScanner) {
			return null;
		}
		return <video playsInline={true} onPlaying={(e) => {
		   if(e.target.classList[0] ==='active'){
				 //video playing
			 }
		}}  id="ScannerVideo"></video>;
	};


	render() {
		const optionalClasses = []
		if(this.state.scannerPaused){
			optionalClasses.push('scanner-paused')
		}
		if(this.state.wide){
			optionalClasses.push('wide')
		}
		if(this.state.animateFrom){
			optionalClasses.push('animate-scanner-from-' + this.state.animateFrom)
		}
		return (
			<div className={"view view-main navbar-through g-scanner " + optionalClasses.join(' ') } data-page="null">
				<div className="navbar">
					<div className="navbar-inner navbar-on-center">
						<div className="sliding left">
							<button className="button button-link" onClick={this.closeModalsAndHideScanner}>
								<i className="icon-back icon"></i>
								Back
							</button>
						</div>
						<div className="sliding center">{this._getTitle()}</div>
						<div></div>
					</div>
				</div>

				<div className="pages">
					<div className="page page-on-center scanner-page">
						{this.renderWebScannerVideo()}
						<div className="gray-box top">
							<div className={'animate-from-top'}>
							{this._getSubTitle()}
							{!this.state.forceHideManual && <>
								<p className="manual-text">Can't scan the code?</p>
								<div className={'flex-row'}>
							{window.QRScanner && <div className={'flashlight-container ' + (this.state.flashLightEnabled ? 'enabled':'')}>
								<Icon onClick={this.toggleFlashlight} name={'flashlight'+ (this.state.flashLightEnabled ? '':'-white')}/>
								</div>}
								<Input onFocus={this.onManualInputFocus} onBlur={this.onManualInputBlur} ref={ref=>this.myRefs.manualInput = ref} placeholder={"Enter Manually"} onEnter={this.manualBarcode} >
								<div className={'manual-add'} onClick={this.addManualBarcode}  ><Icon tabIndex={-1}  name={'check-white'}/></div>
								<Label white className="enter-helper">Press Enter to submit</Label>
								</Input>
							{window.QRScanner &&<div className={'empty-box-opposite-flashlight'}></div>}
								</div>
							</>
							}
							<ScannedDrugs drugs={this.state.drugs || []}/>
							</div>
						</div>
						<div className="side-row">
							<div className="gray-box side left">

							</div>
							<div className={"scan-box"}>
								<Corner className="top-left corner"/>
								<Corner className="bottom-left corner"/>
								<Corner className="top-right corner"/>
								<Corner className="bottom-right corner"/>
								<CornerNegative className="bottom-left corner-negative"/>
								<CornerNegative className="top-left corner-negative"/>
								<CornerNegative className="top-right corner-negative"/>
								<CornerNegative className="bottom-right corner-negative"/>
							</div>
							<div className="gray-box side right">

							</div>
						</div>
						<div className="gray-box bottom">
							<div className={'bottom-buttons'}>
								{this._displayCreateButton()}
								{this.state.bottom}
							</div>
						</div>
					</div>
				</div>
			</div>
		);
	}
}

const parseResponse = (response) => { // response could be a raw string of the code value or json {text:'asdf', type:'qr'}
	try {
		const data = JSON.parse(response);
		if (data.text) {
			console.log(data.type); // org.gs1.EAN-13, org.gs1.GS1DataBar,org.iso.DataMatrix
			return {...data, text: formatScannedCode(data.text)};
		}
		return {text: formatScannedCode(response)};
	} catch (e) {
		return {text: formatScannedCode(response)};
	}
};

const formatScannedCode = (text) => {
	let cleanedCode = text;
	if(cleanedCode && cleanedCode.includes && cleanedCode.toUpperCase().includes('URL:')){
		cleanedCode = cleanedCode.toUpperCase().replace('URL:', '');
	}
	return cleanedCode;
};