import React, { Component } from 'react';
import Button from '../button/Button';

export default class TertiaryButton extends Component {
	render() {
		return (
			<Button bg="white" color="blue" {...this.props} border>
				{this.props.children}
			</Button>
		);
	}
}
