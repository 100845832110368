import React, { Component } from 'react';
import cloneDeep from 'lodash.clonedeep';
import {PopupAnimationMs} from "../../utils/animationConstants";

export class PopupController extends Component {
	constructor(props) {
		super(props);
		this.state = {
			components: [],
			renderedComponents:[]
		};
		this.closing = false;
	}

	show = (component, props) => {
		if (this.closing) {
			setTimeout(() => {
				this.show(component, props);
			}, 450);
			return;
		}
		if (!props) props = {};
		let components = this.state.components;
		props.opened = true;
		components.push({ component, props });
		this.setComponents(components);
	};

	_matchComponent = (component1, component2) => {
		if (!component1) return false;
		if (!component2) return false;
		if (component1 === component2) return true;
		if (component1 === component2.constructor) return true;
		return false;
	};

	hideAll = () => {
		let state = cloneDeep(this.state);
		for (let i in this.state.components) {
			state.components[i].props.opened = false;
		}
		this.closing = true;
		setTimeout(() => {
			this.setComponents([], () => {
				this.closing = false;
			});
		}, PopupAnimationMs);
		this.setComponents(state.components);

	};

	hide = (component) => {
		let state = cloneDeep(this.state);
		for (let i in state.components) {
			if (this._matchComponent(state.components[i].component, component)) {
				state.components[i].props.opened = false;
				this.closing = true;
				setTimeout(() => {
					delete state.components[i];
					state.components.splice(i, 1);
					this.setComponents(state.components, () => {
						this.closing = false;
					});
				}, PopupAnimationMs);
			}
		}
		this.setComponents(state.components);
	};
	temporaryHideLast = () => {
		let state = cloneDeep(this.state);
		let lastComponentIndex = null;
		for (let i in state.components) {
				lastComponentIndex = i;
		}
		if(lastComponentIndex && state.components[lastComponentIndex]){
			const props = state.renderedComponents[lastComponentIndex].props;
			props.opened = false;
			state.components[lastComponentIndex].props = props;
		}
		this.setComponents(state.components);
	};

	temporaryResumeLast = () => {
		let state = cloneDeep(this.state);
		let lastComponentIndex = null;
		for (let i in state.components) {
			lastComponentIndex = i;
		}
		if(lastComponentIndex && state.components[lastComponentIndex]){
			state.components[lastComponentIndex].props.opened = true;
		}
		this.setComponents(state.components);
	};

	setComponents = (components, callback) => {
	   const renderedComponents = this._components(components);
		 this.setState({components, renderedComponents}, callback);
	};

	_components = (components) => {
		let comps = [];
		for (let i in components) {
			let component = components[i];
			let props = components[i].props;
			let hide = this.hide;
			if (props.disableClose) {
				hide = null;
			}
			comps.push(<component.component onPopupClosed={hide} key={i} onClose={hide} {...props} />);
		}
		if (comps.length > 0) return comps;
		return null;
	};

	render() {
		return this.state.renderedComponents;
	}
}
