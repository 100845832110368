import React, { Component } from 'react';
import { Popup, View } from 'framework7-react';
import './WasteDrugPopup.css';
import FontIcon from '../../fontIcon/FontIcon';

import WasteDrugDetailsPage from '../../pages/wasteDrugDetailsPage/WasteDrugDetailsPage';

export default class WasteDrugPopup extends Component {
	constructor(props) {
		super(props);
		this.state = {
			opened: true
		};
	}
	close = () => {
		window.popupController.hide(WasteDrugPopup);
	};

	render() {
		return (
			<Popup
				onPopupBeforeDestroy={this.close}
				// onPopupClose={this.close}
				opened={this.props.opened}
				tabletFullscreen={true}
				className="g-waste-drug-popup"
			>
				<FontIcon onClick={this.close} name="icon-close" color="white" />
				<View stackPages>
					<WasteDrugDetailsPage onComplete={this.props.onComplete} noBackButton />
				</View>
			</Popup>
		);
	}
}
