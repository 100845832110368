import React, {Component} from 'react';
import { Popup, Page, Navbar } from 'framework7-react';
import Vial from 'assets/vial-barcode.png';

import './NdcScannerExplainerPopup.css';
import Label from "../../label/Label";
import PrimaryButton from "../../primaryButton/PrimaryButton";

export default class NdcScannerExplainerPopup extends Component {
	render() {
		return (
			<Popup opened={this.props.opened} tabletFullscreen={true} className="g-ndc-scanner-explainer-popup never-transparent">
				<Page >
					<Navbar title={'Barcode Import'}></Navbar>
					<Label center dark large>Scan the labeler/NDC barcode</Label>
					<img className="vial" src={Vial} alt="drug-vial" />
					<Label center dark>If we find a match, we will auto fill the details we find. (type, expiration, and amount)</Label>
					<Label center dark>Make sure everything is correct before continuing.</Label>
					<Label center dark>The barcode is printed on the vial or packaging. Please note that this is not the LogRx QR code.</Label>
					<PrimaryButton center onClick={() => {
					   window.popupController.hide(NdcScannerExplainerPopup)
					}}>Done</PrimaryButton>
				</Page>
			</Popup>
		)
	}
}