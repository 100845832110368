import React, { Component } from 'react';
import './Button.css';
import throttle from 'lodash.throttle';

export default class Button extends Component {
	getClasses = () => {
		return (
			(this.props.className || '') +
			this.getCenterClass() +
			' g-button button ' +
			this.getColor() +
			' ' +
			this.getBackground() +
			' ' +
			this.getDisabled() +
			' ' +
			this.getBorder() +
			' ' +
			this.getNoShadow()
		);
	};

	getNoShadow = () => {
		if (this.props.noShadow) return 'no-shadow';
		return '';
	};
	getCenterClass = () => {
		if (this.props.center) {
			return ' center ';
		}
		return ' ';
	};

	getColor = () => {
		if (this.props.color) {
			return 'g-' + this.props.color;
		}
		return '';
	};

	getBackground = () => {
		if (this.props.bg) {
			return 'bg-' + this.props.bg;
		}
		return '';
	};

	getDisabled = () => {
		if (this.props.disabled) {
			return 'disabled';
		}
		return '';
	};

	getBorder = () => {
		if (this.props.border) {
			return 'border';
		}
		return '';
	};

	onClick = throttle((e) => {
		e.preventDefault()
		e.stopPropagation();
		if (this.props.disabled) return;
		else if (this.props.onClick) {
			this.props.onClick();
		}
	}, 500,  { leading: true, trailing: false }); // allow first click but ignore others until after 300 ms.

	preventDefault = (e) => {
	   e.preventDefault();
		 e.stopPropagation();
		 return false;
	};

	render() {
		// eslint-disable-next-line
		let { className, color, bg, onClick, center, noShadow, ...props } = { ...this.props };
		return (
			<a
				draggable={false}
				onContextMenu={this.preventDefault}
				onTouchMove={this.preventDefault}
				onDragStart={this.preventDefault}
				onTouchStart={this.preventDefault}
				onTouchEnd={this.preventDefault}
				onTouchCancelCapture={this.preventDefault}
				onTouchCancel={this.preventDefault}
				onDrag={this.preventDefault}
				onMouseDown={this.preventDefault}
				{...props}
				onClick={this.onClick}
					 className={this.getClasses()}  >
				{this.props.children}
			</a>
		);
	}
}
