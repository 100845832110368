import React, {Component} from 'react';
import mainAPI from '../../../api/MainAPI';
import {Page, Views, View} from 'framework7-react';
import Label from '../../label/Label';
import Input from '../../input/Input';
import PrimaryButton from '../../primaryButton/PrimaryButton';
import './EnterDualFactorCodePopup.css';
import toasts from '../../../utils/toasts';
import FontIcon from '../../fontIcon/FontIcon';
import {onLoginSuccess} from '../../pages/login/Login';
import Popup from "../popup/Popup";
import {enterPhoneNumberPageRoute} from "../../pages/enterPhoneNumberPage/EnterPhoneNumberPage";

export default class EnterDualFactorCodePopup extends Component {
	constructor(props) {
		super(props);
		this.myRefs = {};
	}

	submit = async () => {
		const code = this.myRefs.code.getValue();
		const user = await mainAPI.user.loginWithCode({
			dual_factor_guid: this.props.dualFactorGuid,
			dual_factor_code: code
		});
		if (!user || !user.token) return toasts.error('Incorrect Code');
		if (user.can_set_phone_number) {
			return window.f7.views.dual_factor_view.router.navigate(enterPhoneNumberPageRoute, {
				props: {
					onSuccess: x => this.setUser(user),
					onCancel: x => this.setUser(user),
					dual_factor_guid: this.props.dualFactorGuid,
					dual_factor_code: code
				}
			});
		}
		this.setUser(user)
	};
	setUser = (user) => {
		this.close();
		onLoginSuccess(user)
	};

	close = () => {
		window.popupController.hide(EnterDualFactorCodePopup);
	};

	render() {
		return (
			<Popup {...this.props} show={this.props.show} className="g-enter-dual-factor-code-popup">
				<FontIcon onClick={this.close} name="icon-close" color="black"/>
				<Views><View name={'dual_factor_view'}>
					<Page className={'dual-factor-code-page'}>
						<Label large dark center>
							Enter the OTP code sent to you
						</Label>
						<Label dark-gray center className={'expiration'}>
							Expires after 1 minute
						</Label>
						<Input onEnter={this.submit} border center autoFocus textContentType="one-time-code" autocomplete="one-time-code" placeholder={'OTP code'}
									 ref={(ref) => (this.myRefs.code = ref)}/>
						<PrimaryButton center onClick={this.submit}>Submit</PrimaryButton>
					</Page>
				</View></Views>
			</Popup>
		);
	}
}
