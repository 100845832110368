import React, {Component} from 'react';
import './BoxCheckScanProgressCard.css';
import Card from "../../../card/Card";
import Label from "../../../label/Label";
import PrimaryButton from "../../../primaryButton/PrimaryButton";
import ProgressBar from "../progressBar/ProgressBar";

export default class BoxCheckScanProgressCard extends Component{
	constructor(props) {
		super(props);
		this.state = {
			progress: this.props.progress
		}
	}
	componentWillReceiveProps(nextProps, nextContext) {
		this.setState({progress:nextProps.progress})
	}

	render(){
    return(
    <Card className="g-box-check-scan-progress-card">
			<Label center medium dark>Scan all the drugs in the box</Label>
			<ProgressBar progress={this.state.progress} total={this.props.total}/>
			<PrimaryButton onClick={this.props.onContinue} center>Continue</PrimaryButton>
    </Card>
    )
  }
}