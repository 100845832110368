import React, { Component } from 'react';

import { App, Link, Navbar, NavRight, Page, Popup, Statusbar, View, Views } from 'framework7-react';

import { routes } from '../routes';
import '../css/f7override/Navbar.css';
import '../css/f7override/Page.css';
import '../css/f7override/Popup.css';
import '../css/f7override/Dialog.css';
import '../css/f7override/notch.css';
import '../css/f7override/Toast.css';
import { PopupController } from './popups/popupController';
import LoginPopup from './popups/loginPopup/LoginPopup';
import ResetPasswordPopup from './popups/resetPasswordPopup/ResetPasswordPopup';
import LeftPanel from 'components/LeftPanel.jsx';
import DashBoardPage from './pages/dashboardPage/DashBoardPage';
import listenForInteraction from '../utils/listenForInteraction';
import mainAPI from '../api/MainAPI';
import toasts from '../utils/toasts';
import StackTrace from 'stacktrace-js';
import ToastController from "./toasts/ToastController";
import vpnController from "../controllers/vpnController";

const MainViews = (props, context) => {
	setTimeout(() => {
		if (window.StatusBar) {
			window.StatusBar.styleLightContent();

			// window.StatusBar.backgroundColorByHexString('#284677')
		}
	}, 3000);

	return (
		<Views id={"main-views"}>
			<View id="main-view" navbarThrough dynamicNavbar={true} main url="/" stackPages>
				<DashBoardPage />
			</View>
		</Views>
	);
};

const AppPopup = () => (
	<Popup id="popup">
		<View navbarFixed>
			<Page>
				<Navbar title="Popup">
					<NavRight>
						<Link closePopup>Close</Link>
					</NavRight>
				</Navbar>
			</Page>
		</View>
	</Popup>
);

class APP extends Component {
	componentDidCatch = (error, info) => {
		const correlationId = Date.now();
		StackTrace.fromError(error).then((updated) => {
			mainAPI.error_log.create({
				type: 'App.componentDidCatch',
				message: correlationId + localStorage.token + JSON.stringify(updated)
			});
		});
		mainAPI.error_log.create({
			type: 'App.componentDidCatch',
			message: correlationId + localStorage.token + JSON.stringify(error) + JSON.stringify(info)
		});
	};
	appResume = async () => {
		await mainAPI.user.get();
		await this.checkVpnStatus();
	};
	checkVpnStatus = async () => {
		const vpnConnected = await vpnController.isVpnConnected();
		if (vpnConnected) {
			toasts.error('We recommend disconnecting from your VPN while using the app', 2500);
		}
	};
	componentDidMount = () => {
		listenForInteraction.onInteraction(() => {
			localStorage.last_log_in = Date.now();
		});
		document.addEventListener('resume', this.appResume, false);
		document.addEventListener('chcp_updateIsReadyToInstall', this.showHotUpdateToast, false);
		// toasts.show('localStorage.updating', localStorage.updating);
		// this.showUpToDateToast();
		setTimeout(() => {
			if (navigator && navigator.splashscreen && navigator.splashscreen.hide) navigator.splashscreen.hide();
		}, 1000);
		document.fonts.load('16px "Helvetica Neue"').then(() => {
			console.log('main-font-loaded')
			document.body.classList.remove('main-font-loading')
		});
		document.fonts.load('16px "untitled-font-1"').then(() => {
			console.log('icon-font-loaded')
			document.body.classList.remove('icon-font-loading')
		});
	};

	showHotUpdateToast = () => {
		localStorage.updating = true;
		toasts.show('Updating to new version');
	};

	showUpToDateToast = () => {
		if (!localStorage.updating) return;
		setTimeout(() => {
			delete localStorage.updating;
			toasts.show('Update installed. You are now on the latest version.', 3000);
		}, 3000);
	};

	render() {
		this.showUpToDateToast();
		return (
			<App
				params={{ theme: 'ios', routes, name: 'LogRx', id: 'com.logrx.main' }}
				ref={(ref) => {
					if (!window.f7) window.f7 = ref.$f7;
				}}
			>
				<Statusbar />
				<LeftPanel />
				<PopupController ref={(ref) => (window.popupController = ref)} />
				<ToastController ref={(ref) => (window.toastController = ref)} />
				<MainViews />
				<AppPopup />
				<ResetPasswordPopup />
				<LoginPopup />
			</App>
		);
	}
}

export default APP;
