import React, { Component } from 'react';
import classNames from 'classnames';
import './Icon.css';

export class Icon extends Component {
	constructor() {
		super();
		this.state={
			pressed: false
		}
	}
	getSize = () => {
		let size = this.props.size;
		if (size === 'xl') {
			return 'g-xl';
		} else if (size === 'xxxxl') {
			return 'g-xxxxl';
		} else if (size === 'xxxl') {
			return 'g-xxxl';
		} else if (size === 'xxl') {
			return 'g-xxl';
		} else if (size === 'large') {
			return 'g-lg';
		} else if (size === 'medium') {
			return 'g-md';
		} else if (size === 'small') {
			return 'g-sm';
		} else if (size === 'x-small') {
			return 'g-xsm';
		} else if (size === 'xx-small') {
			return 'g-xxsm';
		} else {
			return 'g-sm';
		}
	};
	touchStart = () => {
		if(!this.props.onClick)return;
		this.setState({pressed:true})
	};

	touchEnd = () => {
		if(!this.props.onClick)return;
		this.setState({pressed:false})
	};
	classes = () => {
		const classes = [this.props.className]
		if(this.state.pressed) {
			classes.push('pressed');
		}
		if(this.props.onClick) {
			classes.push('onClick');
		}
		return classes.join(' ');
	};
	render() {
		return (
			<div
				onClick={this.props.onClick}
				onMouseDown={this.touchStart}
				onMouseUp={this.touchEnd}
				onMouseLeave={this.touchEnd}
				onTouchStart={this.touchStart}
				onTouchEnd={this.touchEnd}
				onTouchCancel={this.touchEnd}
				className={classNames('g-icon-component', 'g-icon', this.props.name, this.getSize(), this.classes(), {
					disabled: this.props.disabled
				})}
			>
				{this.props.children}
			</div>
		);
	}
}

export default Icon;
