import React, { Component } from 'react';
import './Input.css';
import FontIcon from '../fontIcon/FontIcon';
import Validation from '../validation/Validation';
import toasts from '../../utils/toasts';
import HideShowPassword from "../hideShowPassword/HideShowPassword";
import {XIcon} from "../svg/XIcon";

export default class Input extends Component {
	constructor(props) {
		super(props);
		this.state = {
			value: this._defaultValue(),
			valid: true,
			showPassword:false,
		};
		this.myRefs = {}
	}
	componentDidMount() {
		if (this.props.autoFocus) {
			setTimeout(this.forceFocus, 100)
		}
	}

	componentWillReceiveProps = (nextProps) => {
		if (nextProps.value !== this.state.value && nextProps.value !== undefined) {
			this.setState({ value: nextProps.value });
		}
	};

	getValue = () => {
		return this.state.value;
	};

	_defaultValue = () => {
		if (this._isEmpty(this.props.value)) {
			return '';
		}
		return this.props.value;
	};

	_isEmpty = (value) => {
		if (value === null) return true;
		if (value === undefined) return true;
		if (value === '') return true;
		return false;
	};

	_handleKeyUp = (e) => {
		if (e.key === 'Enter') {
			if(this.props.onEnter){
				this.props.onEnter(e.target.value);
				this.clear();
			}
		}
	}

	clear = () => {
		this.setState({ value: '' });
	};
	_valueChanged = (e) => {
		let value = e.target.value;
		if (this.props.length && value.length > this.props.length) {
			return toasts.error(`This is limited to ${this.props.length} characters.`);
		}
		this.setState({ value }, () => {
			this.setState({ valid: this._isValid() });
			if (this.props.onChange) {
				this.props.onChange(value);
			}
		});
	};

	toggleShowPassword = (showPassword) => {
		this.setState({ showPassword });
	};
	getIcon = () => {
		if(this.props.type === 'password'){
			return <HideShowPassword onChange={this.toggleShowPassword}/>
		}
		if(this.props.showCancelX && this.state.value){
			return <XIcon onClick={this.clearValue}/>
		}
		if (this.props.iconName) {
			return <FontIcon color="gray" name={this.props.iconName} />;
		}
	};

	clearValue = () => {
		this.setState({ value: '' });
	}

	getInputClasses = () => {
		if (this.props.iconName) {
			return 'icon';
		}
		return '';
	};

	getClasses = () => {
		const isEmpty = this._isEmpty(this.state.value)
		const {wrapperClassName, center, border, type} = this.props;
		let classes = 'g-input item-input';
		if (center) {
			classes += ' center';
		}
		if(isEmpty){
			classes += ' empty ';
		}
		if(border){
			classes += ' border ';
		}
		if(wrapperClassName) {
			classes += ` ${wrapperClassName} `;
		}
		if(type){
			classes += ` type-${type} `;
		}
		return classes;
	};

	placeholder = () => {
		if (this._isEmpty(this.state.value)) {
			return this.props.placeholder;
		}
		return '';
	};

	_isValid = () => {
		let type = this.props.type;
		let val = this.state.value;
		let valid = true;
		let re = null;
		if ((type === undefined || type === 'text') && this.props.required) {
			valid = val !== '';
		} else if (type === 'email') {
			re =
				// eslint-disable-next-line
				/^(([^<>()\[\]\\.,;:\s@"]+(\.[^<>()\[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
		} else if (type === 'number') {
			re = /[+-]?(?=\.\d|\d)(?:\d+)?(?:\.?\d*)(?:[eE][+-]?\d+)?/;
		} else if (type === 'date') {
			if (val && val.length > 0) valid = true;
			else valid = false;
		} else {
			valid = true;
		}
		if (re) {
			valid = re.test(val);
		}
		return valid;
	};

	isValid = () => {
		let valid = this._isValid();
		this.setState({ valid });
		return valid;
	};

	_isInvalidText = () => {
		if (!this.state.valid) {
			return <Validation />;
		}
	};

	forceBlur = () => {
		this.myRefs.input.blur();
	};

	_handleFocus = () => {
		if (this.props.onFocus) this.props.onFocus();
	};

	_checkForDisabled = () => {
		if (this.props.disabled) {
			toasts.error('This is disabled');
		}
	};
	forceFocus = () => {
	  this.myRefs.input.focus();
	};

	render() {
		let type = this.props.type;
		if(type === 'password'){
			if(this.state.showPassword){
				type = 'text';
			}
		}
		return (
			<div className={this.getClasses()} onClick={this._checkForDisabled} data-placeholder={this.props.placeholder} >
				<input
					spellCheck="false"
					ref={ref=>this.myRefs.input = ref}
					onKeyUp={this._handleKeyUp}
					onChange={this._valueChanged}
					type={type}
					placeholder={this.placeholder()}
					onFocus={this._handleFocus}
					onBlur={this.props.onBlur}
					value={this.state.value}
					disabled={!!this.props.disabled}
					className={this.getInputClasses()}
					autocomplete={this.props.autocomplete}
					textContentType={this.props.textContentType}
				></input>
				{this.getIcon()}
				{this._isInvalidText()}
				{this.props.children}
			</div>
		);
	}
}
