import React, {Component, useState} from 'react';
import './DrugScanned.css';
import { Block, List, ListItem, Page, View } from 'framework7-react';
import FontIcon from '../../fontIcon/FontIcon';
import Card from '../../card/Card';
import Vial from 'assets/vial_with_code.png';
import models from '../../../model/models';
import utils from 'utils/utils';
import mainAPI from '../../../api/MainAPI';
import Volume from '../../volume/Volume';
import getLocation, { getLastLocation } from '../../../utils/getLocation';
import transferDrugController from '../../../controllers/transferDrugController';
import BatchTransferPopup from '../batchTransferPopup/BatchTransferPopup';
import PrimaryButton from '../../primaryButton/PrimaryButton';
import SecondaryButton from '../../secondaryButton/SecondaryButton';
import TertiaryButton from '../../tertiaryButton/TertiaryButton';
import NoBorderButton from '../../noBorderButton/NoBorderButton';
import Label from '../../label/Label';
import { administerDetailsPageRoute } from '../../pages/administerDetailsPage/AdministerDetailsPage';
import { unAdministerMultiUsePageRoute } from '../../pages/unAdministerMultiUsePage/UnAdministerMultiUsePage';
import wasteController from '../../../controllers/wasteController';
import batchAdministerController from '../../../controllers/batchAdministerController';
import {boxTransferPageRoute} from "../../pages/boxTransferPage/BoxTransferPage";
import Popup from "../popup/Popup";
import Pencil from "../../svg/Pencil";
import DatePicker from "../../datePicker/DatePicker";
import toasts from "../../../utils/toasts";

let Navbar = (props) => {
	return (
		<div className="g-navbar">
			<div></div>
			<div>Drug Details</div>
			<FontIcon onClick={props.cancel} name="icon-close" color="white" />
		</div>
	);
};

class DrugScanned extends Component {
	constructor(props) {
		super(props);
		this.state = {
			drug: {},
			can_un_administer: false,
			drug_in_other_agency: false,
			is_reusable: false,
			history: [],
			drugHasUsage: false
		};
		getLocation();
	}

	componentDidMount = async () => {
		await this.getDrugInfo();
	};

	componentWillUnmount = () => {
		this.close();
	};

	getDrugInfo = async () => {
		const id = this.props.value.id;

		const promises = [
			mainAPI.drug.get(id),
			mainAPI.stock_move_record.userGroupTransferInfo(id),
			mainAPI.stock_move_record.forDrug(id)
		];

		let [drug, userGroupTransferInfo, history] = await Promise.all(promises);
		const drugHasUsage = !!history.filter(({stock_move_type__name: type}) => type !== 'take_ownership').length
		const response = await mainAPI.drug_type.is_short_date_allowed(drug.type);

		this.setState({
			drug,
			can_un_administer: userGroupTransferInfo.can_un_administer,
			drug_in_other_agency: userGroupTransferInfo.drug_in_other_agency,
			is_reusable: userGroupTransferInfo.is_reusable,
			history,
			short_date_allowed: Boolean(response.allowed),
			drugHasUsage
		});
	};

	close = () => {
		window.f7.popup.close();
	};

	continue = async (val) => {
		if (val === 'waste') {
			wasteController.start(this.props.value, this.state.drug.drug_box_id);
		} else if (val === 'administer') {
			batchAdministerController.reset();
			let value = this.props.value;
			window.f7.views.current.router.navigate(administerDetailsPageRoute, {
				props: {
					value: {
						id: value.id,
						quantity: value.stock_move_record__quantity,
						barcodeId: value.barcode_id
					}
				}
			});
		} else if (val === 'box_change') {
			window.popupController.show(BatchTransferPopup, {
				onYesAddMore: this._startBatchTransfer,
				onNoBatchTransfer: this._transferDrug
			});
		} else if (val === 'agency_change') {
			window.popupController.show(BatchTransferPopup, {
				onYesAddMore: () => this._startBatchTransfer(true),
				onNoBatchTransfer: () => this._transferDrug(true)
			});
		} else if (val === 'un_administer') {
			let value = this.props.value;
			let location = getLastLocation();
			if (this.state.is_reusable) {
				return window.f7.views.current.router.navigate(unAdministerMultiUsePageRoute, {
					props: { drugCode: value.barcode_id, id: value.id }
				});
			}
			await mainAPI.stock_move_record.create({
				stock_move_type_id__name: 'un_administer',
				drug_id: value.id,
				doses: 0,
				quantity: value.quantity,
				lat: location.lat,
				lon: location.lon
			});
			for (let i in models) {
				if (models[i].refreshData) models[i].refreshData();
			}
			this.close();
		}
	};

	_startBatchTransfer = (transferAgency) => {
		transferDrugController.start([this.props.value], transferAgency);
	};

	_transferDrug = (transferAgency) => {
		// window.popupController.hideAll();
		window.f7.views.current.router.navigate(boxTransferPageRoute, {props:{ transferAgency, drugIds: JSON.stringify([this.props.value.id]) }});
	};

	cancel = () => {
		window.popupController.hide(BatchTransferPopup);
		window.popupController.hide(DrugScanned);
		this.props.onCancel();
	};

	modifyIncidentNumber = () => {
		let value = this.props.value || {};
		window.f7.views.current.router.navigate('/modify/incident', {
			props: { drugCode: value.barcode_id, id: value.id }
		});
	};

	drugHistory = () => {
		let value = this.props.value || {};
		window.f7.views.current.router.navigate('/drug/history', { props: { id: value.id } });
	};

	maybeRenderModifyIncidentNumber = () => {
		const {drugHasUsage} = this.state;
		// If the drug is reusable and has had at least one usage or the drug is not reusable and has been fully used
		// then the incident number can be modified. We can't use (drug stock < drug quantity) because this is not
		// true for a drug that has been used and then un-administered to it's full amount.
		// All of this can be simplified to "if the drug has any usage".
		if (drugHasUsage) {
			const {user_group_id} = models.user.getCurrent() || {};
			const {can_modify_incident_number = false} = models.user_group.obj(user_group_id) || {};
			if (can_modify_incident_number) {
				return (
					<SecondaryButton key={'modify-incident-number'} onClick={this.modifyIncidentNumber}>Modify Incident
						Number</SecondaryButton>);
			}
		}
		return (<></>);
	}

	renderOptions = () => {
		let user = models.user.getCurrent();
		let user_group = models.user_group.obj(user.user_group_id);
		let options = [];
		options.push(<NoBorderButton key={'history'} onClick={this.drugHistory}>History</NoBorderButton>);
		if (!this.state.drug?.id) return null;
		if (this.state.drug.stock_move_record__quantity > 0) {
			options.push(
				<PrimaryButton
					key={'administer'}
					onClick={() => {
						this.continue('administer');
					}}
				>
					Administer Drug
				</PrimaryButton>
			);

			options.push(
				<SecondaryButton
					key={'box-change'}
					onClick={() => {
						this.continue('box_change');
					}}
				>
					Change Box
				</SecondaryButton>
			);

			options.push(
				<TertiaryButton
					key={'waste'}
					onClick={() => {
						this.continue('waste');
					}}
				>
					Waste Drug
				</TertiaryButton>
			);
			if (user_group.allow_agency_drug_transfers) {
				options.push(
					<NoBorderButton
						key={'agency-change'}
						onClick={() => {
							this.continue('agency_change');
						}}
					>
						Change Agency
					</NoBorderButton>
				);
			}
		} else {
			if (this.state.drug_in_other_agency) {
				options.push(
					<Label key={'drug-has-been-transferred'} center dark>
						This drug has been transferred to another agency
					</Label>
				);
				options.push(
					<Label key={'drug-can-be-transferred-back'} center dark>
						Someone from that agency can transfer it back
					</Label>
				);
			}
		}
		if (this.state.can_un_administer) {
			options.push(
				<PrimaryButton
					key={'un-administer'}
					onClick={() => {
						this.continue('un_administer');
					}}
				>
					Un-Administer
				</PrimaryButton>
			);
		}
		options.push(this.maybeRenderModifyIncidentNumber());
		return options;
	};
	updateShortDate = async (value) => {
	  const response = await mainAPI.drug.update({id:this.state.drug?.id, short_date:value});
		if (response) {
			toasts.show('Short Date updated');
		} else {
			toasts.error('Short Date did not update');
		}
		await this.getDrugInfo();
	};

	render() {
		let value = this.state.drug || {};
		return (
			<Popup
				onPopupBeforeDestroy={this.close}
				onPopupClose={this.close}
				tabletFullscreen
				opened={this.props.opened}
				className="g-drug-scanned never-transparent"
			>
				<View stackPages>
					<Page className="g-drug-scanned-page">
						<Navbar cancel={this.cancel} />
						<Block>
							<div className="success">
								<FontIcon name="icon-done-all" />
								<p>Scanned successfully</p>
								<div></div>
							</div>
							<div className="info">
								<img className="vial" src={Vial} alt="drug-card" />
								<Card>
									<List>
										<ListItem>
											<p>Drug</p>
											<p className="drug-type">{models.drug_type.obj(value.type)?.name || ''}</p>
										</ListItem>
										<ListItem>
											<p>Exp</p>
											<p>{utils.display_date(value.expiration_date)}</p>
										</ListItem>
										{this.state.short_date_allowed && <ListItem>
											<p>Short Date</p>
											<DateEditor value={value.short_date} onChange={this.updateShortDate}/>
										</ListItem>}
										<ListItem>
											<p>Amount</p>
											<p>
												<Volume type={value.type} value={value.stock_move_record__quantity} />
											</p>
										</ListItem>
										<ListItem>
											<p>Code</p>

											<p className={'selectable'}>{utils.upperCase(value.barcode_id)}</p>
										</ListItem>
									</List>
								</Card>
							</div>
							<div className="drug-options">
								{this.renderOptions()}
							</div>
						</Block>
					</Page>
				</View>
			</Popup>
		);
	}
}

export default DrugScanned;

const DateEditor = (props) => {
	const [editing, setEditing] = useState(false);
	const onCalendarClose = () => {
		setEditing(false);
	};
	const handleChange = (value) => {
	   if(props.onChange)props.onChange(value);
		 setEditing(false);
	};
	if(editing) {
		return <DatePicker className={'date-picker-hidden-input'} value={props.value} autoFocus onChange={handleChange} onCalendarClose={onCalendarClose}/>
	} else {
		return <div><Pencil className={'edit-icon'} onClick={e => setEditing(true)}/>{utils.display_date(props.value)}</div>
	}
};
