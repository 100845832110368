import React, {Component} from 'react';
import './Toast.css';
import Check from "../../svg/Check";
import Label from "../../label/Label";
import {CircleX} from "../../svg/CircleX";

export default class Toast extends Component{
	constructor() {
		super();
		this.state = {show:false}
	}
	componentDidMount() {
		this.showTimeout = setTimeout(this.show,50)
	}
	componentWillUnmount() {
		if(this.showTimeout){
			clearTimeout(this.showTimeout)
		}
		if(this.hideTimeout){
			clearTimeout(this.hideTimeout)
		}
	}

	show = () => {
		this.setState({show:true});
		this.hideTimeout = setTimeout(this.hide, this.props.hideMs || 2000);
	}

	hide = () => {
		this.setState({show:false});
		if(this.props.onHide){
			this.props.onHide()
		}
	}

	render(){
		const otherClasses = [];
		if(this.state.show){
			otherClasses.push('show')
		}
		if(this.props.success){
			otherClasses.push('success')
		}else{
			otherClasses.push('error')
		}
    return(
    <div className={"g-toast never-transparent " + otherClasses.join(' ')} onClick={this.props.onClick}>
			{this.props.success ? <Check/> : <CircleX/>}
			<Label dark>{this.props.text}</Label>
    </div>
    )
  }
}