import React, { Component } from 'react';
import './PullToRefresh.css';

export default class PullToRefresh extends Component {
	componentDidMount() {
		this.setup();
	}

	componentWillUnmount() {
		this.removeEventListeners();
	}

	setup = () => {
		document.addEventListener('touchstart', this.swipeStart, false);
		document.addEventListener('touchmove', this.swipe, false);
		document.addEventListener('touchend', this.swipeEnd, false);
		this.pStart = { x: 0, y: 0 };
		this.pCurrent = { x: 0, y: 0 };
		this.isLoading = false;
	};

	loading = () => {
		this.isLoading = true;
		this.setToLoading();
	};

	stopLoadingAfterAnimation = () => {
		this.finishTimeout = setTimeout(() => {
			this.finish();
		}, 1000);
	};

	clearFinishTimeout = () => {
		if (this.finishTimeout) clearTimeout(this.finishTimeout);
		this.finishTimeout = null;
	};

	preventOverflowScrolling = () => {
		if (!this.props.parent) return;
		this.props.parent.style.overflow = 'scroll';
		this.props.parent.style['-webkit-overflow-scrolling'] = 'unset';
	};

	allowOverflowScrolling = () => {
		if (!this.props.parent) return;
		this.props.parent.style.overflow = '';
		this.props.parent.style['-webkit-overflow-scrolling'] = '';
	};

	swipeStart = (e) => {
		if (!this.main.contains(e.target)) return;
		this.swipeStarted = true;
		if (typeof e['targetTouches'] !== 'undefined') {
			let touch = e.targetTouches[0];
			this.pStart.y = touch.screenY + this.props.parent.scrollTop;
		} else {
			this.pStart.y = e.screenY + this.props.parent.scrollTop;
		}
	};

	swipe = async (e) => {
		if (!this.main.contains(e.target)) return;
		if (!this.props.parent) return;
		if (this.props.parent.scrollTop > 0) return;
		if (typeof e['changedTouches'] !== 'undefined') {
			let touch = e.targetTouches[0];
			this.pCurrent.y = touch.screenY;
		} else {
			this.pCurrent.y = e.screenY;
		}
		let changeY = this.pCurrent.y - this.pStart.y;
		if (changeY < 0) return;
		this.animateStarted = true;
		this.preventOverflowScrolling();
		this.clearFinishTimeout();
		let maxFromTop = 30;
		let moveElementDown = changeY - 87;
		if (moveElementDown > maxFromTop) {
			moveElementDown = maxFromTop;
		}
		this.main.style.transition = `unset`;
		this.main.style.transform = `translateY(${moveElementDown}px)`;
		if (changeY > 87) {
			if (this.isLoading) return;
			this.loading();
			if (this.props.onPullToRefresh) {
				await this.props.onPullToRefresh();
			}
			this.stopLoadingAfterAnimation();
		}
	};
	swipeEnd = (e) => {
		this.swipeStarted = false;
		if (!this.main.contains(e.target)) return;
		this.allowOverflowScrolling();
		if (!this.animateStarted) return;
		this.animateStarted = false;
		this.stopLoadingAfterAnimation();
		if (this.isLoading) {
			return this.setToLoading();
		}
		this.setToHome();
	};
	removeEventListeners = () => {
		document.removeEventListener('touchstart', this.swipeStart, false);
		document.removeEventListener('touchmove', this.swipe, false);
		document.removeEventListener('touchend', this.swipeEnd, false);
	};

	finish = () => {
		this.clearFinishTimeout();
		if (this.swipeStarted) return this.stopLoadingAfterAnimation();
		this.isLoading = false;
		this.setToHome();
	};

	setToHome = () => {
		this.main.classList.remove('loading')
		this.main.style.transition = ``;
		this.main.style.transform = `translateY(-87px)`;
	};

	setToLoading = () => {
		this.main.classList.add('loading')
		this.main.style.transition = ``;
		this.main.style.transform = `translateY(0px)`;
	};

	render() {
		return (
			<div className="g-pull-to-refresh" ref={(ref) => (this.main = ref)}>
				<div className="loading-container">
					<div className="spinning-loading" />
				</div>
				<div className="card-wrapper">{this.props.children}</div>
			</div>
		);
	}
}
