import throttle from 'lodash.throttle';

const DashboardController = function () {
	this.refresh = (optionalDrugBoxId) => {
		this.onRefreshCallback(optionalDrugBoxId || localStorage.lastBoxId);
	}
	this.refreshThrottled = throttle(this.refresh ,1000,  { leading: true, trailing: false });
	this.onRefresh = (callback) => {
		this.onRefreshCallback = callback;
	};
}

const dashboardController = new DashboardController();
export default dashboardController