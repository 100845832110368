import scanViewController from "./scanViewController";
import mainAPI from "../api/MainAPI";
import toasts from "../utils/toasts";
import NewDrugPopup from "../components/popups/newDrugPopup/NewDrugPopup";
import PrimaryButton from "../components/primaryButton/PrimaryButton";
import React from 'react';
import NdcScannerExplainerPopup from "../components/popups/ndcScannerExplainerPopup/NdcScannerExplainerPopup";

let NdcBarcodeScanController = function () {
	this.start = (state, onDoneCreating) => {
		this.drugDetailsState = state;
		this._onDoneCreating = onDoneCreating
		this.showScanner();
	};

	this.showScanner = () => {
		scanViewController.showScanner(
			'Drug Barcode',
			'Scan the labeler/NDC barcode',
			this._handleScan,
			null,
			null,
			this._onHide,
			{
				forceHideManual: true,
				wide: true,
				allowAnyType: true,
				bottom: <PrimaryButton style={{margin: 'auto'}} center onClick={this.showExplainerPopup}>How Does this
					work?</PrimaryButton>
			}
		)
	}
	this._onHide = () => {
		setTimeout(
			window.popupController.show(NewDrugPopup, {
				state: this.drugDetailsState,
				onDoneCreating: this._onDoneCreating
			}), 200);

	};
	this.showExplainerPopup = () => {
		window.popupController.show(NdcScannerExplainerPopup, {});
	};
	this._handleScan = async (text, inputManually, type) => {
		const drugPresets = await mainAPI.national_drug_code.search_by_barcode({ndc_barcode: text, code_type: type});
		scanViewController.hideScanner();
		if (drugPresets && drugPresets.type) {
			const presets = {};
			if (drugPresets.type) {
				presets.type = drugPresets.type
			}
			if (drugPresets.expiration_date) {
				presets.expiration_date = drugPresets.expiration_date
			}
			if (drugPresets.quantity) {
				presets.quantity = drugPresets.quantity
			}
			window.popupController.show(NewDrugPopup, {
				state: {
					...this.drugDetailsState,
					value: {
						...this.drugDetailsState?.value,
						...presets,
						ndc_barcode: text,
					},
				},
				onDoneCreating: this._onDoneCreating
			});
		} else {
			toasts.show('Drug details were not found. Enter the drug details manually and we can auto fill them next time you scan this barcode.', 4500);
			window.popupController.show(NewDrugPopup, {
				state: {
					...this.drugDetailsState,
					value: {
						...this.drugDetailsState?.value,
						ndc_barcode: text,
					},
				},
				onDoneCreating: this._onDoneCreating
			});
		}
	}
};

let ndcBarcodeScanController = new NdcBarcodeScanController();
export default ndcBarcodeScanController;

