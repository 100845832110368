import React, {Component} from 'react';
import './ProgressBar.css';

export default class ProgressBar extends Component {
	render() {
		let progressPercent = (this.props.progress / this.props.total) * 100;
		progressPercent = Math.max(progressPercent, 10);
		return (<div className="g-progress-bar">
				<div style={{width: progressPercent + '%'}} className={'progress'}>{this.props.progress}</div>
				<div style={{width: (100 - progressPercent) + '%'}} className={'total'}>{this.props.total}</div>
			</div>
		)
	}
}