import cloneDeep from 'lodash.clonedeep';
import uniq from 'lodash.uniq';

import boxScannedPopupDiscrepancyModel from "./BoxScannedPopupDiscrepancy.model";

class BoxScannedPopupModel {
	constructor() {
		this.state = {};
	}

	clear = () => {
		this.state = {};
    boxScannedPopupDiscrepancyModel.setDrugs(null);
	};

	getAndSaveState = (newProps) => {
		let props = cloneDeep(newProps);
		let state = cloneDeep(this.state || {});
		if (props && props.lastDrugScanned) {
			state.drugsChecked = state.drugsChecked || [];
			state.drugsChecked.push(newProps.lastDrugScanned.id);
		}
		if (props && props.drugsChecked) props.drugsChecked = state.drugsChecked;
		if (props && props.drugCodesChecked && this.state.drugs) {
			state.drugsChecked = props.drugCodesChecked.map(code=>this.drugCodeToId(this.state.drugs,code))
			delete props.drugsChecked;
			delete props.drugCodesChecked;
		}
		this.setState(state);
		this.setState(props);
		return cloneDeep(this.state);
	};

	getState = () => {
		return this.state;
	}

		drugCodeToId = (drugs, code) => {
	   return drugs.find(d =>d.barcode_id == code).id
	};

	setState = (newState) => {
		for (let i in newState) {
			this.state[i] = cloneDeep(newState[i]);
		}
		if (Array.isArray(this.state.drugsChecked)) {
			this.state.drugsChecked = uniq(this.state.drugsChecked);
			this.state.drugsChecked = cloneDeep(this.state.drugsChecked.filter(this.isDrugInBox));
		}
		if (Array.isArray(this.state.drugsCheckedManually)) {
			this.state.drugsCheckedManually = uniq(this.state.drugsCheckedManually);
			this.state.drugsCheckedManually = cloneDeep(this.state.drugsCheckedManually.filter(this.isDrugInBox));
		}
	};

	isDrugInBox = (drugId) => {
		if (!Array.isArray(this.state.drugs)) return false;
		const drugIsInBox = this.state.drugs.find((drug) => drug.id == drugId);
		return Boolean(drugIsInBox);
	};
}

const boxScannedPopupModel = new BoxScannedPopupModel();
export default boxScannedPopupModel;
