import React, { Component } from 'react';
import cloneDeep from 'lodash.clonedeep';
import './BoxScannedPopup.css';
import { Block, Page, Toolbar, View } from 'framework7-react';
import FontIcon from '../../fontIcon/FontIcon';
import Card from '../../card/Card';
import Box from 'assets/drug_box.png';
import Button from '../../button/Button';
import mainAPI from 'api/MainAPI';
import models from '../../../model/models';
import utils from 'utils/utils';
import CheckBox from '../../checkBox/CheckBox';
import ScannerInput from 'components/scannerInput/ScannerInput.jsx';
import boxScannedPopupModel from './BoxScannedPopup.model';
import Volume from '../../volume/Volume';
import toasts from 'utils/toasts.js';
import verifyController from '../../../controllers/verifyController';
import Label from '../../label/Label';
import getLocation, { getLastLocation } from '../../../utils/getLocation';
import inventoryCheckController from '../../../controllers/inventoryCheckController';
import Input from '../../input/Input';
import barcodeLookup from '../../../utils/barcodeLookup';
import { handleNetworkError } from '../../../api/requests';
import objUtils from '../../../utils/objUtils';
import ImageInput from '../../imageInput/ImageInput';
import boxScannedPopupDiscrepancyModel from "./BoxScannedPopupDiscrepancy.model";
import {VERSION} from "../../pages/loginHome/LoginHome";
import Popup from "../popup/Popup";
import dashboardController from "../../../controllers/dashboardController";

const Navbar = (props) => {
	let title = 'Inventory Check';
	if (props.reseal) {
		title = 'Reseal Box';
	}
	const handleClose = () => {
		console.log('Navbar handleClose');
		if (!props.reseal) {
			return props.cancel();
		}
		if (props.isOptional) {
			return props.cancel();
		}
		return window.f7.dialog.confirm(
			'This will create a discrepancy. Press OK to leave. Press Cancel to continue resealing',
			'Are you sure you want to leave, without resealing the box?',

			() => {
				const user = models.user.getCurrent();
				const userName = user.first_name + ' ' + user.last_name;
				let disc = {
					created_by_id: user.id,
					discrepancy_type: 'Reseal Avoided',
					notes: `${userName} was on the reseal screen and canceled before finishing the reseal`
				};
				mainAPI.discrepancy.create(disc);
				props.cancel();
			},
			() => {}
		);
	};

	return (
		<div className="g-navbar">
			<div></div>
			<div>{title}</div>
			<FontIcon onClick={handleClose} name="icon-close" color="white" />
		</div>
	);
};

export default class BoxScannedPopup extends Component {
	constructor(props) {
		super(props);
		this.drugRef = {};
		this.state = boxScannedPopupModel.getAndSaveState(props);
		this.boxRefs = {};
		getLocation();
	}

	componentWillReceiveProps = (nextProps) => {
		try {
			if (window.f7.views.box_scanned_popup_view?.router?.currentRoute?.path === '/') { // on the Box scanned popup page (not verifying). the popup controller can save stale props
				this.setState(boxScannedPopupModel.getAndSaveState(nextProps));
			} else {
				this.setState({opened: nextProps.opened})
			}
		}catch(e){
			console.error(e)
			mainAPI.error_log.create({type:'BoxScannedPopup.componentWillReceiveProps', message:e?.message})

		}
	};

	componentDidMount = () => {
		this._getData();
	};

	_getData = async () => {
		let id = this.state.value.id;
		let drug_box = models.drug_box.obj(id);
		if (drug_box) this.drug_box_id = id;
		// Here is where we get the drugs for the box
		let drugs = await mainAPI.drug.for_box(id);
		let activeDrugs = [];
		for (let i in drugs) {
			let drug = cloneDeep(drugs[i]);
			if (drug.stock_move_record__quantity > 0) {
				activeDrugs.push(drug);
			}
		}
		let state = boxScannedPopupModel.getAndSaveState({
			drug_box_id: id,
			drugs: activeDrugs,
			drug_box,
			value: { id: id }
		});
		let user = models.user.getCurrent();
		let user_group = models.user_group.obj(user.user_group_id);
		state.user_group = user_group;
		this.setState(state);
	};

	cancel = () => {
		window.popupController.hide(BoxScannedPopup);
		boxScannedPopupModel.clear();
	};

	possibleTypes = () => {
		const types = {};
		for (let i in this.state.drugs) {
			const drug = cloneDeep(this.state.drugs[i]);
			types[drug.type] = drug.type;
		}
		const typeArray = [];
		for (let typ in types) {
			typeArray.push(typ);
		}
		return typeArray.sort();
	};

	_displayCards = () => {
		let types = this.possibleTypes();
		let cards = [];
		for (let i in types) {
			const type = cloneDeep(types[i]);
			cards.push(this._getCard(type, i == types.length - 1));
		}
		return <div className="card-list">{cards}</div>;
	};

	_getCard = (type, last) => {
		let count = this._getCount(type);
		const typeObj = models.drug_type.obj(type);
		return (
			<Card className={last ? 'last' : ''}>
				<div className="top-row">
					<div className="dark">{typeObj.name}</div>
					<div>{count} {count ==1 ? 'vial': 'vials'}</div>
				</div>

				{this._getRows(type)}
			</Card>
		);
	};


	_drugsChecked = () => {
	   return cloneDeep(this.state.drugsChecked || []);
	};

	_manualCheck = (id, value) => {
		id = parseInt(id);
		let checked = this._drugsChecked();
		let manual = cloneDeep(this.state.drugsCheckedManually || []);

		for (let i in checked) {
			if (checked[i] !== id) continue;
			if (!value) {
				checked.splice(i, 1);
				break;
			}
		}
		for (let i in manual) {
			if (manual[i] !== id) continue;
			if (!value) {
				manual.splice(i, 1);
				break;
			}
		}
		if (value) checked.push(parseInt(id));
		if (value) manual.push(parseInt(id));
		setTimeout(() => {
			boxScannedPopupModel.setState({ drugsChecked: checked, drugsCheckedManually: manual, hideScanSuccess:true });
			this.setState({ drugsChecked: checked, drugsCheckedManually: manual, hideScanSuccess:true });
			if (this._allDrugsScanned()) {
				document.querySelector('.g-box-scanned-popup .page-content').scrollTo({ top: 0, behavior: 'smooth' });
			}
		}, 50);
	};

	_lessThan60DaysFromNow = (dt) => {
		if (!dt || !dt.getTime) return false;
		let sixtyFromNow = utils.xDaysAgo(-60);
		if (dt.getTime() < sixtyFromNow.getTime()) {
			return true;
		}
		return false;
	};

	_lessThan90DaysFromNow = (dt) => {
		if (!dt || !dt.getTime) return false;
		let ninetyFromNow = utils.xDaysAgo(-90);
		if (dt.getTime() < ninetyFromNow.getTime()) {
			return true;
		}
		return false;
	};

	_getRows = (type) => {
		let rows = [];
		let checked = this._drugsChecked();
		let user_group = this.state.user_group || {};
		let allow_daily_check_with_checkbox = !!user_group.allow_daily_check_with_checkbox;
		for (let i in this.state.drugs) {
			let drug = cloneDeep(this.state.drugs[i]);
			let expirationWarning = ' ';
			if (this._lessThan60DaysFromNow(drug.expiration_date)) {
				expirationWarning = ' sixty ';
			} else if (this._lessThan90DaysFromNow(drug.expiration_date)) {
				expirationWarning = ' ninety ';
			}

			if (type === drug.type) {
				rows.push(
					<div
						key={drug.id}
						ref={(ref) => (this.drugRef[drug.id] = ref)}
						className={
							'drug-row ' +
							expirationWarning
						}
					>
						<CheckBox
							disabled={!allow_daily_check_with_checkbox}
							id={drug.id}
							forceValue={checked.includes(drug.id)}
							onChange={(value, id) => {
								this._manualCheck(id, value);
							}}
						/>

						<div className="dark selectable">{utils.upperCase(drug.barcode_id)}</div>
						<div>
							<div className="expiration">{displayExpOrShortDate(drug)}</div>
							<div>
								<Volume type={drug.type} value={drug.stock_move_record__quantity}/>
							</div>
						</div>

					</div>
				);
			}
		}
		return rows;
	};

	_getCount = (type) => {
		let count = 0;
		for (let i in this.state.drugs) {
			let drug = this.state.drugs[i];
			if (type === drug.type) count++;
		}
		return count;
	};

	_hasDrugs = () => {
		let hasDrugs = !objUtils.isEmpty(this.state.drugs);
		return hasDrugs;
	};

	_allDrugsScanned = () => {
		let checked = this._drugsChecked();
		if (!this._hasDrugs()) return true;
		if (checked.length === 0) return false;
		const unCheckedDrugs = this._unCheckedDrugs(true);
		if (unCheckedDrugs.length > 0) {
			return false;
		}
		return true;
	};

	_allNonControlledScanned = () => {
		let checked = this._drugsChecked();
		if (!this._hasDrugs()) return true;
		if (checked.length === 0) return false;
		const unCheckedDrugs = this._unCheckedDrugs(false);
		if (unCheckedDrugs.length > 0) {
			return false;
		}
		return true;
	};

  _unCheckedDrugs = (includeNonControlled = true) => {
    let checked = this._drugsChecked();
    if (!this._hasDrugs()) return []
    let unChecked = [];
    for (let i in this.state.drugs) {
      let drug = cloneDeep(this.state.drugs[i]);
			const drugType = models.drug_type.obj(drug.type);
			if(!includeNonControlled && drugType && drugType.categories.includes('Non-Controlled Substance')){
				continue;
			}
      let scanned = checked.includes(drug.id);
      if (!scanned) {
        unChecked.push(drug);
      }
    }
    return unChecked;
  };

	renderImageInput = () => {
		let user_group = models.user_group.obj();
		if (!user_group.allow_image_upload) {
			return <img src={Box} alt="box-popup" />;
		}
		return <ImageInput
			required={user_group.require_inventory_image}
			value={this.state.image_url} ref={(ref) => (this.boxRefs.image_url = ref)} />;
	};

	_renderSelectAllButton = () => {
		const {user_group: {allow_daily_check_with_checkbox: check_option = false, enable_select_all_inventory_check_option: select_all = false} = {}} = this.state;
		const enabled  = !!check_option && !!select_all
		if (!enabled) return (<></>)
		const onSelectAll = () => {
			const {drugs = []} = this.state;
			const allDrugIds = drugs.map(({id}) => id)
			this.setState({ drugsChecked: allDrugIds, drugsCheckedManually: allDrugIds }, () => {
				boxScannedPopupModel.setState({ drugsCheckedManually: allDrugIds });
				document.querySelector('.g-box-scanned-popup .page-content').scrollTo({ top: 0, behavior: 'smooth' });
			});
		}

		return (
			<Button key='select-all-button' bg="darkblue" color="white" center onClick={onSelectAll}>Select All</Button>
		);
	}

	_renderContinueButton = () => {
		let display = [];
		let reseal = null;
		let resealNotes = null;

    if (this.state.reseal) {
      reseal = (
        <ScannerInput
					onFocus={() => {
						this.setState({opened:false});
					}}
					onChange={(value) => {
						this.setState({opened:true, drug_box__barcode_id: value});
					}}
          key={'new-box-code'}
          required
					value={this.state.drug_box__barcode_id}
          ref={(ref) => (this.boxRefs.drug_box__barcode_id = ref)}
          placeholder={'New box code'}
        />
      );
      if (this.props.isOptional) {
        resealNotes = (
          <Input key={'reseal-reason'} value={this.state.notes} onChange={(value) => {
             this.setState({notes:value})
          }} required ref={(ref) => (this.boxRefs.notes = ref)} placeholder={'Reason for reseal'} center />
        );
      }
    } else {
      reseal = null;
      resealNotes = null;
    }
    display.push(
      <Button bg="darkblue" color="white" className={'continue'} center onClick={this._continue} key={'continue-button'}>
        Continue
      </Button>
    );

		if (reseal) display.push(reseal);
		if (resealNotes) display.push(resealNotes);
		return display;
	};

	discrepancyBeforeContinueV1 = (useOptionalWitness) => {
		const {username, primary_email} = models.user.getCurrent();
		const usernameText = (typeof username === 'string' && username.trim() !== '') ? username : primary_email;
		const unCheckedCount = this._unCheckedDrugs(false).length;
		const drugText = unCheckedCount === 1 ? '1 controlled substance is' : `${unCheckedCount} controlled substances are`;

    const dialog = window.f7.dialog.create({
			title: 'Partial Inventory Check',
			text: `${drugText} not checked. Are you sure that you want to proceed?`,
			content: `
		<div class="dialog-input-field">
		  <div class="item-input">
			<input type="text" class="f7-dialog no-border" disabled placeholder="Username" id="confirm-username" value="${usernameText}" />
			<input type="password" class="f7-dialog" placeholder="Password" id="confirm-password" autofocus />
		  </div>
		</div>
  		`,
			buttons: [{
				text: 'Cancel',
				onClick: (dialog) => {
          boxScannedPopupDiscrepancyModel.setDrugs(null);
          dialog.close()
        }
			},
				{
					text: 'Confirm',
					close: false,
					onClick: async (dialog) => {
						const {password} = this.state;
						if (!password) {
							toasts.error(`Password is required.`);
						} else {
							// User input a password. Attempt to authenticate.
							const authentication = await mainAPI.user.login({username: usernameText, password})
							const {token, first_name, last_name, id} = authentication || {}
							if (authentication && token) {
								boxScannedPopupModel.setState({discrepancyConfirmedByUser: `${first_name} ${last_name}`, discrepancyConfirmedByUserId: id});
                const drugs = this._unCheckedDrugs(false);
                boxScannedPopupDiscrepancyModel.setDrugs(drugs);
								dialog.close();
								await this._continue(useOptionalWitness, true)
							} else {
								console.log('Invalid password');
								toasts.error(`Invalid password.`);
							}
						}
					}
				}
			],
			destroyOnClose: true
		})
		dialog.on('dialogOpen', () => {
			// add event listener to password field when dialog starts opening
			document.getElementById('confirm-password').addEventListener('input', (event = {}) => {
				const {target: {value: password} = {}} = event
				this.setState({password});
			});
		});
		dialog.on('dialogOpened', () => {
			// focus cursor to password field after the dialog has opened
			document.getElementById('confirm-password').focus();
		});
		dialog.open()
	};

  discrepancyModalBeforeContinue = (useOptionalWitness) => {

    let drugText = `<bold class="redText">${this._unCheckedDrugs(false).length}</bold> drugs`;
    if (this._unCheckedDrugs(false).length === 1) {
      drugText = `<bold class="redText">${this._unCheckedDrugs(false).length}</bold> drug`;
    }
    window.f7.dialog.confirm(
      'This will create a discrepancy. Press OK if this is expected.',
      `Are you sure you want to proceed with ${drugText} unaccounted for?`,
      () => {
        this._continue(useOptionalWitness, true)
      },
      () => {});
  };

	_continue = async (useOptionalWitness = true, continueWithDiscrepancy = false) => {
    if(!this._allNonControlledScanned()){
      if(!continueWithDiscrepancy){
        // return this.discrepancyModalBeforeContinue(useOptionalWitness);
        return this.discrepancyBeforeContinueV1(useOptionalWitness);
      }
    }
		if (this.boxRefs.drug_box__barcode_id) {
			if (!this.boxRefs.drug_box__barcode_id.isValid()) return toasts.error('Invalid code');
			let code = this.boxRefs.drug_box__barcode_id.getValue();
			if ((code + '').length < 3) return toasts.error('This code must be at least 3 characters');
			const { drug, box } = await barcodeLookup(code);
			if (box || drug) return toasts.error(`This code ${code} is already in use`);
			let state = { drug_box__barcode_id: code };
			boxScannedPopupModel.getAndSaveState(state);
		}

		if (this.boxRefs.notes) {
			if (!this.boxRefs.notes.isValid()) return toasts.error('Notes are required');
			let value = this.boxRefs.notes.getValue();
			let state = { notes: value };
			boxScannedPopupModel.getAndSaveState(state);
		}
		if (this.boxRefs.image_url) {
			if (!this.boxRefs.image_url.isValid()) return toasts.error('Image required');
			let value = this.boxRefs.image_url.getValue();
			let state = { image_url: value };
			boxScannedPopupModel.getAndSaveState(state);
		}

		if (this.props.optionalWitness && useOptionalWitness) {
			return this.onVerified(this.props.optionalWitness);
		}
		if (this.props.reseal) {
			return verifyController.start(this.onVerified, this._getDiscrepancyMessage());
		}

		let boxRequireBoxCheckVerify = this.state.drug_box.daily_check_verify_required;
		let globalRequireBoxCheckVerify = this.state.user_group.daily_check_verify_required;

		if (boxRequireBoxCheckVerify === 1) {
			return verifyController.start(this.onVerified, this._getDiscrepancyMessage());
		} else if (boxRequireBoxCheckVerify === 0) {
			return this.onVerified({});
		} else {
			if (globalRequireBoxCheckVerify === 1) {
				return verifyController.start(this.onVerified, this._getDiscrepancyMessage());
			} else if (globalRequireBoxCheckVerify === 0) {
				return  this.onVerified({});
			}
		}
	};

	getInputState = () => {
		const state = {}
		if (this.boxRefs.drug_box__barcode_id) {
			const code = this.boxRefs.drug_box__barcode_id.getValue();
			state.drug_box__barcode_id = code;
		}

		if (this.boxRefs.notes) {
			const value = this.boxRefs.notes.getValue();
			state.notes = value;
		}
		if (this.boxRefs.image_url) {
			const value = this.boxRefs.image_url.getValue();
			state.image_url = value;
		}
		return state;

	};

	startScanDrugs = () => {
		const barcodes = this.state.drugs.map(d=>d.barcode_id)
		const drugsChecked = this._drugsChecked().map(x=>toBarcodeId(this.state.drugs, x))
		const inputState = this.getInputState();
		const state = boxScannedPopupModel.getState();
		const functions = {};
		for (let i in this.props){
		  if (typeof this.props[i] === 'function') {
				functions[i] = this.props[i];
			}
		}
		return inventoryCheckController.scanForDrugInBox(barcodes,
			drugsChecked,
			{...state, ...inputState, ...functions})
	};

  _getDiscrepancyMessage = () => {
    if(boxScannedPopupDiscrepancyModel.getDrugs()){
      return 'You are verifying an activity that will create a discrepancy report.'
    }
  };

  _createDiscrepancyForMissingDrug = async (drug) => {
    let user = models.user.getCurrent();
    let userName = user.first_name + ' ' + user.last_name;
    const drugType = models.drug_type.obj(drug.type);
    let drug_box = this.state.drug_box || {};
    let box_code = drug_box.barcode_id || '';
	  const { discrepancyConfirmedByUser } = boxScannedPopupModel.getState();

    let disc = {
      created_by_id: user.id,
      discrepancy_type: 'Box check missing drug',
      notes: `${userName} performed a box check on box (${drug_box.name} ${box_code}) without drug (${utils.upperCase(drug.barcode_id)} ${drugType.name}). Confirmed by ${discrepancyConfirmedByUser ? `${discrepancyConfirmedByUser}` : ''} VERSION: ${VERSION}`
    };
    await mainAPI.discrepancy.create(disc);
  }

  _createDiscrepanciesForMissingDrugs = async () => {
    const drugs = boxScannedPopupDiscrepancyModel.getDrugs();
    try {
      for (let i in drugs) {
        await this._createDiscrepancyForMissingDrug(drugs[i])
      }
    }catch(e){
      boxScannedPopupDiscrepancyModel.setDrugs(null);
      throw e;
    }
    boxScannedPopupDiscrepancyModel.setDrugs(null);
  }

	onVerified = async (user) => {
		if (this.continuePressed) return;
		let value = boxScannedPopupModel.getAndSaveState({
			witness_id: user.witness_id,
			witness_biometric_guid: user.guid,
			guest_verify__name: user.guest_verify__name,
			guest_verify__signature_img: user.guest_verify__signature_img,
			guest_verify__license_number: user.guest_verify__license_number,
			guest_verify__title: user.guest_verify__title
		});
		boxScannedPopupModel.getAndSaveState();
		let scanned_manually = 0;
		if (value.drugsCheckedManually && value.drugsCheckedManually.length > 0) {
			scanned_manually = 1;
		}
		let { lat, lon } = getLastLocation();
		let bc = {
			drug_box_id: value.drug_box_id || this.drug_box_id || this.props?.value?.id,
			witness_id: value.witness_id,
			witness_biometric_guid: value.witness_biometric_guid,
			guest_verify__name: value.guest_verify__name,
			guest_verify__signature_img: value.guest_verify__signature_img,
			guest_verify__license_number: value.guest_verify__license_number,
			guest_verify__title: value.guest_verify__title,
			discrepancy_confirmed_by_user_id: value.discrepancyConfirmedByUserId,
			scanned_manually,
			lat,
			lon
		};
		if (value.drug_box__barcode_id) {
			bc.drug_box__barcode_id = value.drug_box__barcode_id;
		}
		if (value.image_url) {
			bc.image_url = value.image_url;
		}
		if (value.notes) {
			bc.notes = value.notes;
		}
		const box_check = await this.saveBoxCheck(bc, value).catch((e) => {
			this.continuePressed = false;
			console.error('Error during `saveBoxCheck`.', e);
			const {message} = e;
			let type = 'BoxScannedPopup.saveBoxCheck Failed';
			if (message.toLowerCase() === 'Failed to create drug checks'.toLowerCase()) {
				type += ' (drug checks failed)'
				toasts.error(message);
			}
			else {
				toasts.error('Unknown error');
			}
			mainAPI.error_log.create({ type, message: (e?.message || e) + JSON.stringify(bc) + JSON.stringify(value) });
			return null;
		});
    if(boxScannedPopupDiscrepancyModel.getDrugs()){
      await this._createDiscrepanciesForMissingDrugs();
    }
		if (!box_check) {
			this.continuePressed = false;
			return;
		}
		boxScannedPopupModel.clear();
		let createdTimestamp = '';
		if (box_check?.created_on?.getTime) {
			createdTimestamp = box_check.created_on.getTime();
		}
		dashboardController.refresh(bc.drug_box_id)
		if (this.props.onSuccess && typeof this.props.onSuccess === 'function') {
			const keepPopupOpen = await this.props.onSuccess(box_check.id, createdTimestamp);
			if(!keepPopupOpen) {
				window.popupController.hide(BoxScannedPopup);
			}
		} else {
			window.f7.views.current.router.navigate('/box/check/confirm/' + box_check.id + '/' + createdTimestamp);
		}
	};

	handleBoxCheckError = (error) => {
		if (error?.response?.data?.err === 'This witness is not part of your agency, or the "verify code" has expired.') {
			// toasts.show('This witness is not part of your agency, or the "verify code" has expired.');
			this._continue(false);
		}
		handleNetworkError(error);
	};

	saveBoxCheck = async (bc, value) => {
		this.continuePressed = true;
		let box_check = await mainAPI.box_check.create(bc, this.handleBoxCheckError);
		if (!box_check) {
			this.continuePressed = false;
			return;
		}
		let drug_checks = [];
		for (let i in value.drugs) {
			let drug = value.drugs[i];
      if (this._unCheckedDrugs().map(drug=>drug.id).includes(drug.id)) continue;
			let drugCheck = { drug_id: drug.id, check_id: box_check.id };
			if (
				value.drugsCheckedManually &&
				value.drugsCheckedManually.length > 0 &&
				value.drugsCheckedManually.includes(drug.id)
			) {
				drugCheck.scanned_manually = 1;
			}
			drug_checks.push(drugCheck);
		}
		this.continuePressed = false;
		const {error} = await mainAPI.drug_check.update_many(drug_checks);
		if (error) throw new Error("Failed to create drug checks");
		return box_check;
	};

	render() {

		let drug_box = this.state.drug_box || {};
		let box_id = drug_box.barcode_id || '';

		return (
			<Popup opened={this.state.opened} tabletFullscreen={true} className="g-box-scanned-popup never-transparent" animate={true}>
				<View stackPages name={'box_scanned_popup_view'}>
					<Page className="box-scanned-popup-page" >
						<Navbar {...this.state} isOptional={this.props.isOptional} cancel={this.cancel} />
						<Block>
							<div className="info-row">
								<Label dark large>
									{drug_box.name}
								</Label>
								<Label dark large className={'selectable'}>
									{box_id.toUpperCase()}
								</Label>
							</div>
							<div
								className={(this.state.hideScanSuccess || this.props.lastDrugScanned) ? 'success hide' : 'success show'}>
								<FontIcon name="icon-done-all"/>
								<p>Scanned successfully</p>
								<div></div>
							</div>
							{this.renderImageInput()}
							{this._renderSelectAllButton()}
							{this._renderContinueButton()}
							{this._displayCards()}
							{/*<Button center bg="blue" color="white" onClick={this._continue}>Complete Box Check</Button>*/}
						</Block>
						<Toolbar className="g-box-scan-toolbar">
							<Button bg="darkblue" color="white" center onClick={this.startScanDrugs}>
								Scan Drugs
							</Button>
						</Toolbar>
					</Page>
				</View>
			</Popup>
		);
	}
}

const toBarcodeId = (drugs, id) => {
   return drugs.find(d=>id==d.id).barcode_id
};

const displayExpOrShortDate = (drug) => {
	if (!drug.short_date) {
		return 'Exp ' + utils.display_date(drug.expiration_date);
	}
	if (drug?.short_date?.getTime() < drug?.expiration_date?.getTime()) {
		return 'Short ' + utils.display_date(drug.short_date);
	}
	return 'Exp ' + utils.display_date(drug.expiration_date);

};